import React, { useState, useRef } from "react";
import "../App.css";
import downloadicon from "../icons/downloadimg.png";
import pdfIcon from "../icons/pdfFile.png";
import txtIcon from "../icons/txtFile.png";
import mp3Icon from "../icons/mp3icon.png";
import mp4Icon from "../icons/mp4icon.png";
import zipIcon from "../icons/zipicon.png";
import docXIcon from "../icons/docxicon.png";
import imgIcon from "../icons/imgIcon.png";
import UnknownIcon from "../icons/unknownFile.png";

function SharedFileSection({
  files,
  handleDownload,
  handleViewPdf
}) {
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const dropdownButtonRef = useRef(null);

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleDoubleClick = (file) => {
    if (file.filename.split(".").pop().toLowerCase() === "pdf") {
      handleViewPdf(file._id);
    }
  };

  const fileIconMap = {
    pdf: pdfIcon,
    txt: txtIcon,
    mp3: mp3Icon,
    mp4: mp4Icon,
    avi: mp4Icon,
    mov: mp4Icon,
    wmv: mp4Icon,
    mkv: mp4Icon,
    flv: mp4Icon,
    mpeg: mp4Icon,
    mpg: mp4Icon,
    "3gp": mp4Icon,
    webm: mp4Icon,
    ogv: mp4Icon,
    ogg: mp4Icon,
    m4v: mp4Icon,
    ts: mp4Icon,
    f4v: mp4Icon,
    m2ts: mp4Icon,
    vob: mp4Icon,
    zip: zipIcon,
    docx: docXIcon,
    doc: docXIcon,
    jpg: imgIcon,
    jpeg: imgIcon,
    png: imgIcon,
    gif: imgIcon,
    bmp: imgIcon,
    svg: imgIcon,
  };

  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    return fileIconMap[extension] || UnknownIcon;
  };

  return (
    <div>
        <ul className="fileSection">
          {files.map((file) => (
            <li
              key={file._id}
              className="fileSectionlistitem"
              onMouseEnter={() => setHoveredItemId(file._id)}
              onMouseLeave={() => setHoveredItemId(null)}
              onDoubleClick={() => handleDoubleClick(file)} // Handle double-click
            >
              <div className="fileitem">
                <div className="fileitemname d-flex justify-content-center align-items-center">
                  <span className="filefoldername">
                    <img
                      src={getFileIcon(file.filename)}
                      alt="icon"
                      style={{ width: "2rem", marginRight: "0.5rem" }}
                    />
                    {file.filename.length <= 60
                      ? file.filename
                      : file.filename.substring(0, 60) + "..."}
                  </span>
                </div>
                <div className="dropdown" onClick={handleClick}>
                  <button
                    type="button"
                    className="butn dropdown-toggle"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ref={dropdownButtonRef}
                    style={{
                      backgroundColor: hoveredItemId === file._id ? "transparent" : "transparent",
                      width: "2rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <i
                      className="fa-solid fa-ellipsis-vertical"
                      style={{
                        fontSize: "x-large",
                        color: hoveredItemId === file._id ? "black" : "white",
                      }}
                    ></i>
                  </button>

                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <li>
                      <hr className="dropdown-divider" />
                    </li>
                    
                    <li
                      className="dropdown-item"
                      onClick={() => handleDownload(file._id, file.filename)}
                      style={{ cursor: "pointer" }}
                    >
                      <label className="iconic">
                        <img
                          src={downloadicon}
                          alt="icn"
                          style={{
                            width: "20px",
                            marginRight: "8px",
                          }}
                        />
                        Download
                      </label>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
    </div>
  );
}

export default SharedFileSection;
