import React from "react";

const Toast = ({ toasts, onClose }) => {
  return (
    <>
      {toasts.length > 0 && (
        <div
          className="toast-container position-fixed top-0 end-0 m-3"
          style={{ zIndex: "9999" }}
        >
          {toasts.map((toast, index) => (
            <div
              key={toast.id}
              className={`toast align-items-center ${
                toast.type === "red" ? "text-bg-danger" : "text-bg-primary"
              } border-0 mb-2 ${toast.show ? "show" : "hide"}`}
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-delay="5000"
              onTransitionEnd={() => toast.show || onClose(index)} // Close toast if not shown
            >
              <div className="d-flex">
                <div className="toast-body">{toast.message}</div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  aria-label="Close"
                  onClick={() => onClose(index)}
                ></button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Toast;
