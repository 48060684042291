import React, { useContext, useEffect,useState } from "react";
import "../App.css";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "./Spinner";
import Sidebar from "./Sidebar";
import GreatContext from "../context/GreatContext";
import Navbar from "./Navbar";
import ChatComponent from "./ChatComponent";
function MyChatsComp() {
  // let history = useHistory();
  // useEffect(() => {
  //   const timeoutRef = localStorage.getItem("logtime");
  //   const currentTime = new Date().toISOString();

  //   const tenMinutesInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds

  //   // Calculate the difference between current time and timeout reference time
  //   const timeDifference = new Date(currentTime) - new Date(timeoutRef);

  //   // Check if the time difference exceeds 10 minutes
  //   if (timeDifference >= tenMinutesInMilliseconds) {
  //     // Perform your action here, e.g., logout the user
  //     localStorage.removeItem("logtime")
  //     localStorage.removeItem("token");
  //     // Redirect to login page or any other desired action after logout
  //     history.push("/login");
  //   } else {
  //     const currentTime2 = new Date().toISOString();
  //     localStorage.setItem("logtime",currentTime2);
  //   }
    
  // }, [history]);
  const GrConst = useContext(GreatContext);
  const {
    loading,
    loading4,
    users,
    fetchUsers,
    currentUser,
    fetchCurrentUser,
    loading5,
    setLoading5,
  } = GrConst; 
  useEffect(() => {
    fetchUsers();
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);
  const[showNav,setShowNav]=useState(false);
  const handleResize = () => {
    const isMobile = window.innerWidth < 1600; // Adjust the width threshold as necessary
    setShowNav(isMobile);
  };

  useEffect(() => {
    // Set initial state based on current width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);
  return (
    <>
      <div className="wrapper">
        {loading && <Spinner />}
        {showNav ? <Navbar /> : <Sidebar/>}

        <div id="wrapper">
          <div className="fileFolderaccess">
            {loading4 && <div className="loader"></div>}

            <ChatComponent
              users={users}
              currentUser={currentUser}
              fetchUsers={fetchUsers}
              loading5={loading5}
              setLoading5={setLoading5}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyChatsComp;
