import React, { useState, useEffect, useRef, useCallback } from "react";
import "../App.css";
import CryptoJS from "crypto-js";
import Spinner from "./Spinner";
import axios from "axios";
import chatb from "../icons/chatboxEmp.png";
import sentIcon from "../icons/sent.png";
import nomsg from "../icons/nomsg2.png";
import zoomin from "../icons/zoom-in.png";
import zoomout from "../icons/zoom-out.png";
import scrollup from "../icons/scrollup.png";
import scrolldown from "../icons/scrolldown.png";
import reseticon from "../icons/reset.png";
import gifer from "../icons/gifer.png";
import ObjectId from "bson-objectid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Picker from "@emoji-mart/react";
import GifPicker from "gif-picker-react";
import Toast from "./Modals/Toast";
const MemoizedEmojiPicker = React.memo(Picker);
const MemoizedGifPicker = React.memo(GifPicker);

const ChatComponent = ({ users, currentUser, loading5, setLoading5 }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [sharedKey, setSharedKey] = useState("");
  const [typingNotification, setTypingNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [isInDeletion, setIsInDeletion] = useState(false);
  const [localBlockStatus, setLocalBlockStatus] = useState({});
  const ws = useRef(null);

  useEffect(() => {
    if (!selectedUser) return;
    // Reset messages and shared key when selected user changes
    setMessages([]);
    setSharedKey("");
    setLoading(true); // Set loading to true when starting to load data
    const loadSharedKeyAndMessages = async () => {
      if (selectedUser) {
        try {
          const sharedKeyResponse = await fetch(
            "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/generateSharedKey",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem("token"),
              },
              body: JSON.stringify({
                receiverId: selectedUser._id,
              }),
            }
          );

          if (!sharedKeyResponse.ok) {
            console.error(
              "Error checking or generating shared key:",
              sharedKeyResponse.statusText
            );
            return;
          }

          const fetchKeyResponse = await fetch(
            `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/getSharedKey/${selectedUser._id}`,
            {
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
            }
          );

          if (!fetchKeyResponse.ok) {
            console.error(
              "Error fetching shared key:",
              fetchKeyResponse.statusText
            );
            return;
          }

          const { sharedKey } = await fetchKeyResponse.json();
          setSharedKey(sharedKey);

          const messagesResponse = await fetch(
            `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/getMessages/${selectedUser._id}`,
            {
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
            }
          );

          if (!messagesResponse.ok) {
            console.error(
              "Error fetching messages:",
              messagesResponse.statusText
            );
            return;
          }

          const { messages } = await messagesResponse.json();
          const decryptedMessages = messages.map((msg) => ({
            ...msg,
            decryptedMessage: msg.message
              ? decryptMessage(msg.message, sharedKey)
              : "This message has been deleted",
          }));
          setMessages(decryptedMessages);
        } catch (error) {
          console.error("Error loading shared key or messages:", error.message);
        } finally {
          setLoading(false); // Set loading to false once data is loaded
        }
      }
    };

    loadSharedKeyAndMessages();
  }, [selectedUser]);

  const handleTyping = useCallback(() => {
    if (
      selectedUser &&
      ws.current &&
      ws.current.readyState === WebSocket.OPEN
    ) {
      ws.current.send(
        JSON.stringify({
          type: "typing",
          senderId: currentUser._id,
          receiverId: selectedUser._id,
        })
      );
    }
  }, [selectedUser, currentUser]);

  useEffect(() => {
    if (selectedUser && currentUser && currentUser._id) {
      if (ws.current) {
        ws.current.close();
      }

      // Create new WebSocket connection
      ws.current = new WebSocket(
        "wss://secure-storage-system-mern-cloud-file.onrender.com"
      );

      ws.current.onopen = () => {
        if (currentUser && currentUser._id) {
          ws.current.send(
            JSON.stringify({
              type: "set-user-id",
              userId: currentUser._id,
            })
          );
        }
      };

      ws.current.onmessage = async (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "new-message") {
            const { senderId, receiverId, message, timestamp, tempId, isGif } =
              data;

            if (
              (currentUser._id === receiverId ||
                currentUser._id === senderId) &&
              (selectedUser._id === receiverId || selectedUser._id === senderId)
            ) {
              const fetchKeyResponse = await fetch(
                `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/getSharedKey/${
                  currentUser._id === receiverId ? senderId : receiverId
                }`,
                {
                  headers: {
                    "auth-token": localStorage.getItem("token"),
                  },
                }
              );

              if (!fetchKeyResponse.ok) {
                console.error(
                  "Error fetching shared key:",
                  fetchKeyResponse.statusText
                );
                return;
              }

              const { sharedKey } = await fetchKeyResponse.json();
              setSharedKey(sharedKey);

              // Decrypt the new message
              const decryptedMessage = decryptMessage(message, sharedKey);

              // Add the decrypted message to the messages state
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  _id: tempId,
                  senderId,
                  message: message,
                  decryptedMessage,
                  timestamp,
                  isGif,
                },
              ]);
            }
          } else if (data.type === "typing") {
            const { senderId } = data;
            const sender = users.find((user) => user._id === senderId);
            if (senderId === selectedUser._id) {
              setTypingNotification(`${sender.name} is typing...`);
              setTimeout(() => setTypingNotification(""), 3000); // Clear after 3 seconds
            }
          } else if (data.type === "delete-message") {
            // Remove the deleted message from the UI
            const { msgId } = data;
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg._id === msgId
                  ? {
                      ...msg,
                      deleted: true,
                      decryptedMessage: "This message has been deleted",
                    }
                  : msg
              )
            );
          } else if (data.type === "block-unblock") {
            const { senderId, receiverId, isBlocked } = data;
            if (currentUser._id === receiverId) {
              setLocalBlockStatus((prevStatus) => ({
                ...prevStatus,
                [receiverId]: isBlocked,
                [senderId]: isBlocked,
              }));
            }
          }
        } catch (error) {
          console.error("Error processing WebSocket message:", error);
        }
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.current.onclose = () => {
        // console.log("WebSocket connection closed");
      };

      return () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.close();
        }
      };
    }
  }, [currentUser, users, selectedUser]);

  const messagesEndRef = useRef(null);
  const messagesTopRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
      showToolbar();
    }
  };
  const scrollToBottom2 = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      showToolbar();
    }
  };
  const scrollToTop = () => {
    if (messagesTopRef.current) {
      messagesTopRef.current.scrollIntoView({ behavior: "smooth" });
      showToolbar();
    }
  };

  useEffect(() => {
    if (!isInDeletion) {
      scrollToBottom();
    } else {
      setIsInDeletion(false);
    }
    // eslint-disable-next-line
  }, [messages]);

  const handleSendMessage = useCallback(async () => {
    if (selectedUser && message && sharedKey) {
      const tempId = new ObjectId().toHexString();
      try {
        const encryptedMessage = encryptMessage(message, sharedKey);
        const timestamp = new Date().toISOString();

        await fetch(
          "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/sendMessage",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
            body: JSON.stringify({
              msgId: tempId,
              receiverId: selectedUser._id,
              message: encryptedMessage,
            }),
          }
        );

        // Send the message via WebSocket for real-time updates
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              type: "send-message",
              senderId: currentUser._id,
              receiverId: selectedUser._id,
              message: encryptedMessage,
              timestamp,
              tempId,
            })
          );
        }
        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  }, [selectedUser, message, sharedKey, currentUser]);

  const handleSendGif = useCallback(
    async (gifUrl, isGif = true) => {
      if (selectedUser && gifUrl && sharedKey) {
        const tempId = new ObjectId().toHexString();
        try {
          const timestamp = new Date().toISOString();
          const encryptedMessage = encryptMessage(gifUrl, sharedKey);

          await fetch(
            "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/sendMessage",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem("token"),
              },
              body: JSON.stringify({
                msgId: tempId,
                receiverId: selectedUser._id,
                message: encryptedMessage,
                isGif,
              }),
            }
          );

          // Send the message via WebSocket for real-time updates
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(
              JSON.stringify({
                type: "send-message",
                senderId: currentUser._id,
                receiverId: selectedUser._id,
                message: encryptedMessage,
                timestamp,
                tempId,
                isGif,
              })
            );
          }

          // Clear the selected GIF
        } catch (error) {
          console.error("Error sending GIF:", error);
        }
      }
    },
    [selectedUser, currentUser, sharedKey]
  );

  const encryptMessage = (message, sharedKey) => {
    try {
      const iv = CryptoJS.lib.WordArray.random(16);
      const key = CryptoJS.enc.Base64.parse(sharedKey);
      const encrypted = CryptoJS.AES.encrypt(message, key, { iv }).toString();
      return `${iv.toString(CryptoJS.enc.Base64)}:${encrypted}`;
    } catch (error) {
      console.error("Error during encryption:", error);
      throw error;
    }
  };

  const decryptMessage = (encryptedMessage, sharedKey) => {
    try {
      if (!encryptedMessage) {
        throw new Error("No message to decrypt");
      }

      const [ivBase64, encryptedBase64] = encryptedMessage.split(":");
      if (!ivBase64 || !encryptedBase64) {
        throw new Error("Invalid encrypted message format");
      }

      const iv = CryptoJS.enc.Base64.parse(ivBase64);
      const key = CryptoJS.enc.Base64.parse(sharedKey);
      const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
        iv,
      }).toString(CryptoJS.enc.Utf8);

      return decrypted;
    } catch (error) {
      console.error("Error during decryption:", error);
      return "Error decrypting message"; // Return a fallback message if decryption fails
    }
  };

  const handleDeleteMessage = async (msgId, senderId) => {
    if (senderId !== currentUser._id) {
      console.error("You can only delete your own messages.");
      return;
    }
    try {
      await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/deleteMessage/${msgId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      setIsInDeletion(true);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg._id === msgId
            ? {
                ...msg,
                deleted: true,
                decryptedMessage: "This message has been deleted",
              }
            : msg
        )
      );
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            type: "delete-message",
            msgId,
            receiverId: selectedUser._id,
          })
        );
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  useEffect(() => {
    const fetchBlockStatus = async () => {
      try {
        const response = await axios.get(
          `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/block-status`,
          {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          }
        );

        if (response.status === 200) {
          const blockStatusData = response.data; // Assume this returns { userId1: true, userId2: false, ... }
          setLocalBlockStatus(blockStatusData);
        }
      } catch (error) {
        console.error("Error fetching block status:", error);
      }
    };

    fetchBlockStatus();
  }, []);

  const [blockedByUsers, setBlockedByUsers] = useState([]);

  useEffect(() => {
    const fetchBlockedByUsers = async () => {
      try {
        const response = await axios.get(
          "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/blocked-by",
          {
            headers: {
              "auth-token": localStorage.getItem("token"), // Add your auth token from local storage
            },
          }
        );

        if (response.status === 200) {
          setBlockedByUsers(response.data); // Set the array of user IDs
        }
      } catch (error) {
        console.error(
          "Error fetching users who have blocked the current user:",
          error
        );
      }
    };

    fetchBlockedByUsers();
  }, [blockedByUsers]);

  const isWithinTwoMinutes = (prevTimestamp, currentTimestamp) => {
    return new Date(currentTimestamp) - new Date(prevTimestamp) <= 30000;
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };
  const [fontSize, setFontSize] = useState(1.3);
  const [gifSize, setgifSize] = useState(20);
  const increaseFontSize = () => {
    setFontSize((prev) => (prev < 2.3 ? prev + 0.1 : prev));
    setgifSize((prev) => (prev < 25 ? prev + 1 : prev));
  };
  const resetFontSize = () => {
    setFontSize(1.3);
    setgifSize(20);
  };

  const decreaseFontSize = () => {
    setFontSize((prev) => (prev > 0.5 ? prev - 0.1 : prev));
    setgifSize((prev) => (prev > 10 ? prev - 1 : prev));
  };

  const [toolbarVisible, setToolbarVisible] = useState(false);
  const toolbarTimeoutRef = useRef(null);

  const showToolbar = () => {
    setToolbarVisible(true);
    setShowEmojiPicker(false);

    if (toolbarTimeoutRef.current) {
      clearTimeout(toolbarTimeoutRef.current);
    }

    toolbarTimeoutRef.current = setTimeout(() => {
      setToolbarVisible(false);
    }, 5000);
  };

  const [showDeleteButton, setShowDeleteButton] = useState(null);
  const handleMouseDown = (msgId) => {
    setShowDeleteButton((prev) => (prev === msgId ? null : msgId));
  };

  const handleClickOutside = (e) => {
    // Hide the delete button if clicked outside the message content
    if (!e.target.closest(".messageContent")) {
      setShowDeleteButton(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const isBlockedBySelectedUser = (userId) => {
    return blockedByUsers.includes(userId);
  };
  //Emoji
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiData, setEmojiData] = useState(null);

  useEffect(() => {
    const fetchEmojiData = async () => {
      const response = await fetch(
        "https://cdn.jsdelivr.net/npm/@emoji-mart/data"
      );
      const data = await response.json();
      setEmojiData(data);
    };

    fetchEmojiData();
  }, []);

  const handleEmojiClick = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowGifPicker(false);
    setShowEmojiPicker((prev) => !prev);
  };

  const isOnlyEmojis = (text) => {
    return /^[\p{Emoji}\u200d]+$/u.test(text);
  };

  const getMessageFontSize = (text) => {
    return isOnlyEmojis(text) ? `${fontSize + 1.7}rem` : `${fontSize}rem`;
  };

  const handleGifSelect = (gif) => {
    handleSendGif(gif.url, true);
  };

  const toggleGifPicker = () => {
    setShowEmojiPicker(false);
    setShowGifPicker((prev) => !prev);
  };

  const [toasts, setToasts] = useState([]);

  const getUserNameById = (id) => {
    const user = users.find((user) => user._id === id);
    return user ? user.name : "Unknown";
  };

  const handleBlockUnblock = async (userId, isBlocked) => {
    const url = isBlocked
      ? `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/unblock/${userId}`
      : `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/block/${userId}`;

    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        // Update local block status
        setLocalBlockStatus((prevStatus) => ({
          ...prevStatus,
          [userId]: !isBlocked,
        }));

        const userName = getUserNameById(userId);
        const toastId = Date.now(); // Unique ID based on timestamp
        setToasts((prevToasts) => [
          ...prevToasts,
          {
            id: toastId,
            show: true,
            type: isBlocked ? "blue" : "red",
            message: isBlocked
              ? `${userName} has been unblocked successfully`
              : `${userName} has been blocked successfully`,
          },
        ]);

        // Hide this specific toast after 5 seconds
        setTimeout(() => {
          setToasts((prevToasts) =>
            prevToasts.map((toast) =>
              toast.id === toastId ? { ...toast, show: false } : toast
            )
          );
        }, 3000);

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              type: "block-unblock",
              senderId: currentUser._id,
              receiverId: userId,
              isBlocked: !isBlocked,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error blocking/unblocking user:", error);
      const toastId = Date.now(); // Unique ID based on timestamp
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          id: toastId,
          show: true,
          type: "red", // Default to 'red' for errors
          message: "An error occurred. Please try again.",
        },
      ]);
      setTimeout(() => {
        setToasts((prevToasts) =>
          prevToasts.map((toast) =>
            toast.id === toastId ? { ...toast, show: false } : toast
          )
        );
      }, 3000);
    }
  };

  const handleCloseToast = (index) => {
    setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
  };

  const [userProfilePics, setUserProfilePics] = useState({}); // Store all user profile pics

  const fetchUserProfilePic = async (userId) => {
    try {
      setLoading5(true);
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/profilePic/${userId}`
      );
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setUserProfilePics((prevPics) => ({
          ...prevPics,
          [userId]: imageObjectURL,
        }));
      } else {
        console.error("Failed to fetch profile pic");
      }
      setLoading5(false);
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      setLoading5(false);
    }
  };

  // Fetch profile pictures for all users
  useEffect(() => {
    users.forEach((user) => {
      if (!userProfilePics[user._id]) {
        fetchUserProfilePic(user._id);
      }
    });
    // eslint-disable-next-line
  }, [users]); // Re-run this effect when `users` array changes

  return (
    <>
      <Toast toasts={toasts} onClose={handleCloseToast} />
      <div
        className="chatWrapper"
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "white",
        }}
      >
        <div className={`ChatUserList ${selectedUser ? "hide-on-mobile" : ""}`}>
          <div
            className="mobileSet"
            style={{
              padding: "1rem",
              marginBottom: "2rem",
              border: "0.2rem solid #CEBFFC",
              borderRadius: "5rem",
            }}
          >
            <h1
              className="display-6"
              style={{ color: "white", fontWeight: "400" }}
            >
              Inbox
            </h1>
          </div>
          <div className="userList">
            <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
              {users.map((user) => {
                const hasBlockedCurrentUser = isBlockedBySelectedUser(user._id);
                const userProfilePic = userProfilePics[user._id];
                return (
                  <React.Fragment key={user._id}>
                    <li
                      className="display-6"
                      onClick={() => setSelectedUser(user)}
                      style={{
                        cursor: "pointer",
                        padding: "1rem 0.5rem",
                        backgroundColor:
                          selectedUser && selectedUser._id === user._id
                            ? "#9389ff63"
                            : "transparent",
                        borderRadius: "5px",
                        fontWeight: "400",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onMouseEnter={(e) => {
                        if (!(selectedUser && selectedUser._id === user._id)) {
                          e.currentTarget.style.backgroundColor = "#9389ff63";
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (!(selectedUser && selectedUser._id === user._id)) {
                          e.currentTarget.style.backgroundColor = "transparent";
                        }
                      }}
                    >
                      <div className="togetherdpname">
                        <div className="Userprofilepic">
                          {loading5 ? (
                            <div
                              className="spinner-border text-info"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <img
                              className="userprofilepIc"
                              src={userProfilePic}
                              alt="profile"
                            />
                          )}
                        </div>

                        <span>{user.name}</span>
                      </div>

                      <button
                        className={
                          localBlockStatus[user._id] || hasBlockedCurrentUser
                            ? `btn btn-danger`
                            : `btn btn-primary`
                        }
                        style={{ marginLeft: "10px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBlockUnblock(
                            user._id,
                            localBlockStatus[user._id]
                          );
                        }}
                        disabled={hasBlockedCurrentUser}
                      >
                        {hasBlockedCurrentUser
                          ? "Unavailable"
                          : localBlockStatus[user._id]
                          ? "Unblock"
                          : "Block"}
                      </button>
                    </li>
                    <li style={{ backgroundColor: "#cebffc", height: "1px" }}>
                      <hr
                        key={`${user._id}-divider`}
                        className="dropdown-divider"
                      />
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>

        {loading ? (
          <Spinner /> // Show spinner while loading
        ) : selectedUser ? (
          <div className="ChatArea">
            <div className="chatAreahead">
              <div className="mobilechathead">
                <button
                  onClick={() => setSelectedUser(null)}
                  className="show-on-mobile"
                >
                  <i
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "x-large" }}
                  ></i>
                </button>
                <div className="togetherdpname">
                  <div className="Userprofilepic">
                    {loading5 ? (
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <img
                        className="userprofilepIc"
                        src={userProfilePics[selectedUser._id]}
                        alt="profile"
                      />
                    )}
                  </div>
                  <h1 className="display-6" style={{ fontSize: "2.5rem" }}>
                    {selectedUser.name}
                  </h1>
                </div>
              </div>

              <div
                className="toolbarmsg"
                style={{
                  padding: "0rem 1rem",
                  opacity: toolbarVisible ? "1" : "0",
                  display: "flex",
                  backgroundColor: "#000000a6",
                  borderRadius: "5rem",
                  transition: "all 0.2s ease-in-out",
                }}
                onMouseEnter={() => showToolbar()}
              >
                <button className="toolbut" onClick={scrollToTop}>
                  <img src={scrollup} alt="" style={{ width: "1.5rem" }} />
                </button>
                <button className="toolbut" onClick={increaseFontSize}>
                  <img src={zoomin} alt="" style={{ width: "1.5rem" }} />
                </button>
                <button className="toolbut" onClick={resetFontSize}>
                  <img src={reseticon} alt="" style={{ width: "1.5rem" }} />
                </button>
                <button className="toolbut" onClick={decreaseFontSize}>
                  <img src={zoomout} alt="" style={{ width: "1.5rem" }} />
                </button>
                <button className="toolbut" onClick={scrollToBottom2}>
                  <img src={scrolldown} alt="" style={{ width: "1.5rem" }} />
                </button>
              </div>
            </div>
            <div
              className={
                messages.length === 0
                  ? "messagesNonContainer"
                  : "messagesContainer"
              }
              onScroll={showToolbar}
              onClick={showToolbar}
            >
              <div ref={messagesTopRef} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  justifyContent: "space-between",
                  minHeight: "100%",
                  width: "100%",
                  padding: "2rem 1rem 2rem 1rem",
                }}
                onClick={() => setShowGifPicker(false)}
              >
                {typingNotification && (
                  <div
                    style={{
                      fontStyle: "italic",
                      color: "white",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {typingNotification}
                  </div>
                )}

                <div style={{ flexGrow: 1 }}>
                  {messages.length === 0 ? (
                    <div className="msgNone">
                      <img
                        src={nomsg}
                        alt=""
                        style={{
                          width: "60%",
                          borderRadius: "1rem",
                          cursor: "",
                        }}
                      />
                    </div>
                  ) : (
                    messages.map((msg) => {
                      const isFirstMessage = msg === messages[0];
                      const prevMessage = isFirstMessage
                        ? null
                        : messages[messages.indexOf(msg) - 1];

                      const isDifferentSender =
                        !prevMessage || prevMessage.senderId !== msg.senderId;
                      const isTimeGap =
                        !prevMessage ||
                        !isWithinTwoMinutes(
                          prevMessage.timestamp,
                          msg.timestamp
                        );

                      const showHeader = isDifferentSender || isTimeGap;
                      return (
                        <div
                          key={msg._id}
                          className={`message ${
                            msg.senderId === currentUser._id ? "you" : "other"
                          }`}
                          style={{
                            justifyContent: !msg.deleted ? "" : "center",
                            margin: !msg.deleted ? "" : "1.5rem 0rem",
                          }}
                        >
                          {!msg.deleted &&
                            showDeleteButton === msg._id &&
                            msg.senderId === currentUser._id && (
                              <div
                                className="messageActions"
                                style={{ marginRight: "1.5rem" }}
                              >
                                <button
                                  onClick={() =>
                                    handleDeleteMessage(msg._id, msg.senderId)
                                  }
                                  style={{
                                    marginLeft: "1rem",
                                    background: "transparent",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    shake
                                    style={{
                                      color: "#ff0505",
                                      fontSize: "1.7rem",
                                    }}
                                  />
                                </button>
                              </div>
                            )}

                          <div
                            className={`messageContent ${
                              msg.senderId === currentUser._id ? "you" : "other"
                            }`}
                            style={{
                              cursor: "pointer",
                              backgroundColor: msg.deleted
                                ? "#d3d3d3"
                                : showDeleteButton === msg._id
                                ? msg.senderId === currentUser._id
                                  ? "#6bb4ff"
                                  : "#988eff"
                                : msg.senderId !== currentUser._id
                                ? "#d7d3ff"
                                : "#bedeff",
                              borderRadius: !msg.deleted ? "" : "5rem",
                              padding: !msg.deleted ? "" : "0.5rem 1rem",
                            }}
                            onMouseDown={() =>
                              !msg.deleted && handleMouseDown(msg._id)
                            }
                          >
                            {showHeader && !msg.deleted ? (
                              <div
                                className="messageHeader"
                                style={{
                                  fontSize:
                                    fontSize > 1.3
                                      ? `${fontSize - 0.1}rem`
                                      : "1.3rem",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <strong>
                                  {msg.senderId === currentUser._id
                                    ? "You"
                                    : selectedUser.name}
                                </strong>
                                <div
                                  style={{
                                    textAlign: "right",
                                    fontSize:
                                      fontSize > 1.3
                                        ? `${fontSize - 0.6}rem`
                                        : "0.85rem",
                                    paddingLeft: "1rem",
                                  }}
                                >
                                  {formatTimestamp(msg.timestamp)}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div
                              className="messageBody"
                              style={{
                                minWidth: msg.isGif ? "auto" : "10rem",
                                fontSize: !msg.deleted
                                  ? getMessageFontSize(msg.decryptedMessage)
                                  : "1rem",
                                textAlign: !msg.deleted ? "" : "center",
                              }}
                            >
                              {!showHeader && !msg.deleted ? (
                                <div
                                  className="nonameMsgHeader"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize:
                                        fontSize > 1.3
                                          ? `${fontSize - 0.6}rem`
                                          : "0.85rem",
                                      textAlign: "left",
                                    }}
                                  >
                                    {formatTimestamp(msg.timestamp)}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {msg.deleted ? (
                                `${
                                  msg.senderId === currentUser._id
                                    ? "You"
                                    : getUserNameById(msg.senderId)
                                } deleted a message`
                              ) : msg.isGif ? (
                                <img
                                  src={msg.decryptedMessage}
                                  alt="GIF"
                                  className="gifMessage"
                                  style={{ height: `${gifSize}rem` }}
                                  onLoad={() => scrollToBottom}
                                />
                              ) : (
                                msg.decryptedMessage
                              )}
                            </div>
                          </div>
                          <div ref={messagesEndRef} />
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            {selectedUser &&
              !localBlockStatus[selectedUser._id] &&
              !isBlockedBySelectedUser(selectedUser._id) && (
                <div className="charAreafoot">
                  <button
                    onClick={toggleEmojiPicker}
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <span role="img" aria-label="emoji">
                      <i
                        className="fa-regular fa-face-smile"
                        style={{ color: "#ffffff", fontSize: "2.6rem" }}
                      ></i>
                    </span>
                  </button>
                  {showEmojiPicker && emojiData && (
                    <div className="emojiPickerContainer">
                      <MemoizedEmojiPicker onEmojiSelect={handleEmojiClick} />
                    </div>
                  )}
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={toggleGifPicker}
                  >
                    <img src={gifer} alt="gif" style={{ width: "3rem" }} />
                  </button>
                  {showGifPicker && (
                    <div className="gifPickerContainer">
                      <MemoizedGifPicker
                        tenorApiKey={"AIzaSyB5oXqUW5nKyMwA4zg2K9s76IgAtcndrns"}
                        onGifClick={handleGifSelect}
                      />
                    </div>
                  )}

                  <textarea
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      handleTyping();
                    }}
                    rows="3"
                    style={{
                      width: "80%",
                      resize: "none",
                      padding: "1rem",
                      borderRadius: "5rem",
                      outline: "none",
                      overflow: "hidden",
                      caretColor: "red",
                      fontSize: "x-large",
                    }}
                  ></textarea>
                  <button
                    className="sendmsg"
                    onClick={handleSendMessage}
                    style={{
                      width: "12%",
                      background: "transparent",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <img src={sentIcon} alt="Send" style={{ width: "4rem" }} />
                  </button>
                </div>
              )}
          </div>
        ) : (
          <div className="Nochathello">
            <img
              src={chatb}
              alt=""
              style={{
                marginRight: "0rem",
                width: "90%",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 className="display-6" style={{ marginRight: "1rem" }}>
                your chats are End-to-End encrypted
              </h2>
              <i
                className="fa-solid fa-lock"
                style={{ fontSize: "xx-large", color: "aqua" }}
              ></i>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatComponent;
