import React from "react";
import userContext from "./userContext";

const UserState = (props) => {
  
  return (
    <userContext.Provider value={{ }}>
      {props.children}
    </userContext.Provider>
  );
};

export default UserState;
