import React from 'react';

// Recursive component to display folders and subfolders
function FolderOptions({ folders, level = 0 }) {
  return (
    <>
      {folders.map((folder) => (
        <React.Fragment key={folder._id}>
          <option value={folder._id} style={{ paddingLeft: `${level * 10}px` }}>
            {folder.name}
          </option>
          {folder.subfolders && folder.subfolders.length > 0 && (
            <FolderOptions folders={folder.subfolders} level={level + 1} />
          )}
        </React.Fragment>
      ))}
    </>
  );
}

function MoveFileModal({ showDropdown, folders, selectedFolder, handleSelectFolder, handleMoveFiles }) {
  return (
    showDropdown && (
      <div>
        <select onChange={handleSelectFolder} value={selectedFolder}>
          <option value="">Select Folder</option>
          <FolderOptions folders={folders} />
        </select>
        <button onClick={handleMoveFiles}>Move</button>
      </div>
    )
  );
}

export default MoveFileModal;
