import React, { useEffect, useRef } from "react";
import "../../App.css";

const CreateFolderModal = ({
  creator,
  setCreator,
  folderName,
  setFolderName,
  handleCreateFolder,
}) => {
  const modalRef = useRef(null);
  const bootstrapModal = useRef(null);

  // Handle showing the modal
  const showModal = () => {
    if (modalRef.current) {
      bootstrapModal.current = new window.bootstrap.Modal(modalRef.current);
      bootstrapModal.current.show();
    }
  };

  // Handle hiding the modal
  const hideModal = () => {
    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
      setCreator(false); // Close the modal and reset the creator state
    }
  };

  useEffect(() => {
    if (creator) {
      showModal();
    }
    const modalElement = modalRef.current;
    const handleOutsideClick = () => {
      setCreator(false);
    };
    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleOutsideClick);
    }
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleOutsideClick);
      }
    };
  }, [creator, setCreator]);

  return (
    <div
      className="modal fade"
      id="createFolderModal"
      tabIndex="-1"
      aria-labelledby="createFolderModalLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content" id="createfoldermodal" style={{color:'black'}}>
          <div className="modal-header">
            <h5 className="modal-title" id="createFolderModalLabel">
              New Folder
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            ></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              placeholder="Enter folder name"
              className="form-control"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleCreateFolder();
                hideModal();
              }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
