import React, { useContext, useEffect,useState } from "react";
import "../App.css";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "./Spinner";
import ProgressBar from "./progressBar";
import Sidebar from "./Sidebar";
import GreatContext from "../context/GreatContext";
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
 
function MyDashboardComp() { 
  // let history = useHistory();
  // useEffect(() => {
  //   const timeoutRef = localStorage.getItem("logtime");
  //   const currentTime = new Date().toISOString();

  //   const tenMinutesInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds

  //   // Calculate the difference between current time and timeout reference time
  //   const timeDifference = new Date(currentTime) - new Date(timeoutRef);

  //   // Check if the time difference exceeds 10 minutes
  //   if (timeDifference >= tenMinutesInMilliseconds) {
  //     // Perform your action here, e.g., logout the user
  //     localStorage.removeItem("logtime")
  //     localStorage.removeItem("token");
  //     // Redirect to login page or any other desired action after logout
  //     history.push("/login");
  //   } else {
  //     const currentTime2 = new Date().toISOString();
  //     localStorage.setItem("logtime",currentTime2);
  //   }
    
  // }, [history]);
  const GrConst = useContext(GreatContext);
  const {
    loading,
    loading2,
    loading4,
    downloadProgress,
    uploadProgress,
    loading3,
    zippingProgress,
    isZipping,
    isDownloading,
    downloadType,
    isFetching,
    fetchingProgress,
    users,
    fetchUsers,
    sharedItems,
    fetchSharedItems,
    fetchSharedFolderContents,
    receivedTodayFolders,
    receivedTodayFiles,
    sentTodayFiles,
    sentTodayFolders,
    fetchSentItems,
    fetchReceivedTodayCounts,
    currentUser,
    fetchCurrentUser,
    profilePic,
    fetchProfilePic,
    fetchUserItemCounts,
    totalFiles,
    totalFolders,
    uploadType,
    loading5,

  } = GrConst;
  useEffect(() => {
    fetchUsers();
    fetchCurrentUser();
    fetchSharedItems();
    // eslint-disable-next-line
  }, []);
  const[showNav,setShowNav]=useState(false);
  const handleResize = () => {
    const isMobile = window.innerWidth < 1600; // Adjust the width threshold as necessary
    setShowNav(isMobile);
  };

  useEffect(() => {
    // Set initial state based on current width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);
  return (
    <>
      <div className="wrapper">
        {loading && <Spinner />}
        {showNav ? <Navbar /> : <Sidebar/>}

        <div id="wrapper">
          <div className="fileFolderaccess">
            {loading4 && <div className="loader"></div>}
            {loading2 &&
              (downloadType === "folder" || downloadType === "file") && (
                <div className="fixed-progress-bar">
                  <div>
                    {downloadType === "folder" ? (
                      isFetching ? (
                        <h6>Processing...</h6>
                      ) : isZipping ? (
                        <h6>Downloading Folder...</h6>
                      ) : (
                        <h6>Finalizing...</h6>
                      )
                    ) : downloadType === "file" ? (
                      isDownloading ? (
                        <h6>Downloading File...</h6>
                      ) : (
                        <h6>Preparing File...</h6> // Optional: Handle the case if needed
                      )
                    ) : (
                      <h6>Unknown Download Type...</h6> // Optional: Handle unexpected cases
                    )}
                  </div>
                  <ProgressBar
                    progress={
                      downloadType === "folder"
                        ? isFetching
                          ? Math.round(fetchingProgress ?? 0)
                          : isZipping
                          ? Math.round(zippingProgress ?? 0)
                          : Math.round(downloadProgress ?? 0)
                        : Math.round(downloadProgress ?? 0)
                    }
                  />
                </div>
              )}

            {loading3 && (
              <div className="fixed-progress-bar">
                <div>
                  {Math.round(uploadProgress) !== 100 ? (
                    <>
                      <h6>Uploading...</h6>
                      <ProgressBar progress={Math.round(uploadProgress)} />
                    </>
                  ) : uploadType === "file" ? (
                    <>
                      <h6
                        className="display-6"
                        style={{ fontSize: "large", fontWeight: "500" }}
                      >
                        Upload completed
                      </h6>
                      <h6
                        className="display-6"
                        style={{ fontSize: "medium", fontWeight: "400" }}
                      >
                        <br />
                        <br />
                        Processing your file, you're free to surf around...
                      </h6>
                    </>
                  ) : (
                    <>
                      <h6
                        className="display-6"
                        style={{ fontSize: "large", fontWeight: "500" }}
                      >
                        Upload completed
                      </h6>
                      <h6
                        className="display-6"
                        style={{ fontSize: "medium", fontWeight: "400" }}
                      >
                        <br />
                        <br />
                        Processing your folder, you're free to surf around...
                      </h6>
                    </>
                  )}
                </div>
              </div>
            )}
              <Dashboard
                users={users}
                loading5={loading5}
                sharedItems={sharedItems}
                fetchSharedItems={fetchSharedItems}
                fetchSharedFolderContents={fetchSharedFolderContents}
                receivedTodayFiles={receivedTodayFiles}
                receivedTodayFolders={receivedTodayFolders}
                sentTodayFiles={sentTodayFiles}
                sentTodayFolders={sentTodayFolders}
                fetchSentItems={fetchSentItems}
                fetchReceivedTodayCounts={fetchReceivedTodayCounts}
                currentUser={currentUser}
                profilePic={profilePic}
                fetchProfilePic={fetchProfilePic}
                totalFiles={totalFiles}
                totalFolders={totalFolders}
                fetchUserItemCounts={fetchUserItemCounts}
              />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyDashboardComp;
