import React, { useState, useEffect } from "react";
import FolderList from "./FolderList"; // Reusing the FolderList component
import SharedFileSection from "./SharedFileSection";
import Spinner from "./Spinner"; // Assuming you have a spinner for loading
import { Link } from "react-router-dom";

const SharedFolder = ({
  folders,
  files,
  handleDownload,
  handleViewPdf,
  fetchFolders,
  fetchFolderContents,
  handleFolderDownload,
  navigationHistory,
  handleBack,
  resetNavigationHistory,
  breadcrumb,
  setBreadcrumb,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchFolders(); // Wait for folders to be fetched
      setLoading(false);
    };
  
    loadData(); // Call the async function
    // eslint-disable-next-line
  }, []);
  
  const updateBreadcrumb = (folderName, folderId) => {
    setBreadcrumb((prevBreadcrumb) => {
      // Keep breadcrumb history and add new folder
      const updatedBreadcrumb = [...prevBreadcrumb];
      updatedBreadcrumb.push({
        name: folderName,
        link: `/folders/${folderId}`,
      });
      return updatedBreadcrumb;
    });
  };
  const handleHomeClick = async () => {
    setLoading(true);
    setBreadcrumb([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    resetNavigationHistory();
    await fetchFolders(); // Fetch the root folders
    setLoading(false);
  };
  // Example function to handle folder navigation
  const handleFolderDoubleClick = async (folder) => {
    console.log(folder);
    setLoading(true); // Start loading
    updateBreadcrumb(folder.name, folder._id); // Update breadcrumb
    await fetchFolderContents(folder._id);
    setCurrentFolder(folder);
    setLoading(false); // Stop loading
  };
  const getFilesInCurrentFolder = () => {
    if (!currentFolder) {
      return [];
    }
    return files.filter((file) => file.path.startsWith(currentFolder.path));
  };
  const filesInCurrentFolder = getFilesInCurrentFolder();
  const handleBreadcrumbClick = (index) => {
    setBreadcrumb((prevBreadcrumb) => prevBreadcrumb.slice(0, index + 1));
  };
  return (
    <div id="folderSecDiv">
      {loading ? (
        <Spinner /> // Show spinner when loading
      ) : (
        <>
          <h4>Shared Files & Folders</h4>
          <nav
            aria-label="breadcrumb"
            style={{ fontSize: "larger", fontWeight: "600" }}
          >
            <ol className="breadcrumb">
              {breadcrumb.map((item, index) => (
                <li
                  key={index}
                  className={`breadcrumb-item ${
                    index === breadcrumb.length - 1 ? "active" : ""
                  }`}
                  aria-current={
                    index === breadcrumb.length - 1 ? "page" : undefined
                  }
                >
                  {index === 0 ? ( // Check if it's the "Home" breadcrumb
                    <Link
                      to={item.link}
                      onClick={(e) => {
                        e.preventDefault();
                        handleHomeClick(); // Call the handleHomeClick function
                      }}
                    >
                      {item.name}
                    </Link>
                  ) : index === breadcrumb.length - 1 ? (
                    item.name
                  ) : (
                    <Link
                      to={item.link}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor link behavior
                        handleBreadcrumbClick(index);
                        const folderId = item.link.split("/").pop();
                        fetchFolderContents(folderId);
                      }}
                    >
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ol>
          </nav>

          {navigationHistory.length > 0 && (
            <button onClick={handleBack} className="btn btn-secondary mb-3">
              Back
            </button>
          )}

          {folders.length > 0 && ( // Conditionally render the heading
            <h3>Folders</h3>
          )}
          <FolderList
            folders={folders}
            fetchFolderContents={fetchFolderContents}
            handleFolderDownload={handleFolderDownload}
            setLoading={setLoading}
            updateBreadcrumb={updateBreadcrumb}
            handleFolderDoubleClick={handleFolderDoubleClick}
          />
          {currentFolder &&
            filesInCurrentFolder.length > 0 && ( // Conditionally render Files heading
              <h3>Files</h3>
            )}
          {currentFolder &&
            filesInCurrentFolder.length === 0 && ( // Conditionally render No files here text
              <p>No files here</p>
            )}
          <SharedFileSection
            files={getFilesInCurrentFolder()} // Filter and pass the files for the current folder
            handleDownload={handleDownload}
            handleViewPdf={handleViewPdf}
          />
        </>
      )}
    </div>
  );
};

export default SharedFolder;
