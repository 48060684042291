import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import RenameFolderModal from "./renamefolderModal";
import downloadicon from "../icons/downloadimg.png";
import renameicon from "../icons/renameicon.png";
import trashicon from "../icons/trashicon.png";
import normalFolder1 from "../icons/normalFolder1.png";
import normalFolder2 from "../icons/normalFolder2.png";
import normalFolder3 from "../icons/normalFolder3.png";
import normalFolder4 from "../icons/normalFolder4.png";
import "../App.css";

const SharedFolderList = ({
  handleFolderDownload,
  handleDeleteFolder,
  handleRenameClick2,
  folderToRename,
  newFolderName,
  setNewFolderName,
  handleRenameFolder,
  setLoading,
  updateBreadcrumb,
  selectedFolders,
  setSelectedFolders,
  selectedFiles,
  users,
  sharedItems,
  setSharedItems,
  fetchSharedFolderContents,
  handleFolderDoubleClick,
  fetchSharedItems,
  currentUser,
  setFolderToRename,
}) => {
  const [isSelecting, setIsSelecting] = useState(false);
  // eslint-disable-next-line
  const selectionRef = useRef(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownMenuRefs = useRef({});
  const dropdownButtonRefs = useRef({});
  const ws = useRef(null);
  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideDropdown = Object.values(
        dropdownMenuRefs.current
      ).some((ref) => ref?.contains(event.target));
      const isClickOnButton = Object.values(dropdownButtonRefs.current).some(
        (ref) => ref?.contains(event.target)
      );

      if (!isClickInsideDropdown && !isClickOnButton) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (folderId) => {
    if (openDropdownId === folderId) {
      setOpenDropdownId(null); // Close the dropdown
    } else {
      // Close the currently open dropdown
      if (openDropdownId !== null && dropdownMenuRefs.current[openDropdownId]) {
        const currentDropdownMenu = dropdownMenuRefs.current[openDropdownId];
        currentDropdownMenu.classList.remove("show");
        void currentDropdownMenu.offsetWidth; // Trigger reflow
      }
      setOpenDropdownId(folderId); // Open the new dropdown
    }
  };

  const handleItemClick = (callback) => {
    callback();
    setOpenDropdownId(null); // Close the dropdown after clicking an item
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);

  const getFolderIconAndColor = (size) => {
    if (size < 10 * 1024 * 1024) {
      // Below 10MB
      return { icon: normalFolder1, color: "#fff8a6" };
    } else if (size >= 10 * 1024 * 1024 && size < 25 * 1024 * 1024) {
      // 10MB to 25MB
      return { icon: normalFolder2, color: "#9fffd8" };
    } else if (size >= 25 * 1024 * 1024 && size < 50 * 1024 * 1024) {
      // 25MB to 50MB
      return { icon: normalFolder3, color: "#8dd5ff" };
    } else {
      // Greater than 50MB
      return { icon: normalFolder4, color: "#ffc0c0" };
    }
  };
  const infoRef = useRef(null);
  const [selectedDroper, setSelectedDroper] = useState(false);
  const handleMouseDown = (folder) => {
    // Set a timeout to detect a long press
    selectionRef.current = setTimeout(() => {
      setSelectedDroper(true);
      handleDropdownToggle(folder._id); // Trigger dropdown after holding for 500ms
    }, 500);
  };

  const handleMouseUp = () => {
    // Clear the timeout if the mouse is released before 500ms
    if (selectionRef.current) {
      clearTimeout(selectionRef.current);
    }
    setTimeout(() => {
      selectionRef.current = null;
      setSelectedDroper(false);
    }, 500);
  };

  const handleMouseClick = (folder) => {
    if (!isSelecting) {
      handleFolderDoubleClick(folder);
    } else if (isSelecting) {
      if (selectedFiles.length === 0) {
        setSelectedFolders((prevSelectedFolders) => {
          const updatedSelection = prevSelectedFolders.includes(folder._id)
            ? prevSelectedFolders.filter((id) => id !== folder._id)
            : [...prevSelectedFolders, folder._id];
          return updatedSelection;
        });
      }
    }
  };

  const handleUnselect = () => {
    setSelectedFolders([]);
    setIsSelecting(false);
  };

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      if (selectionRef.current) {
        clearTimeout(selectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (infoRef.current) {
      if (isSelecting) {
        infoRef.current.classList.add("visible");
      } else {
        infoRef.current.classList.remove("visible");
      }
    }
  }, [isSelecting]);

  useEffect(() => {
    if (currentUser) {
      if (!ws.current) {
        ws.current = new WebSocket("wss://secure-storage-system-mern-cloud-file.onrender.com");

        ws.current.onopen = () => {
          if (currentUser && currentUser._id) {
            ws.current.send(
              JSON.stringify({
                type: "set-user-id",
                userId: currentUser._id,
              })
            );
          }
        };
        // Assuming `ws` is your WebSocket connection
        ws.current.onmessage = async (event) => {
          try {
            const data = JSON.parse(event.data);

            if (data.type === "new-shared-item") {
              await fetchSharedItems(); // Update the shared items when a new one is received
            }
          } catch (error) {
            console.error("Error processing WebSocket message:", error);
          }
        };

        ws.current.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.current.onclose = () => {
          // console.log("WebSocket connection closed");
        };

        return () => {
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.close();
          }
        };
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const getUserNameById = (id) => {
    const user = users.find((user) => user._id === id);
    return user ? user.name : "Unknown";
  };

  return (
    <div>
      <RenameFolderModal
        folderToRename={folderToRename}
        newFolderName={newFolderName}
        setNewFolderName={setNewFolderName}
        handleRenameFolder={handleRenameFolder}
        setFolderToRename={setFolderToRename}
      />
      <div
        ref={infoRef}
        className={`selected-folders-info ${isSelecting ? "visible" : ""}`}
      >
        {isSelecting && (
          <>
            <span>
              {selectedFolders.length} folder
              {selectedFolders.length > 1 ? "s" : ""} selected
            </span>
            <button onClick={handleUnselect}>Unselect All</button>
          </>
        )}
      </div>
      <div className="container text-center">
        <div className="selectionRef" ref={selectionRef}>
          <div className="row row-cols-3 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
            {sharedItems.folders.map((folder) => {
              const { icon, color } = getFolderIconAndColor(folder.size);
              let isDropdownOpen = openDropdownId === folder._id;
              return (
                <div
                  key={folder._id}
                  className="col mb-4 d-flex justify-content-center"
                >
                  <div
                    key={folder._id}
                    className={`card ${
                      selectedFolders.includes(folder._id)
                        ? "selected-folder"
                        : ""
                    }`}
                    style={{
                      width: "11rem",
                      height: "15rem",
                      backgroundColor:
                        hoveredItemId === folder._id ? color : "transparent",
                      border: "none",
                      transition: "all 0.1s ease-in-out",
                      cursor: "pointer",
                      borderRadius: "0.8rem",
                    }}
                    onMouseEnter={() => setHoveredItemId(folder._id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                    onDoubleClick={() => handleFolderDoubleClick(folder)}
                    onMouseDown={(event) => handleMouseDown(folder, event)}
                    onMouseUp={handleMouseUp}
                    onTouchStart={(event) => handleMouseDown(folder, event)}
                    onTouchEnd={handleMouseUp}
                    onClick={(event) => {
                      // Disable folder click if dropdown was triggered
                      if (!selectedDroper) {
                        handleMouseClick(folder);
                      } else {
                        event.preventDefault();
                      }
                    }}
                  >
                    <img
                      src={icon} // Get the appropriate icon based on folder size
                      alt="folder-icon"
                      style={{ height: "10rem", objectFit: "cover" }}
                    />
                    <div
                      className={`card-body d-flex justify-content-center align-items-center ${
                        selectedFolders.includes(folder._id)
                          ? "selected-folder"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          hoveredItemId === folder._id ? color : "#212529",
                        padding: "0rem",
                        borderRadius: "0.8rem",
                        transition: "all 0.3s ease-in-out",
                      }}
                    >
                      <div
                        className="dropdown d-flex justify-content-between"
                        style={{ width: "100%" }}
                      >
                        <h5
                          className={`card-title ${
                            selectedFolders.includes(folder._id)
                              ? "selected-folder-title"
                              : ""
                          }`}
                          style={{
                            padding: "0.8rem",
                            textAlign: "left",
                            color:
                              hoveredItemId === folder._id ? "black" : "white",
                          }}
                        >
                          {folder.name.includes(" ")
                            ? folder.name.length > 28
                              ? folder.name.substring(0, 25) + "..."
                              : folder.name
                            : folder.name.length > 13
                            ? folder.name.substring(0, 11) + "..."
                            : folder.name}
                          - Owned by, {getUserNameById(folder.user)}
                        </h5>
                        {folder.sharedWith.some(
                          (sharedUser) =>
                            sharedUser.userId === currentUser._id &&
                            sharedUser.permissions === "edit"
                        ) && (
                          <>
                            <button
                              type="button"
                              className="dropdownMenuButton2 btn btn-secondary dropdown-toggle"
                              id={`dropdownMenuButton${folder._id}`}
                              data-bs-toggle="dropdown"
                              aria-expanded={isDropdownOpen}
                              style={{
                                backgroundColor:
                                  hoveredItemId === folder._id
                                    ? "transparent"
                                    : "transparent",
                                width: "2rem",
                                borderRadius: "1rem",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDropdownToggle(folder._id);
                              }}
                              ref={(el) =>
                                (dropdownButtonRefs.current[folder._id] = el)
                              }
                            >
                              <i
                                className={`fa-solid fa-ellipsis-vertical ${
                                  selectedFolders.includes(folder._id)
                                    ? "selected-folder-btn"
                                    : ""
                                }`}
                                style={{
                                  fontSize: "x-large",
                                  color:
                                    hoveredItemId === folder._id
                                      ? "black"
                                      : "white",
                                }}
                              />
                            </button>
                            <ul
                              className={`dropdown-menu sharerFolder ${
                                isDropdownOpen ? "show" : ""
                              }`}
                              aria-labelledby={`dropdownMenuButton${folder._id}`}
                              ref={(el) =>
                                (dropdownMenuRefs.current[folder._id] = el)
                              }
                            >
                              <li
                                className="dropdown-item"
                                onClick={(event) => {
                                  event.stopPropagation();

                                  handleItemClick(() =>
                                    handleRenameClick2({
                                      _id: folder._id,
                                      name: folder.name,
                                    })
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <label className="iconic">
                                  <img
                                    src={renameicon}
                                    alt="rename"
                                    style={{
                                      width: "20px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  Rename
                                </label>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={(event) => {
                                  event.stopPropagation();

                                  handleItemClick(() =>
                                    handleFolderDownload(
                                      folder._id,
                                      folder.name
                                    )
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <label className="iconic">
                                  <img
                                    src={downloadicon}
                                    alt="download"
                                    style={{
                                      width: "20px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  Download
                                </label>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={(event) => {
                                  event.stopPropagation();

                                  handleItemClick(() =>
                                    handleDeleteFolder(folder._id)
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <label className="iconic">
                                  <img
                                    src={trashicon}
                                    alt="delete"
                                    style={{
                                      width: "20px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  Delete
                                </label>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {folder.subfolders && folder.subfolders.length > 0 && (
                    <SharedFolderList
                      folders={folder.subfolders}
                      handleFolderDownload={handleFolderDownload}
                      handleDeleteFolder={handleDeleteFolder}
                      handleRenameClick2={handleRenameClick2}
                      folderToRename={folderToRename}
                      newFolderName={newFolderName}
                      setNewFolderName={setNewFolderName}
                      handleRenameFolder={handleRenameFolder}
                      setLoading={setLoading}
                      updateBreadcrumb={updateBreadcrumb}
                      selectedFolders={selectedFolders}
                      setSelectedFolders={setSelectedFolders}
                      selectedFiles={selectedFiles}
                      sharedItems={sharedItems}
                      setSharedItems={setSharedItems}
                      users={users}
                      fetchSharedFolderContents={fetchSharedFolderContents}
                      handleFolderDoubleClick={handleFolderDoubleClick}
                      fetchSharedItems={fetchSharedItems}
                      currentUser={currentUser}
                      setFolderToRename={setFolderToRename}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function SharedWithMe2({
  setLoading,
  handleFolderDownload,
  handleDeleteFolder,
  handleRenameClick2,
  folderToRename,
  newFolderName,
  setNewFolderName,
  handleRenameFolder,
  navigationHistory2,
  handleBack2,
  resetNavigationHistory2,
  breadcrumb2,
  setBreadcrumb2,
  selectedFolders,
  setSelectedFolders,
  selectedFiles,
  users,
  sharedItems,
  setSharedItems,
  fetchSharedItems,
  fetchSharedFolderContents,
  currentUser,
  setNavigationHistory2,
  setFolderToRename,
}) {
  // useEffect(() => {
  //   if (navigationHistory2.length === 0) {
  //     const fetchItems = async () => {
  //       setLoading(true);
  //       await fetchSharedItems(); // Fetch the root folders
  //       setBreadcrumb2([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
  //       setLoading(false);
  //     };
  
  //     fetchItems();
  //   }
  //   // eslint-disable-next-line
  // }, [navigationHistory2]); // This effect runs whenever navigationHistory2 changes
  
  const updateBreadcrumb = (folderName, folderId) => {
    setBreadcrumb2((prevBreadcrumb) => {
      // Check if the folder already exists in the breadcrumb to avoid duplicates
      const existingIndex = prevBreadcrumb.findIndex(
        (item) => item.link === `/folders/${folderId}`
      );
      if (existingIndex === -1) {
        return [
          ...prevBreadcrumb,
          { name: folderName, link: `/folders/${folderId}` },
        ];
      }
      return prevBreadcrumb;
    });
  };

  const handleHomeClick = async () => {
    setLoading(true);
    setBreadcrumb2([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    resetNavigationHistory2();
    await fetchSharedItems(); // Fetch the root folders
    setLoading(false);
  };

  const handleFolderDoubleClick = async (folder) => {
    setLoading(true); // Start loading
    updateBreadcrumb(folder.name, folder._id); // Update breadcrumb
    setNavigationHistory2((prevHistory) => {
      const newHistory = [...prevHistory, folder.parentId];
      return newHistory;
    });
    const folderContents = await fetchSharedFolderContents(folder._id);
    if (folderContents) {
      setSharedItems({
        files: folderContents.files, // Assuming folderContents contains files
        folders: folderContents.folders, // Assuming folderContents contains folders
      });
    }
    setLoading(false); // Stop loading
  };
  const handleBreadcrumbClick = async (index) => {
    setLoading(true);
    const folderId = breadcrumb2[index].link.split("/").pop();
    setBreadcrumb2((prevBreadcrumb) => prevBreadcrumb.slice(0, index + 1));

    setNavigationHistory2((prevHistory) => {
      const folderIndex = prevHistory.indexOf(folderId);

      if (folderIndex !== -1) {
        const newHistory = prevHistory.slice(0, folderIndex);
        return newHistory;
      }
      return prevHistory;
    });

    if (folderId !== "folders") {
      setLoading(true);
      const folderContents = await fetchSharedFolderContents(folderId);
      if (folderContents) {
        setSharedItems({
          files: folderContents.files,
          folders: folderContents.folders,
        });
      }
      setLoading(false);
    } else {
      handleHomeClick(); // Navigate to the root folders if "Home" is clicked
    }
  };

  return (
    <div id="folderSecDiv">
      {navigationHistory2.length > 0 && (
        <>
          <nav
            aria-label="breadcrumb"
            style={{ fontSize: "larger", fontWeight: "600" }}
          >
            <ol className="breadcrumb">
              {breadcrumb2.map((item, index) => (
                <li
                  key={index}
                  className={`breadcrumb-item ${
                    index === breadcrumb2.length - 1 ? "active" : ""
                  }`}
                  aria-current={
                    index === breadcrumb2.length - 1 ? "page" : undefined
                  }
                  style={{ color: "#ccc0fe" }}
                >
                  {index === 0 ? ( // Check if it's the "Home" breadcrumb
                    <Link
                      to={item.link}
                      onClick={(e) => {
                        e.preventDefault();
                        handleHomeClick(); // Call the handleHomeClick function
                      }}
                    >
                      {item.name}
                    </Link>
                  ) : index === breadcrumb2.length - 1 ? (
                    item.name
                  ) : (
                    <Link
                      to={item.link}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor link behavior
                        handleBreadcrumbClick(index);
                        const folderId = item.link.split("/").pop();
                        fetchSharedFolderContents(folderId);
                      }}
                    >
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ol>
          </nav>
          <button onClick={handleBack2} className="btn btn-secondary mb-3">
            Back
          </button>
        </>
      )}
      {sharedItems.folders.length > 0 && (
        <>
          <SharedFolderList
            fetchSharedFolderContents={fetchSharedFolderContents}
            handleFolderDownload={handleFolderDownload}
            handleDeleteFolder={handleDeleteFolder}
            handleRenameClick2={handleRenameClick2}
            folderToRename={folderToRename}
            newFolderName={newFolderName}
            setNewFolderName={setNewFolderName}
            handleRenameFolder={handleRenameFolder}
            setLoading={setLoading}
            onFolderDoubleClick={handleFolderDoubleClick}
            updateBreadcrumb={updateBreadcrumb}
            selectedFolders={selectedFolders}
            setSelectedFolders={setSelectedFolders}
            selectedFiles={selectedFiles}
            sharedItems={sharedItems}
            setSharedItems={setSharedItems}
            users={users}
            handleFolderDoubleClick={handleFolderDoubleClick}
            fetchSharedItems={fetchSharedItems}
            currentUser={currentUser}
            setFolderToRename={setFolderToRename}
          />
        </>
      )}
    </div>
  );
}
export default SharedWithMe2;
