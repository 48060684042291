import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap first
import "./App.css"; // Custom CSS after
import { FileProvider } from "./Components/traverse";
import Main from "./Components/main";
import GreatState from "./context/GreatState";
import AccessShared from "./Components/AccessShared";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UserState from "./context/User/UserState";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import MyDashboardComp from "./Components/MyDashboardComp";
import ShareWithMeComp from "./Components/ShareWithMeComp";
import MyChatsComp from "./Components/MyChatsComp";
import TeamsComp from "./Components/TeamsComp";
// import FirstPage from "./Components/FirstPage";
import FirstPage from "./Components/LandingPage";

function App() {
  useEffect(() => {
    const ws = new WebSocket("wss://secure-storage-system-mern-cloud-file.onrender.com");

    ws.onopen = () => {
      // console.log('WebSocket connection opened');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.authComplete) {
        const fileId = data.fileId;
        if (fileId) {
          const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/googleAPI/viewFile/${fileId}`;

          if (!localStorage.getItem("tabOpened")) {
            window.open(url, "_blank");
            localStorage.setItem("tabOpened", "true");
            setTimeout(() => {
              localStorage.removeItem("tabOpened");
            }, 1000);
          }
        }
      }
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
        console.log("WebSocket connection closed");
      }
    };
  }, []);
  const [itemType, setItemType] = useState(null);
  let bannerTimeout = null;

  const showBanner = (selector, item) => {
    hideBanners2(); // First hide any open banners
    requestAnimationFrame(() => {
      const banner = document.querySelector(selector);
      if (banner) {
        banner.classList.add("visible");
      }
    });

    setItemType(item);

    // Clear previous timeout if it exists, and start a new one
    if (bannerTimeout) {
      clearTimeout(bannerTimeout);
    }

    bannerTimeout = setTimeout(() => {
      hideBanners();
    }, 10000); // 10 seconds before hiding
  };

  const hideBanners = () => {
    document.querySelectorAll(".banner.visible").forEach((banner) => {
      banner.classList.add("closing");

      // Wait for the animation to finish before fully removing the banner
      banner.addEventListener(
        "animationend",
        () => {
          banner.classList.remove("closing");
          banner.classList.remove("visible");
        },
        { once: true }
      );
    });
  };
  const hideBanners2 = () => {
    document
      .querySelectorAll(".banner.visible")
      .forEach((b) => b.classList.remove("visible"));
  };

  return (
    <UserState>
      <FileProvider>
        <GreatState>
          <Router>
            <div className="shareItemModf">
              <div className="banners-container">
                <div className="banners">
                  <div className="banner error">
                    <div className="banner-icon">
                      <i
                        data-eva="alert-circle-outline"
                        data-eva-fill="#ffffff"
                        data-eva-height="48"
                        data-eva-width="48"
                      ></i>
                    </div>
                    <div className="banner-message">
                      ERROR 503 -{" "}
                      {itemType === "file share" ? "File" : "Folder"} sharing
                      failed
                    </div>
                    <div className="banner-close" onClick={hideBanners}>
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  </div>
                  <div className="banner success">
                    <div className="banner-icon">
                      <i
                        data-eva="checkmark-circle-outline"
                        data-eva-fill="#ffffff"
                        data-eva-height="48"
                        data-eva-width="48"
                      ></i>
                    </div>
                    <div className="banner-message">
                      {itemType === "file share" ? "File" : "Folder"} has been
                      shared successfully
                    </div>
                    <div className="banner-close" onClick={hideBanners}>
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  </div>
                  <div className="banner info">
                    <div className="banner-icon">
                      <i
                        data-eva="info-outline"
                        data-eva-fill="#ffffff"
                        data-eva-height="48"
                        data-eva-width="48"
                      ></i>
                    </div>
                    <div className="banner-message">
                      {itemType === "file delete"
                        ? "File has been deleted successfully!"
                        : itemType === "folder delete"
                        ? "Folder deleted successfully!"
                        : "Something went wrong!"}
                    </div>
                    <div className="banner-close" onClick={hideBanners}>
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>
              <Route exact path="/home">
                <Main
                  showBanner={showBanner}
                  hideBanners={hideBanners}
                  setItemType={setItemType}
                /> 
              </Route>
              <Route exact path="/shared">
                <AccessShared />
              </Route>

              <Route exact path="/home/dashboard">
                <MyDashboardComp />
              </Route>
              <Route exact path="/home/sharedVault">
                <ShareWithMeComp />
              </Route>
              <Route exact path="/home/chatbox">
                <MyChatsComp />
              </Route>
              <Route exact path="/home/teams">
                <TeamsComp />
              </Route>
              <Route exact path="/">
                <FirstPage />
              </Route>
            </Switch>
          </Router>
        </GreatState>
      </FileProvider>
    </UserState>
  );
}

export default App;
