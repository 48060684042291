import React, { useState, useRef, useEffect } from "react";
import "../App.css"; // Include your CSS file for styling
import folderimg from "../icons/folderupload.png";
import fileimg from "../icons/fileupload.png";
import shareimg from "../icons/share.png";
import createfolderimg from "../icons/createfolder.png";
import CreateFolderModal from "./Modals/CreateFolderModal";

function NewButtonDropdown({
  setIsFolder,
  handleFileChange: propHandleFileChange,
  handleUpload,
  folderName,
  setFolderName,
  handleCreateFolder,
  inMobile
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State to control dropdown visibility
  const dropdownRef = useRef(null);
  const [creator, setCreator] = useState(false);

  const modalnewRef = useRef(null);
  const bootstrapModal = useRef(null);

  const showModal = async () => {
    if (modalnewRef.current) {
      bootstrapModal.current = new window.bootstrap.Modal(modalnewRef.current);
      bootstrapModal.current.show();
    }
  };
  const hideModal = () => {
    setSelectedFolder(null);
    setSelectedFile(null);
    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
    }
  };

  const openCreateFolderModal = () => {
    setCreator(true);
  };

  // Handle click outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedFile || selectedFolder) {
      showModal();
      setIsOpen(false);
    }
  }, [selectedFile, selectedFolder]);

  const handleClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen); // Toggle dropdown visibility
  };

  const handleFileUploadClick = () => {
    setIsFolder(false);
    document.getElementById("fileInput").click(); // Trigger file input click
  };

  const handleFolderUploadClick = () => {
    setIsFolder(true);
    document.getElementById("folderInput").click(); // Trigger folder input click
  };

  const handleFileChangeLocal = (event) => {
    const files = event.target.files;
    setSelectedFile(files.length > 0 ? files[0] : null);
    propHandleFileChange(event); // Call the prop function if needed
  };

  const handleFolderChange = (event) => {
    const files = event.target.files;
    setSelectedFolder(files.length > 0 ? files[0] : null);
    propHandleFileChange(event); // Call the prop function if needed
  };

  const confirmUpload = (event) => {
    handleUpload(event); // Call the actual upload handler
    hideModal();
    setSelectedFile(null);
    setSelectedFolder(null);
  };

  return (
    <>
      <div className={`dropdown ${inMobile ? 'inMobNew' : ''}`} ref={dropdownRef}>
        <button
          type="button"
          className="btn dropdown-toggle"
          id="dropdownMenuButton1"
          aria-expanded={isOpen}
          onClick={handleClick}
        >
          <i className="fa-solid fa-plus fa-beat"></i>
          <span style={{ paddingLeft: "0.5rem" }}>New</span>
        </button>

        <ul
          className={`dropdown-menu ${isOpen ? "show" : ""}`}
          id="uploader1"
          aria-labelledby="dropdownMenuButton1"
        >
          <li
            className="dropdown-item"
            onClick={handleFileUploadClick}
            style={{ cursor: "pointer" }}
          >
            <label>
              <img
                src={fileimg}
                alt="icn"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Upload File
            </label>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li
            className="dropdown-item"
            onClick={handleFolderUploadClick}
            style={{ cursor: "pointer" }}
          >
            <label>
              <img
                src={folderimg}
                alt="icn"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Upload Folder
            </label>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="dropdown-item" style={{ cursor: "pointer" }}>
            <label>
              <img
                src={shareimg}
                alt="icn"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Share a file or Folder
            </label>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={openCreateFolderModal}
          >
            <label>
              <img
                src={createfolderimg}
                alt="icn"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Create new folder
            </label>
          </li>
        </ul>

        {/* Hidden File Input */}
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChangeLocal}
          style={{ display: "none" }}
        />

        {/* Hidden Folder Inpt */}
        <input
          type="file"
          id="folderInput"
          webkitdirectory="true"
          onChange={handleFolderChange}
          style={{ display: "none" }}
        />

        <CreateFolderModal
          creator={creator}
          setCreator={setCreator}
          folderName={folderName}
          setFolderName={setFolderName}
          handleCreateFolder={handleCreateFolder}
        />
      </div>
      <div
        className="modal fade"
        id="uploadConf"
        style={{backgroundColor: "#000000a8" }}
        aria-hidden="true"
        tabIndex="-1"
        ref={modalnewRef}
      >
        <div
          className="modal-dialog"
          style={{ maxWidth: "100%", width: "100%" }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#0b0812f5",
              color: "white",
              height: "35%",
              width: "25%",
              borderRadius: "0.5rem",
              border: "1px solid #ccc0fe",
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingRight: "2rem",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
              }}
            >
              <div
                className="heading"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "flex-start",
                }}
              >
                <h5 className="modal-title">Security & Privacy</h5>
              </div>

              <button
                type="button"
                className="btn-close"
                style={{ backgroundColor: "cyan" }}
                onClick={() => hideModal()}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{ color: "white", padding: "1.2rem", overflowY: "auto" }}
            >
              <p>
                We take user security very seriously. All files uploaded are
                encrypted and stored securely. Please confirm that you
                understand and trust this process before proceeding.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => hideModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmUpload}
              >
                Confirm Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewButtonDropdown;
