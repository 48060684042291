import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import renameicon from "../icons/renameicon2.png";
import moveicon from "../icons/movefileicon2.png";
import "react-image-gallery/styles/css/image-gallery.css";
import RenameFileModal from "./renamefileModal";

const ImageViewer = ({
  onClose,
  handleDownload,
  handleDeleteFile,
  handleMoveClick,
  handleRenameClick,
  fileToRename,
  newFileName,
  setNewFileName,
  handleRenameFile,
  images,
  currentImageIndex,
  setCurrentImageIndex,
  ViewerVisible,
}) => {
  useEffect(() => {
    if (ViewerVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [ViewerVisible]);

  const handleOverlayClick = useCallback(
    (e) => {
      if (e.target.classList.contains("image-viewer-overlay")) {
        onClose();
      }
    },
    [onClose]
  );

  const handleImageGalleryChange = (currentIndex) => {
    setCurrentImageIndex(currentIndex);
  };

  const galleryItems = images.map((image) => ({
    original: image.src,
    thumbnail: image.src,
    description: image.filename,
  }));

  const mobilePdfDropper = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState); // Toggle dropdown state
  };

  return (
    <>
      {ViewerVisible && (
        <div
          className={`image-viewer-overlay ${ViewerVisible ? "visible" : ""}`}
          onClick={handleOverlayClick}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            className="image-viewer-header"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="initialer">
              <button
                className="close-button"
                onClick={onClose}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </button>
              <span
                className="file-name"
                style={{ padding: "0rem 1rem 0rem 1rem" }}
              >
                {images[currentImageIndex]?.filename || "No file selected"}
              </span>
            </div>

            <div className="pdf-mobile-options">
              {/* Button that opens the dropdown */}
              <button
                type="button"
                className="dropdownMenuButton2 btn btn-secondary dropdown-toggle dropend"
                id="mobilePdfoptions"
                data-bs-toggle="dropdown"
                aria-expanded={isDropdownOpen}
                style={{
                  backgroundColor: "transparent",
                  width: "2rem",
                  borderRadius: "1rem",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDropdownToggle(); // Toggle dropdown when clicked
                }}
                ref={mobilePdfDropper}
              >
                <i
                  className="fa-solid fa-ellipsis-vertical selected-folder-btn"
                  style={{
                    fontSize: "x-large",
                    color: "black",
                  }}
                />
              </button>

              {/* Dropdown menu */}
              <ul
                className={`dropdown-menu mof ${isDropdownOpen ? "show" : ""}`}
                aria-labelledby="mobilePdfoptions"
              >
                {/* Rename option */}
                <li className="dropdown-item" style={{ cursor: "pointer" }}>
                  <button
                    onClick={() => {
                      setIsDropdownOpen(false);
                      handleRenameClick(images[currentImageIndex]);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={renameicon}
                      alt="Rename"
                      style={{ width: "2rem" }}
                    />{" "}
                    <h4
                      className="display-6"
                      style={{ fontSize: "medium", color: "white" }}
                    >
                      Rename
                    </h4>
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                {/* Download option */}
                <li className="dropdown-item" style={{ cursor: "pointer" }}>
                  <button
                    onClick={() => {
                      setIsDropdownOpen(false);
                      handleDownload(
                        images[currentImageIndex]._id,
                        images[currentImageIndex].filename
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-download"
                      aria-hidden="true"
                      style={{ color: "white", fontSize: "1.5rem" }}
                    ></i>
                    <h4
                      className="display-6"
                      style={{ fontSize: "medium", color: "white" }}
                    >
                      Download
                    </h4>
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                {/* Delete option */}
                <li className="dropdown-item" style={{ cursor: "pointer" }}>
                  <button
                    onClick={() => {
                      setIsDropdownOpen(false);
                      handleDeleteFile(images[currentImageIndex]._id);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-trash"
                      aria-hidden="true"
                      style={{ color: "white", fontSize: "1.5rem" }}
                    ></i>
                    <h4
                      className="display-6"
                      style={{ fontSize: "medium", color: "white" }}
                    >
                      Delete
                    </h4>
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                {/* Move option */}
                <li className="dropdown-item" style={{ cursor: "pointer" }}>
                  <button
                    onClick={() => {
                      setIsDropdownOpen(false);
                      handleMoveClick(images[currentImageIndex]);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <img src={moveicon} alt="Move" style={{ width: "2rem" }} />
                    <h4
                      className="display-6"
                      style={{ fontSize: "medium", color: "white" }}
                    >
                      Move
                    </h4>
                  </button>
                </li>
              </ul>
            </div>

            <div className="image-viewer-options">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRenameClick(images[currentImageIndex]);
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={renameicon} alt="Rename" style={{ width: "2rem" }} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload(
                    images[currentImageIndex]._id,
                    images[currentImageIndex].filename
                  );
                }}
              >
                <i
                  className="fa fa-download"
                  aria-hidden="true"
                  style={{ color: "white" }}
                ></i>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFile(images[currentImageIndex]._id);
                }}
              >
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  style={{ color: "white" }}
                ></i>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleMoveClick(images[currentImageIndex]);
                }}
              >
                <img src={moveicon} alt="Move" style={{ width: "2rem" }} />
              </button>
            </div>
          </div>
          <div
            className="image-viewer-content"
            onClick={(e) => e.stopPropagation()}
          >
            <ImageGallery
              items={galleryItems}
              startIndex={currentImageIndex}
              onSlide={handleImageGalleryChange}
              showBullets={true}
              showPlayButton={false}
              showFullscreenButton={true}
              showThumbnails={true}
              useBrowserFullscreen={true}
            />
          </div>

          {fileToRename && (
            <RenameFileModal
              fileToRename={fileToRename}
              newFileName={newFileName}
              setNewFileName={setNewFileName}
              handleRenameFile={handleRenameFile}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ImageViewer;
