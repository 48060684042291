import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import "./Logc.css";

// Helper function to create an image element
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

// Function to get the cropped image blob
const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Set the canvas size to the crop area size
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  // Draw the image onto the canvas, cropping the correct area
  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
};

const CropUpdater = ({ image, onCrop }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (!image) return;
    try {
      const croppedBlob = await getCroppedImg(image, croppedAreaPixels);
      onCrop(croppedBlob);
    } catch (error) {
      console.error("Cropping failed", error);
    }
  };

  const handleZoomChange = useCallback((newZoom) => {
    const zoomMultiplier = 0.1; // Adjust this value for sensitivity
    setZoom((prevZoom) => {
      // Calculate the new zoom level with sensitivity adjustment
      const newZoomLevel = prevZoom + (newZoom - prevZoom) * zoomMultiplier;
      // Ensure the zoom level is within the desired range
      return Math.min(Math.max(newZoomLevel, 1), 3);
    });
  }, []);
  
  return (
    <>
      {image && (
        <div className="cropper-container">
          <div className="cropper">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={handleZoomChange}
            />
            <button type="button" className="crop-button" onClick={handleCrop}>
              Crop Image
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CropUpdater;
