import React, { useState, useEffect, useRef } from "react";
import "../App.css";

const SearchBarMain = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState({ files: [], folders: [] });
  const [showResults, setShowResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const resultsRef = useRef(null);
  const searchBarRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const debounceSearch = setTimeout(() => {
      if (query) {
        handleSearch(signal);
        setShowResults(true);
        setIsSearching(true);
      } else {
        setResults({ files: [], folders: [] });
        setShowResults(false);
        setIsSearching(false);
      }
    }, 300);

    return () => {
      clearTimeout(debounceSearch);
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query]);

  const handleSearch = async (signal) => {
    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/searchRoute/search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOnsiaWQiOiI2NmIzN2NhYTE5Y2E0ODY3NzkxNzg1MDQifSwiaWF0IjoxNzIzMDM5ODM3fQ.rsYF73m-9sZgwAJRy25J9S4M2xjsh1KzlipSF6QieJQ",
          },
          body: JSON.stringify({ query }),
          signal,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setResults({
        files: data.files || [],
        folders: data.folders || [],
      });
      setIsSearching(false);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching search results:", error);
      }
      setIsSearching(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        resultsRef.current &&
        !resultsRef.current.contains(event.target) &&
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setResults({ files: [], folders: [] }); // Clear results immediately on input change
    setIsSearching(true);
    if (e.target.value === "") {
      setShowResults(false);
      setIsSearching(false);
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div
          className={`searchbar2 ${showResults ? "searchbar2-expanded" : ""}`}
          ref={searchBarRef}
        >
          <input
            className={`search_input1`}
            type="text"
            placeholder="Search..."
            value={query}
            onChange={handleInputChange}
          />
          <a
            href="/"
            className="search_icon"
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-search"></i>
          </a>
        </div>
        {showResults && (
        <ul className="search-results1" ref={resultsRef}>
          {isSearching ? (
            <li>Processing search results...</li>
          ) : results.folders.length === 0 && results.files.length === 0 ? (
            <li>No items match your search</li>
          ) : (
            <>
              {results.folders.map((folder, index) => (
                <li key={`folder-${index}`}>Folder: {folder.name}</li>
              ))}
              {results.files.map((file, index) => (
                <li key={`file-${index}`}>File: {file.filename}</li>
              ))}
            </>
          )}
        </ul>
      )}
      </div>
      
    </div>
  );
};

export default SearchBarMain;
