import React, { useState, useEffect, useRef, useContext } from "react";
import "../App.css";
import { Link, useHistory } from "react-router-dom";
import GreatContext from "../context/GreatContext";
import logo from "../icons/synchronexaLogo.png";
import NewButtonDropdown from "./NewbuttonDropdown";
const Navbar = () => {
  const NavConst = useContext(GreatContext);
  const {
    isFolder,
    setIsFolder,
    handleFileChange,
    handleUpload,
    folderName,
    setFolderName,
    handleCreateFolder,
    currentUser
  } = NavConst;
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const history = useHistory();
  const navbarRef = useRef(null);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const handleLinkClick = () => {
    setIsNavCollapsed(true); // Collapse the menu when a link is clicked
  };

  const handleLogOut = (e) => {
    e.preventDefault();
    handleLinkClick(); // Collapse menu after logout
    if (localStorage.getItem("isRef3Clicked")) {
      localStorage.removeItem("isRef3Clicked");
    }
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
      localStorage.setItem("state", "logout");
      history.push("/");
    }
  };

  const handleClickOutside = (e) => {
    if (navbarRef.current && !navbarRef.current.contains(e.target)) {
      setIsNavCollapsed(true); // Collapse the menu when clicking outside
    }
  };

  useEffect(() => {
    if (!isNavCollapsed) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener on component unmount
    };
  }, [isNavCollapsed]);

  useEffect(() => {
    // Check if the script is already added
    if (!document.querySelector('script[src="https://checkout.razorpay.com/v1/payment-button.js"]')) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.setAttribute("data-payment_button_id", "pl_P1r2jcL2Aku1CI");
      script.async = true;
  
      // Append script inside the form after it mounts
      const formElement = document.getElementById("razorpay-payment-form");
      formElement.appendChild(script);
    }
  }, [currentUser]);

  return (
    <div className="navbar" ref={navbarRef}>
      <div className="logo">
        <Link to="/home">
          <img src={logo} alt="Synchronexa logo" style={{ width: "10rem" }} />
        </Link>
      </div>

      {/* Burger button */}
      <button
        className={`burger ${!isNavCollapsed ? "open" : ""}`}
        type="button"
        onClick={handleNavCollapse}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      {/* Navigation Menu */}
      <ul className={`menu ${!isNavCollapsed ? "open" : ""}`}>
        <li>
          <NewButtonDropdown
            isFolder={isFolder}
            setIsFolder={setIsFolder}
            handleFileChange={handleFileChange}
            handleUpload={handleUpload}
            folderName={folderName}
            setFolderName={setFolderName}
            handleCreateFolder={handleCreateFolder}
            inMobile={true}
          />
        </li>
        <li>
          <Link to="/home/dashboard">My DashBoard</Link>
        </li>
        <li>
          <Link to="/home">My Drive</Link>
        </li>
        <li>
          <Link to="/home/sharedVault">Shared With me</Link>
        </li>
        <li>
          <Link to="/home/chatbox">My Chats</Link>
        </li>
        <li>
          <Link to="/home/teams">Teams</Link>
        </li>
        <li className="nav-item">
          <button className="btn btn-outline-dark" onClick={handleLogOut}>
            Log Out
          </button>
        </li>
        <li className="nav-item">
          <form id="razorpay-payment-form" style={{ color: "black" }}></form>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
