import React, { useState, useEffect, useContext } from "react";
import FileSection from "./FileSection";
import Spinner from "./Spinner"; // Assuming you have a spinner for loading
import GreatContext from "../context/GreatContext";
import SharedFolder from "./SharedFolder";

const AccessShared = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const GodFather = useContext(GreatContext);
  const {
    folders,
    files,
    navigationHistory,
    handleBack,
    fetchFolders,
    fetchFolderContents,
    handleFolderDownload,
    handleDeleteFolder,
    handleRenameClick2,
    folderToRename,
    newFolderName,
    setNewFolderName,
    handleRenameFolder,
    handleDownload,
    handleDeleteFile,
    handleMoveClick,
    handleRenameClick,
    fileToRename,
    newFileName,
    setNewFileName,
    handleRenameFile,
    resetNavigationHistory,
    breadcrumb,
    setBreadcrumb,
    handleViewPdf,
  } = GodFather;

  // Get the token from the URL query parameters
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://secure-storage-system-mern-cloud-file.onrender.com/api/shareRoutes/shared-data/${token}`
        );

        if (!response.ok) {
          console.log("Response status:", response.status);
          throw new Error("Error fetching shared item");
        }

        const data = await response.json();
          setData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  if (loading) {
    return <Spinner />;
  }
  if (error) return <p>Error: {error}</p>;
   // Filter folders to include the root folder and its subfolders
   const filteredFolders = data && data.type === "folder"
   ? folders.filter(folder => folder.path.startsWith(data.data.path))
   : [];
  return (
    <div className="shared-content">
      {data ? (
        data.type === "file" ? (
          <FileSection
            files={[data.data]}
            handleDownload={handleDownload}
            handleDeleteFile={handleDeleteFile}
            handleMoveClick={handleMoveClick}
            handleRenameClick={handleRenameClick}
            fileToRename={fileToRename}
            newFileName={newFileName}
            setNewFileName={setNewFileName}
            handleRenameFile={handleRenameFile}
            handleViewPdf={handleViewPdf}
          />
        ) : (
          <SharedFolder
            folders={filteredFolders} // Pass only the folders array
            files={files}
            handleDownload={handleDownload}
            handleViewPdf={handleViewPdf}
            fetchFolders={fetchFolders}
            fetchFolderContents={fetchFolderContents}
            handleFolderDownload={handleFolderDownload}
            handleDeleteFolder={handleDeleteFolder}
            handleRenameClick2={handleRenameClick2}
            folderToRename={folderToRename}
            newFolderName={newFolderName}
            setNewFolderName={setNewFolderName}
            handleRenameFolder={handleRenameFolder}
            navigationHistory={navigationHistory}
            handleBack={handleBack}
            resetNavigationHistory={resetNavigationHistory}
            breadcrumb={breadcrumb}
            setBreadcrumb={setBreadcrumb}
          />
        )
      ) : (
        <p>Shared item not found or expired.</p>
      )}
    </div>
  );
};

export default AccessShared;
