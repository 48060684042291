import React, { useState, useRef } from "react";
import downloadicon from "../icons/downloadimg.png";
import normalFolder1 from "../icons/normalFolder1.png";
import normalFolder2 from "../icons/normalFolder2.png";
import normalFolder3 from "../icons/normalFolder3.png";
import normalFolder4 from "../icons/normalFolder4.png";
import "../App.css";

const FolderList = ({
  folders,
  fetchFolderContents,
  handleFolderDownload,
  setLoading,
  handleFolderDoubleClick,
  updateBreadcrumb,
}) => {

  const dropdownButtonRef = useRef(null);
  const handleClick = (event) => {
    event.stopPropagation();
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);

  const getFolderIconAndColor = (size) => {
    if (size < 2 * 1024 * 1024) {
      // Below 2MB
      return { icon: normalFolder1, color: "#fff8a6" };
    } else if (size >= 2 * 1024 * 1024 && size < 10 * 1024 * 1024) {
      // 2MB to 10MB
      return { icon: normalFolder2, color: "#9fffd8" };
    } else if (size >= 10 * 1024 * 1024 && size < 50 * 1024 * 1024) {
      // 10MB to 50MB
      return { icon: normalFolder3, color: "#8dd5ff" };
    } else {
      // Greater than 50MB
      return { icon: normalFolder4, color: "#ffc0c0" };
    }
  };

  return (
    <div>
      <div className="container text-center">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
          {folders.map((folder) => {
            const { icon, color } = getFolderIconAndColor(folder.size);

            return (
              <div
                key={folder._id}
                className="col mb-4 d-flex justify-content-center"
              >
                <div
                  className="card"
                  style={{
                    width: "11rem",
                    height: "15rem",
                    backgroundColor:
                      hoveredItemId === folder._id ? color : "transparent",
                    border: "none",
                  }}
                  onMouseEnter={() => setHoveredItemId(folder._id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                  onDoubleClick={() => handleFolderDoubleClick(folder)}
                >
                  <img
                    src={icon} // Get the appropriate icon based on folder size
                    alt="folder-icon"
                    style={{ height: "10rem", objectFit: "cover" }}
                  />
                  <div
                    className="card-body d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor:
                        hoveredItemId === folder._id ? color : "#212529",
                      padding: "0rem",
                      borderRadius: "0.2rem",
                    }}
                  >
                    <div
                      className="dropdown d-flex justify-content-between"
                      onClick={handleClick}
                      style={{ width: "100%" }}
                    >
                      <h5
                        className="card-title"
                        style={{
                          padding: "0.8rem",
                          textAlign: "left",
                          color:
                            hoveredItemId === folder._id ? "black" : "white",
                        }}
                      >
                        {folder.name.length <= 28
                          ? folder.name
                          : folder.name.substring(0, 26) + "..."}
                      </h5>
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ref={dropdownButtonRef}
                        style={{
                          backgroundColor:
                            hoveredItemId === folder._id
                              ? "transparent"
                              : "transparent",
                          width: "2rem",
                          borderRadius: "1rem",
                        }}
                      >
                        <i
                          className="fa-solid fa-ellipsis-vertical"
                          style={{
                            fontSize: "x-large",
                            color:
                              hoveredItemId === folder._id ? "black" : "white",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton2"
                      >
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() =>
                            handleFolderDownload(folder._id, folder.name)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <label className="iconic">
                            <img
                              src={downloadicon}
                              alt="download"
                              style={{ width: "20px", marginRight: "8px" }}
                            />
                            Download
                          </label>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {folder.subfolders && folder.subfolders.length > 0 && (
                  <FolderList
                    folders={folder.subfolders}
                    fetchFolderContents={fetchFolderContents}
                    handleFolderDownload={handleFolderDownload}
                    handleFolderDoubleClick={handleFolderDoubleClick}
                    setLoading={setLoading}
                    updateBreadcrumb={updateBreadcrumb}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FolderList;
