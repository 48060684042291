import React, { useState, useContext, useEffect, useRef } from "react";
import "../App.css";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FolderSection from "./FolderSection";
import FileSection from "./FileSection";
import MoveFileModal from "./MoveFileModal";
import Spinner from "./Spinner";
import ProgressBar from "./progressBar";
import Sidebar from "./Sidebar";
import GreatContext from "../context/GreatContext";
import Navbar from "./Navbar";
import SearchBarMain from "./SearchBarMain";
import emptyDrive from "../icons/emptyDrive.png";
function Main({ showBanner, hideBanners, setItemType }) {
  // let history = useHistory();
  // useEffect(() => {
  //   const timeoutRef = localStorage.getItem("logtime");
  //   const currentTime = new Date().toISOString();

  //   const tenMinutesInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds

  //   // Calculate the difference between current time and timeout reference time
  //   const timeDifference = new Date(currentTime) - new Date(timeoutRef);

  //   // Check if the time difference exceeds 10 minutes
  //   if (timeDifference >= tenMinutesInMilliseconds) {
  //     // Perform your action here, e.g., logout the user
  //     localStorage.removeItem("logtime")
  //     localStorage.removeItem("token");
  //     // Redirect to login page or any other desired action after logout
  //     history.push("/login");
  //   } else {
  //     const currentTime2 = new Date().toISOString();
  //     localStorage.setItem("logtime",currentTime2);
  //   }

  // }, [history]);
  const ImgComponent2 = () => (
    <img
      src={emptyDrive}
      alt=""
      style={{
        marginRight: "0rem",
        width: "60%",
        borderRadius: "5rem 0rem",
      }}
    />
  );
  const MemorizedImg2 = React.memo(ImgComponent2);
  const GrConst = useContext(GreatContext);
  const {
    loading,
    loading2,
    loading4,
    setLoading,
    downloadProgress,
    folders,
    fetchFolders,
    navigationHistory,
    handleBack,
    fetchFolderContents,
    handleFolderDownload,
    handleDeleteFolder,
    handleRenameClick2,
    folderToRename,
    newFolderName,
    setNewFolderName,
    handleRenameFolder,
    files,
    handleDownload,
    handleDeleteFile,
    handleMoveClick,
    handleRenameClick,
    fileToRename,
    setFolderToRename,
    setFileToRename,
    newFileName,
    setNewFileName,
    handleRenameFile,
    showDropdown,
    selectedFolder,
    handleSelectFolder,
    handleMoveFiles,
    resetNavigationHistory,
    breadcrumb,
    setBreadcrumb,
    handleViewPdf,
    uploadProgress,
    loading3,
    zippingProgress,
    isZipping,
    isDownloading,
    downloadType,
    isFetching,
    fetchingProgress,
    handleViewFile,
    fileURL,
    handleViewPdf2,
    handleViewImage,
    ViewerVisible,
    ImageUrl,
    images,
    setImages,
    currentImageIndex,
    setCurrentImageIndex,
    setImageUrl,
    handleDownloadMultipleFiles,
    users,
    fetchUsers,
    setNavigationHistory,
    currentUser,
    fetchCurrentUser,
    uploadType,
    selectedFiles1,
    setSelectedFiles1,
    setSelectedFolders,
    selectedFolders,
    totalFolders,
    fetchUserItemCounts,
    totalFiles,
    setFiles,
    setFolders,
    currentFolder,
    setLoading4,
    deletionSuccess,
  } = GrConst;
  useEffect(() => {
    fetchFolders();
    fetchUsers();
    fetchCurrentUser();
    setBreadcrumb([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    resetNavigationHistory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchUserItemCounts();
    // eslint-disable-next-line
  }, [folders, files]);

  const [borderRadius, setBorderRadius] = useState("8rem 0rem 0rem 8rem");
  const [height, setHeight] = useState("97%");
  const [borderBottom, setborderBottom] = useState("2px solid white");
  const [loadingmoreFiles, setloadingmoreFiles] = useState(false);
  const contentRef = useRef(null);
  const contentEndRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const contentDiv = contentRef.current;

        // Check scroll position and adjust border radius and height
        if (contentDiv.scrollTop > 0) {
          setBorderRadius("3rem 0rem 0rem 0rem");
          setHeight("100%");
          setborderBottom("none");
        } else {
          setBorderRadius("10rem 0rem 0rem 10rem");
          setHeight("97%");
          setborderBottom("2px solid white");
        }
      }
    };

    const contentDiv = contentRef.current;

    if (contentDiv) {
      contentDiv.addEventListener("scroll", handleScroll);

      // Call handleScroll initially to set height and border radius based on initial state
      handleScroll();

      return () => {
        contentDiv.removeEventListener("scroll", handleScroll);
      };
    }
  });
  const [showNav, setShowNav] = useState(false);
  const handleResize = () => {
    const isMobile = window.innerWidth < 1600; // Adjust the width threshold as necessary
    setShowNav(isMobile);
  };

  useEffect(() => {
    // Set initial state based on current width
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        {loading && <Spinner />}
        {showNav ? <Navbar /> : <Sidebar />}

        <div id="wrapper">
          <div className="fileFolderaccess">
            {loading4 && <div className="loader"></div>}
            {loading2 &&
              (downloadType === "folder" || downloadType === "file") && (
                <div className="fixed-progress-bar">
                  <div>
                    {downloadType === "folder" ? (
                      isFetching ? (
                        <h6>Processing...</h6>
                      ) : isZipping ? (
                        <h6>Downloading Folder...</h6>
                      ) : (
                        <h6>Finalizing...</h6>
                      )
                    ) : downloadType === "file" ? (
                      isDownloading ? (
                        <h6>Downloading File...</h6>
                      ) : (
                        <h6>Preparing File...</h6> // Optional: Handle the case if needed
                      )
                    ) : (
                      <h6>Unknown Download Type...</h6> // Optional: Handle unexpected cases
                    )}
                  </div>
                  <ProgressBar
                    progress={
                      downloadType === "folder"
                        ? isFetching
                          ? Math.round(fetchingProgress ?? 0)
                          : isZipping
                          ? Math.round(zippingProgress ?? 0)
                          : Math.round(downloadProgress ?? 0)
                        : Math.round(downloadProgress ?? 0)
                    }
                  />
                </div>
              )}

            {loading3 && (
              <div className="fixed-progress-bar">
                <div>
                  {Math.round(uploadProgress) !== 100 ? (
                    <>
                      <h6>Uploading...</h6>
                      <ProgressBar progress={Math.round(uploadProgress)} />
                    </>
                  ) : uploadType === "file" ? (
                    <>
                      <h6
                        className="display-6"
                        style={{ fontSize: "large", fontWeight: "500" }}
                      >
                        Upload completed
                      </h6>
                      <h6
                        className="display-6"
                        style={{ fontSize: "medium", fontWeight: "400" }}
                      >
                        <br />
                        <br />
                        Processing your file, you're free to surf around...
                      </h6>
                    </>
                  ) : (
                    <>
                      <h6
                        className="display-6"
                        style={{ fontSize: "large", fontWeight: "500" }}
                      >
                        Upload completed
                      </h6>
                      <h6
                        className="display-6"
                        style={{ fontSize: "medium", fontWeight: "400" }}
                      >
                        <br />
                        <br />
                        Processing your folder, you're free to surf around...
                      </h6>
                    </>
                  )}
                </div>
              </div>
            )}

            {totalFolders === 0 && totalFiles === 0 ? (
              loading ? (
                <Spinner />
              ) : (
                <div className="emptyVault">
                  <MemorizedImg2 />
                  <h4
                    className="display-6"
                    style={{
                      color: "white",
                      marginTop: "1.5rem",
                      fontWeight: "300",
                    }}
                  >
                    your drive is empty..
                  </h4>
                </div>
              )
            ) : (
              <div
                className="content"
                ref={contentRef}
                style={{
                  transition: "all 0.3s ease-in-out",
                  height: height,
                  borderRadius: borderRadius,
                  borderBottom: borderBottom,
                }}
              >
                <div className="searcher">
                  <SearchBarMain />
                </div>
                <FolderSection
                  folders={folders}
                  setFiles={setFiles}
                  files={files}
                  currentFolder={currentFolder}
                  setFolders={setFolders}
                  contentEndRef={contentEndRef}
                  currentUser={currentUser}
                  setNavigationHistory={setNavigationHistory}
                  fetchFolders={fetchFolders}
                  setLoading={setLoading}
                  fetchFolderContents={fetchFolderContents}
                  handleFolderDownload={handleFolderDownload}
                  handleDeleteFolder={handleDeleteFolder}
                  handleRenameClick2={handleRenameClick2}
                  folderToRename={folderToRename}
                  deletionSuccess={deletionSuccess}
                  setFolderToRename={setFolderToRename}
                  newFolderName={newFolderName}
                  setNewFolderName={setNewFolderName}
                  showBanner={showBanner}
                  hideBanners={hideBanners}
                  handleRenameFolder={handleRenameFolder}
                  navigationHistory={navigationHistory}
                  handleBack={handleBack}
                  resetNavigationHistory={resetNavigationHistory}
                  contentRef={contentRef}
                  breadcrumb={breadcrumb}
                  setBreadcrumb={setBreadcrumb}
                  selectedFiles={selectedFiles1}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                  users={users}
                  setloadingmoreFiles={setloadingmoreFiles}
                  loading4={loading4}
                  setLoading4={setLoading4}
                  setItemType={setItemType}
                />
                <FileSection
                  users={users}
                  currentUser={currentUser}
                  loading={loading}
                  files={files}
                  handleViewPdf2={handleViewPdf2}
                  handleDownload={handleDownload}
                  handleDeleteFile={handleDeleteFile}
                  handleMoveClick={handleMoveClick}
                  handleRenameClick={handleRenameClick}
                  fileToRename={fileToRename}
                  setFileToRename={setFileToRename}
                  newFileName={newFileName}
                  setNewFileName={setNewFileName}
                  handleRenameFile={handleRenameFile}
                  handleViewPdf={handleViewPdf}
                  handleViewFile={handleViewFile}
                  fileURL={fileURL}
                  deletionSuccess={deletionSuccess}
                  handleViewImage={handleViewImage}
                  ViewerVisible={ViewerVisible}
                  ImageUrl={ImageUrl}
                  selectedFiles={selectedFiles1}
                  setSelectedFiles={setSelectedFiles1}
                  selectedFolders={selectedFolders}
                  images={images}
                  currentImageIndex={currentImageIndex}
                  setCurrentImageIndex={setCurrentImageIndex}
                  showBanner={showBanner}
                  hideBanners={hideBanners}
                  setImageUrl={setImageUrl}
                  setImages={setImages}
                  contentEndRef={contentEndRef}
                  handleDownloadMultipleFiles={handleDownloadMultipleFiles}
                  loadingmoreFiles={loadingmoreFiles}
                  setLoading={setLoading}
                  loading4={loading4}
                  setLoading4={setLoading4}
                  setItemType={setItemType}
                />
              </div>
            )}

            <MoveFileModal
              showDropdown={showDropdown}
              folders={folders}
              selectedFolder={selectedFolder}
              handleSelectFolder={handleSelectFolder}
              handleMoveFiles={handleMoveFiles}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
