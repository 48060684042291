import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import RenameFolderModal from "./renamefolderModal";
import downloadicon from "../icons/downloadimg.png";
import renameicon from "../icons/renameicon.png";
import trashicon from "../icons/trashicon.png";
import normalFolder1 from "../icons/normalFolder1.png";
import normalFolder2 from "../icons/normalFolder2.png";
import normalFolder3 from "../icons/normalFolder3.png";
import normalFolder4 from "../icons/normalFolder4.png";
import "../App.css";
import ShareItemModal from "./Modals/ShareItemModal";

const FolderList = ({
  folders,
  fetchFolderContents,
  handleFolderDownload,
  handleDeleteFolder,
  handleRenameClick2,
  folderToRename,
  newFolderName,
  setNewFolderName,
  handleFolderDoubleClick,
  handleRenameFolder,
  setLoading,
  updateBreadcrumb,
  selectedFolders,
  setSelectedFolders,
  selectedFiles,
  users,
  setFolderToRename,
  currentUser,
  loading4,
  setLoading4,
  showBanner,
  setItemType,
  itemType,
  deletionSuccess,
}) => {
  const [isSelecting, setIsSelecting] = useState(false);
  // eslint-disable-next-line
  const selectionRef = useRef(null);

  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownMenuRefs = useRef({});
  const dropdownButtonRefs = useRef({});

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideDropdown = Object.values(
        dropdownMenuRefs.current
      ).some((ref) => ref?.contains(event.target));
      const isClickOnButton = Object.values(dropdownButtonRefs.current).some(
        (ref) => ref?.contains(event.target)
      );

      if (!isClickInsideDropdown && !isClickOnButton) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (folderId) => {
    if (openDropdownId === folderId) {
      setOpenDropdownId(null); // Close the dropdown
    } else {
      // Close the currently open dropdown
      if (openDropdownId !== null && dropdownMenuRefs.current[openDropdownId]) {
        const currentDropdownMenu = dropdownMenuRefs.current[openDropdownId];
        currentDropdownMenu.classList.remove("show");
        void currentDropdownMenu.offsetWidth; // Trigger reflow
      }
      setOpenDropdownId(folderId); // Open the new dropdown
    }
  };

  const handleItemClick = (callback) => {
    callback();
    setOpenDropdownId(null); // Close the dropdown after clicking an item
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);

  const getFolderIconAndColor = (size) => {
    if (size < 10 * 1024 * 1024) {
      // Below 10MB
      return { icon: normalFolder1, color: "#fff8a6" };
    } else if (size >= 10 * 1024 * 1024 && size < 25 * 1024 * 1024) {
      // 10MB to 25MB
      return { icon: normalFolder2, color: "#9fffd8" };
    } else if (size >= 25 * 1024 * 1024 && size < 50 * 1024 * 1024) {
      // 25MB to 50MB
      return { icon: normalFolder3, color: "#8dd5ff" };
    } else {
      // Greater than 50MB
      return { icon: normalFolder4, color: "#ffc0c0" };
    }
  };
  const infoRef = useRef(null);
  const [selectedDroper, setSelectedDroper] = useState(false);
  const handleMouseDown = (folder) => {
    // Set a timeout to detect a long press
    selectionRef.current = setTimeout(() => {
      setSelectedDroper(true);
      handleDropdownToggle(folder._id); // Trigger dropdown after holding for 500ms
    }, 500);
  };

  const handleDeleteFolderer=async(folder)=>{
    await handleDeleteFolder(folder._id);
    if(deletionSuccess){
      showBanner('.banner.info','folder delete');
    }
  }

  const handleMouseUp = () => {
    // Clear the timeout if the mouse is released before 500ms
    if (selectionRef.current) {
      clearTimeout(selectionRef.current);
    }
    setTimeout(() => {
      selectionRef.current = null;
      setSelectedDroper(false);
    }, 500);
  };

  const handleMouseClick = (folder) => {
    if (!isSelecting) {
      handleFolderDoubleClick(folder);
    } else if (isSelecting) {
      if (selectedFiles.length === 0) {
        setSelectedFolders((prevSelectedFolders) => {
          const updatedSelection = prevSelectedFolders.includes(folder._id)
            ? prevSelectedFolders.filter((id) => id !== folder._id)
            : [...prevSelectedFolders, folder._id];
          return updatedSelection;
        });
      }
    }
  };

  const handleUnselect = () => {
    setSelectedFolders([]);
    setIsSelecting(false);
  };

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      if (selectionRef.current) {
        clearTimeout(selectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (infoRef.current) {
      if (isSelecting) {
        infoRef.current.classList.add("visible");
      } else {
        infoRef.current.classList.remove("visible");
      }
    }
  }, [isSelecting]);

  const [sharedFolder, setSharedFolder] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleShareClick = (folder) => {
    if (folder) {
      setSharedFolder(folder); // Set the currently selected file
      setIsShareModalOpen(true); // Open the share modal
      setItemType('folder share')
    } else {
      console.error("No file selected");
    }
  };

  const handleShareClose = () => {
    setSharedFolder(null); // Clear the selected file when modal closes
    setIsShareModalOpen(false); // Close the share modal
  };

  return (
    <div>
      <RenameFolderModal
        folderToRename={folderToRename}
        newFolderName={newFolderName}
        setNewFolderName={setNewFolderName}
        handleRenameFolder={handleRenameFolder}
        setFolderToRename={setFolderToRename}
      />

      <div
        ref={infoRef}
        className={`selected-folders-info ${isSelecting ? "visible" : ""}`}
      >
        {isSelecting && (
          <>
            <span>
              {selectedFolders.length} folder
              {selectedFolders.length > 1 ? "s" : ""} selected
            </span>
            <button onClick={handleUnselect}>Unselect All</button>
          </>
        )}
      </div>
      <div className="container">
        <div className="selectionRef" ref={selectionRef}>
          {isShareModalOpen && (
            <ShareItemModal
              item={sharedFolder}
              setItem={setSharedFolder}
              onClose={handleShareClose}
              users={users}
              itemType={"folder"}
              isShareModalOpen={isShareModalOpen}
              setIsShareModalOpen={setIsShareModalOpen}
              currentUser={currentUser}
              setLoading4={setLoading4}
              loading4={loading4}
              showBanner={showBanner}
              itemNote={itemType}
            />
          )}
          <div className="row row-cols-3 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
            {folders.map((folder) => {
              const { icon, color } = getFolderIconAndColor(folder.size);
              let isDropdownOpen = openDropdownId === folder._id;
              return (
                <div
                  key={folder._id}
                  className="col mb-4 d-flex justify-content-center"
                >
                  <div
                    key={folder._id}
                    className={`card ${
                      selectedFolders.includes(folder._id)
                        ? "selected-folder"
                        : ""
                    }`}
                    style={{
                      width: "11rem",
                      height: "15rem",
                      backgroundColor:
                        hoveredItemId === folder._id ? color : "transparent",
                      border: "none",
                      transition: "all 0.1s ease-in-out",
                      cursor: "pointer",
                      borderRadius: "0.8rem",
                    }}
                    onMouseEnter={() => setHoveredItemId(folder._id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                    onDoubleClick={() => handleFolderDoubleClick(folder)}
                    onMouseDown={(event) => handleMouseDown(folder, event)}
                    onMouseUp={handleMouseUp}
                    onTouchStart={(event) => handleMouseDown(folder, event)}
                    onTouchEnd={handleMouseUp}
                    onClick={(event) => {
                      // Disable folder click if dropdown was triggered
                      if (!selectedDroper) {
                        handleMouseClick(folder);
                      } else {
                        event.preventDefault();
                      }
                    }}
                  >
                    <img
                      className="folderTypeImg"
                      src={icon} // Get the appropriate icon based on folder size
                      alt="folder-icon"
                      style={{ height: "10rem", objectFit: "cover" }}
                    />
                    <div
                      className={`card-body d-flex justify-content-center align-items-center ${
                        selectedFolders.includes(folder._id)
                          ? "selected-folder"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          hoveredItemId === folder._id ? color : "#212529",
                        padding: "0rem",
                        borderRadius: "0.8rem",
                        transition: "all 0.3s ease-in-out",
                      }}
                    >
                      <div
                        className="dropdown d-flex justify-content-between"
                        style={{ width: "100%" }}
                      >
                        <h5
                          className={`card-title ${
                            selectedFolders.includes(folder._id)
                              ? "selected-folder-title"
                              : ""
                          }`}
                          style={{
                            padding: "0.8rem",
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            margin: "0",
                            color:
                              hoveredItemId === folder._id ? "black" : "white",
                          }}
                        >
                          {folder.name.includes(" ")
                            ? folder.name.length > 28
                              ? folder.name.substring(0, 25) + "..."
                              : folder.name
                            : folder.name.length > 13
                            ? folder.name.substring(0, 11) + "..."
                            : folder.name}
                        </h5>
                        <button
                          type="button"
                          className="dropdownMenuButton2 btn btn-secondary dropdown-toggle"
                          id={`dropdownMenuButton${folder._id}`}
                          data-bs-toggle="dropdown"
                          aria-expanded={isDropdownOpen}
                          style={{
                            backgroundColor:
                              hoveredItemId === folder._id
                                ? "transparent"
                                : "transparent",
                            width: "2rem",
                            borderRadius: "1rem",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDropdownToggle(folder._id);
                          }}
                          ref={(el) =>
                            (dropdownButtonRefs.current[folder._id] = el)
                          }
                        >
                          <i
                            className={`fa-solid fa-ellipsis-vertical ${
                              selectedFolders.includes(folder._id)
                                ? "selected-folder-btn"
                                : ""
                            }`}
                            style={{
                              fontSize: "x-large",
                              color:
                                hoveredItemId === folder._id
                                  ? "black"
                                  : "white",
                            }}
                          />
                        </button>
                        <ul
                          className={`dropdown-menu ${
                            isDropdownOpen ? "show" : ""
                          }`}
                          aria-labelledby={`dropdownMenuButton${folder._id}`}
                          ref={(el) =>
                            (dropdownMenuRefs.current[folder._id] = el)
                          }
                        >
                          <li
                            className="dropdown-item"
                            onClick={(event) => {
                              event.stopPropagation();

                              handleItemClick(() =>
                                handleRenameClick2({
                                  _id: folder._id,
                                  name: folder.name,
                                })
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={renameicon}
                                alt="rename"
                                style={{ width: "20px", marginRight: "8px" }}
                              />
                              Rename
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) => {
                              event.stopPropagation();

                              handleItemClick(() =>
                                handleFolderDownload(folder._id, folder.name)
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={downloadicon}
                                alt="download"
                                style={{ width: "20px", marginRight: "8px" }}
                              />
                              Download
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) => {
                              event.stopPropagation();

                              handleItemClick(() =>
                                handleDeleteFolderer(folder)
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={trashicon}
                                alt="delete"
                                style={{ width: "20px", marginRight: "8px" }}
                              />
                              Delete
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleShareClick(folder);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <i className="fa-solid fa-share"></i>
                              Share
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {folder.subfolders && folder.subfolders.length > 0 && (
                    <FolderList
                      folders={folder.subfolders}
                      fetchFolderContents={fetchFolderContents}
                      handleFolderDownload={handleFolderDownload}
                      handleDeleteFolder={handleDeleteFolder}
                      handleRenameClick2={handleRenameClick2}
                      folderToRename={folderToRename}
                      newFolderName={newFolderName}
                      setNewFolderName={setNewFolderName}
                      handleRenameFolder={handleRenameFolder}
                      setLoading={setLoading}
                      updateBreadcrumb={updateBreadcrumb}
                      selectedFolders={selectedFolders}
                      setSelectedFolders={setSelectedFolders}
                      selectedFiles={selectedFiles}
                      handleFolderDoubleClick={handleFolderDoubleClick}
                      setFolderToRename={setFolderToRename}
                      users={users}
                      currentUser={currentUser}
                      loading4={loading4}
                      setLoading4={setLoading4}
                      showBanner={showBanner}
                      setItemType={setItemType}
                      itemType={itemType}
                      deletionSuccess={deletionSuccess}
                    
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function FolderSection({
  folders,
  setLoading,
  fetchFolders,
  fetchFolderContents,
  handleFolderDownload,
  handleDeleteFolder,
  handleRenameClick2,
  folderToRename,
  setFolderToRename,
  newFolderName,
  setNewFolderName,
  handleRenameFolder,
  navigationHistory,
  handleBack,
  resetNavigationHistory,
  breadcrumb,
  setBreadcrumb,
  selectedFolders,
  setSelectedFolders,
  selectedFiles,
  setNavigationHistory,
  users,
  currentUser,
  contentEndRef,
  setFiles,
  files,
  currentFolder,
  setloadingmoreFiles,
  loading4,
  setLoading4,
  showBanner,
  setItemType,
  itemType,
  deletionSuccess
}) {
  const updateBreadcrumb = (folderName, folderId) => {
    setBreadcrumb((prevBreadcrumb) => {
      // Check if the folder already exists in the breadcrumb to avoid duplicates
      const existingIndex = prevBreadcrumb.findIndex(
        (item) => item.link === `/folders/${folderId}`
      );
      if (existingIndex === -1) {
        return [
          ...prevBreadcrumb,
          { name: folderName, link: `/folders/${folderId}` },
        ];
      }
      return prevBreadcrumb;
    });
  };

  const handleHomeClick = async () => {
    setLoading(true);
    setPage(0);
    setLoadingMore(false);
    setHasMore(true);
    setBreadcrumb([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    resetNavigationHistory();
    await fetchFolders();
    setLoading(false);
  };

  const [page, setPage] = useState(0); // Track pagination (starting at 0)
  const [loadingMore, setLoadingMore] = useState(false); // Track loading state for infinite scroll
  const [hasMore, setHasMore] = useState(true); // Check if more files are available

  const loadMoreFiles = async (folderId) => {
    if (loadingMore || !hasMore) return; // Prevent multiple fetches
    setloadingmoreFiles(true);
    setLoadingMore(true);

    try {
      const limit = 30; // Number of files to fetch per scroll
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/folder/${folderId}?limit=${limit}&skip=${
          page * limit
        }`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newFiles = data.files;

        // Track existing file IDs to avoid duplicates
        const existingFileIds = new Set(files.map((file) => file._id));

        // Filter out files that are already in the existing files
        const filteredNewFiles = newFiles.filter(
          (file) => !existingFileIds.has(file._id)
        );

        // Append filtered new files to the existing ones
        setFiles((prevFiles) => [...prevFiles, ...filteredNewFiles]);

        // If fewer files were returned than the limit, stop further loading
        if (newFiles.length < limit) {
          setHasMore(false);
        }
      } else {
        console.error("Fetching additional files failed");
      }
    } catch (error) {
      console.error("Error loading more files:", error);
    } finally {
      setloadingmoreFiles(false);
      setLoadingMore(false);
      setPage((prevPage) => prevPage + 1); // Move to the next page for pagination
    }
  };

  useEffect(() => {
    const contentEndElement = contentEndRef.current;

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore && !loadingMore && currentFolder) {
          loadMoreFiles(currentFolder);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    if (contentEndElement) {
      observer.observe(contentEndElement);
    }

    return () => {
      if (contentEndElement) {
        observer.unobserve(contentEndElement);
      }
    };
    // eslint-disable-next-line
  }, [currentFolder, hasMore, loadingMore]);

  const handleFolderDoubleClick = async (folder) => {
    setLoading(true);
    updateBreadcrumb(folder.name, folder._id);
    setNavigationHistory((prevHistory) => {
      const newHistory = [...prevHistory, folder.parentId];
      return newHistory;
    });

    setPage(0); // Reset to the first page
    setHasMore(true); // Reset to allow fetching more files
    setFiles([]); // Clear current files
    await fetchFolderContents(folder._id); // Fetch new folder contents
    setLoading(false);
  };

  const handleBreadcrumbClick = async (index) => {
    setLoading(true);
    setPage(0);
    setLoadingMore(false);
    setHasMore(true);
    const folderId = breadcrumb[index].link.split("/").pop();

    setBreadcrumb((prevBreadcrumb) => prevBreadcrumb.slice(0, index + 1));

    setNavigationHistory((prevHistory) => {
      const folderIndex = prevHistory.indexOf(folderId);

      if (folderIndex !== -1) {
        const newHistory = prevHistory.slice(0, folderIndex);
        return newHistory;
      }
      return prevHistory;
    });

    await fetchFolderContents(folderId);

    setLoading(false);
  };

  const backHandeler = () => {
    setPage(0);
    setLoadingMore(false);
    setHasMore(true);
    handleBack();
  };

  return (
    <div id="folderSecDiv">
      <>
        {navigationHistory.length > 0 && (
          <>
            <nav
              aria-label="breadcrumb"
              style={{ fontSize: "larger", fontWeight: "600" }}
            >
              <ol className="breadcrumb">
                {breadcrumb.map((item, index) => (
                  <li
                    key={index}
                    className={`breadcrumb-item ${
                      index === breadcrumb.length - 1 ? "active" : ""
                    }`}
                    aria-current={
                      index === breadcrumb.length - 1 ? "page" : undefined
                    }
                    style={{ color: "#ccc0fe" }}
                  >
                    {index === 0 ? ( // Check if it's the "Home" breadcrumb
                      <Link
                        to={item.link}
                        onClick={(e) => {
                          e.preventDefault();
                          handleHomeClick(); // Call the handleHomeClick function
                        }}
                      >
                        {item.name}
                      </Link>
                    ) : index === breadcrumb.length - 1 ? (
                      item.name
                    ) : (
                      <Link
                        to={item.link}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default anchor link behavior
                          handleBreadcrumbClick(index);
                          const folderId = item.link.split("/").pop();
                          fetchFolderContents(folderId);
                        }}
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
            <button
              onClick={() => backHandeler()}
              className="btn btn-secondary mb-3"
            >
              Back
            </button>
          </>
        )}
        {folders.length > 0 && (
          <>
            <h3 style={{ color: "#ccc0fe" }}>Folders</h3>
            <FolderList
              folders={folders}
              fetchFolderContents={fetchFolderContents}
              handleFolderDownload={handleFolderDownload}
              handleDeleteFolder={handleDeleteFolder}
              handleRenameClick2={handleRenameClick2}
              folderToRename={folderToRename}
              newFolderName={newFolderName}
              setNewFolderName={setNewFolderName}
              handleRenameFolder={handleRenameFolder}
              setLoading={setLoading}
              handleFolderDoubleClick={handleFolderDoubleClick}
              updateBreadcrumb={updateBreadcrumb}
              selectedFolders={selectedFolders}
              setSelectedFolders={setSelectedFolders}
              selectedFiles={selectedFiles}
              setFolderToRename={setFolderToRename}
              users={users}
              currentUser={currentUser}
              loading4={loading4}
              setLoading4={setLoading4}
              showBanner={showBanner}
              setItemType={setItemType}
              itemType={itemType}
              deletionSuccess={deletionSuccess}
            />
          </>
        )}
      </>
    </div>
  );
}

export default FolderSection;
