import React from "react";
import "../App.css";

const RenameFileModal = ({ fileToRename, newFileName, setNewFileName, handleRenameFile,setFileToRename }) => {
  const modalRef = React.useRef(null);
  const bootstrapModal = React.useRef(null);

  // Handle showing the modal
  const showModal = () => {
    if (modalRef.current) {
      bootstrapModal.current = new window.bootstrap.Modal(modalRef.current);
      bootstrapModal.current.show();
      modalRef.current.addEventListener("hide.bs.modal", handleModalHide);
    }
  };

  // Handle hiding the modal
  const hideModal = () => {
    if (bootstrapModal.current) {
      setFileToRename(null);
      bootstrapModal.current.hide();
    }
  };

  const handleModalHide = () => {
    setFileToRename(null); // Reset the file to rename
    // Remove the event listener to avoid memory leaks
    if (modalRef.current) {
      modalRef.current.removeEventListener("hide.bs.modal", handleModalHide);
    }
  };

  React.useEffect(() => {
    if (fileToRename) {
      showModal();
    }
    // eslint-disable-next-line
  }, [fileToRename]);

  return (
    <div
      className="modal fade"
      id="renameFileModal12"
      tabIndex="-1"
      aria-labelledby="renameModalLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content" id="renamemodal2">
          <div className="modal-header">
            <h5 className="modal-title" id="renameModalLabel">Rename File</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            ></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
              placeholder="Enter new file name"
              className="form-control"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={hideModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleRenameFile();
                hideModal();
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameFileModal;
