import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Suspense,
  lazy,
} from "react";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import renameicon from "../icons/renameicon2.png";
import moveicon from "../icons/movefileicon2.png";
import RenameFileModal from "./renamefileModal";
import zoominIcon from "../icons/zoom-in.png";
import zoomoutIcon from "../icons/zoom-out.png";
import "../App.css";

// Enhance performance by dynamically loading the Viewer component
const Viewer = lazy(() =>
  import("@react-pdf-viewer/core").then((module) => ({
    default: module.Viewer,
  }))
);

const PdfViewer = ({
  fileUrl,
  onClose,
  handleDownload,
  handleDeleteFile,
  handleMoveClick,
  handleRenameClick,
  fileToRename,
  newFileName,
  setNewFileName,
  handleRenameFile,
  file,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const toolbarPluginInstance = toolbarPlugin();
  const viewerRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.style.transform = `scale(${zoomLevel})`;
      viewerRef.current.style.transformOrigin = "center top"; // Center the zoom
    }
  }, [zoomLevel]);

  const handleOverlayClick = useCallback(
    (e) => {
      if (
        viewerRef.current &&
        !e.target.closest(".rpv-core__page-layer--single")
      ) {
        onClose();
      }
    },
    [onClose]
  );
  const mobilePdfDropper = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleZoomIn = useCallback(() => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 2));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  }, []);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState); // Toggle dropdown state
  };

  return (
    <div className="pdf-viewer-overlay" onClick={handleOverlayClick}>
      <div className="pdf-viewer-content">
        <div className="pdf-viewer-inner" ref={viewerRef}>
          <Worker
            workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}
          >
            <Suspense fallback={<div>Loading PDF...</div>}>
              <Viewer
                fileUrl={fileUrl}
                plugins={[toolbarPluginInstance, pageNavigationPluginInstance]}
              />
            </Suspense>
          </Worker>
        </div>
      </div>

      {fileToRename && (
        <RenameFileModal
          fileToRename={fileToRename}
          newFileName={newFileName}
          setNewFileName={setNewFileName}
          handleRenameFile={handleRenameFile}
        />
      )}

      <div className="pdf-viewer-header" onClick={(e) => e.stopPropagation()}>
        <div className="initialer">
          <button
            className="close-button"
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          <span
            className="file-name"
            style={{ padding: "0rem 1rem 0rem 1rem" }}
          >
            {file.filename}
          </span>
        </div>
        <div className="pdf-mobile-options">
          {/* Button that opens the dropdown */}
          <button
            type="button"
            className="dropdownMenuButton2 btn btn-secondary dropdown-toggle dropend"
            id="mobilePdfoptions"
            data-bs-toggle="dropdown"
            aria-expanded={isDropdownOpen}
            style={{
              backgroundColor: "transparent",
              width: "2rem",
              borderRadius: "1rem",
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleDropdownToggle(); // Toggle dropdown when clicked
            }}
            ref={mobilePdfDropper}
          >
            <i
              className="fa-solid fa-ellipsis-vertical selected-folder-btn"
              style={{
                fontSize: "x-large",
                color: "black",
              }}
            />
          </button>

          {/* Dropdown menu */}
          <ul
            className={`dropdown-menu mof ${isDropdownOpen ? "show" : ""}`}
            aria-labelledby="mobilePdfoptions"
          >
            {/* Rename option */}
            <li className="dropdown-item" style={{ cursor: "pointer" }}>
              <button
                onClick={() => {
                  setIsDropdownOpen(false);
                  handleRenameClick({ _id: file._id, filename: file.filename });
                }}
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img src={renameicon} alt="Rename" style={{ width: "2rem" }} />{" "}
                <h4
                  className="display-6"
                  style={{ fontSize: "medium", color: "white" }}
                >
                  Rename
                </h4>
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            {/* Download option */}
            <li className="dropdown-item" style={{ cursor: "pointer" }}>
              <button
                onClick={() => {setIsDropdownOpen(false);handleDownload(file._id, file.filename)}}
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa fa-download"
                  aria-hidden="true"
                  style={{ color: "white", fontSize: "1.5rem" }}
                ></i>
                <h4
                  className="display-6"
                  style={{ fontSize: "medium", color: "white" }}
                >
                  Download
                </h4>
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            {/* Delete option */}
            <li className="dropdown-item" style={{ cursor: "pointer" }}>
              <button
                onClick={() => {setIsDropdownOpen(false);handleDeleteFile(file._id)}}
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  style={{ color: "white", fontSize: "1.5rem" }}
                ></i>
                <h4
                  className="display-6"
                  style={{ fontSize: "medium", color: "white" }}
                >
                  Delete
                </h4>
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            {/* Move option */}
            <li className="dropdown-item" style={{ cursor: "pointer" }}>
              <button
                onClick={() => {setIsDropdownOpen(false);handleMoveClick(file)}}
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img src={moveicon} alt="Move" style={{ width: "2rem" }} />
                <h4
                  className="display-6"
                  style={{ fontSize: "medium", color: "white" }}
                >
                  Move
                </h4>
              </button>
            </li>
          </ul>
        </div>
        <div className="pdf-viewer-options">
          <button
            onClick={() =>
              handleRenameClick({ _id: file._id, filename: file.filename })
            }
            style={{ cursor: "pointer" }}
          >
            <img src={renameicon} alt="Rename" style={{ width: "2rem" }} />
          </button>
          <button onClick={() => handleDownload(file._id, file.filename)}>
            <i
              className="fa fa-download"
              aria-hidden="true"
              style={{ color: "white" }}
            ></i>
          </button>
          <button onClick={() => handleDeleteFile(file._id)}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ color: "white" }}
            ></i>
          </button>
          <button onClick={() => handleMoveClick(file)}>
            <img src={moveicon} alt="Move" style={{ width: "2rem" }} />
          </button>
        </div>
      </div>

      <div className="pdf-viewer-footer" onClick={(e) => e.stopPropagation()}>
        <div className="PageToolbar">
          <button
            onClick={handleZoomOut}
            style={{ border: "none", background: "none", padding: 0 }}
          >
            <img
              src={zoomoutIcon}
              alt="Zoom Out"
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            />
          </button>
          <span className="page-info">
            <CurrentPageLabel>
              {({ currentPage, numberOfPages }) => (
                <span>
                  Page {currentPage + 1} of {numberOfPages || "Loading..."}
                </span>
              )}
            </CurrentPageLabel>
          </span>
          <button
            onClick={handleZoomIn}
            style={{ border: "none", background: "none", padding: 0 }}
          >
            <img
              src={zoominIcon}
              alt="Zoom In"
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
