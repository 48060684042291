import React, { useEffect, useState, useRef } from "react";
import "../App.css";
import RenameFileModal from "./renamefileModal";
import downloadicon from "../icons/downloadimg.png";
import renameicon from "../icons/renameicon.png";
import moveicon from "../icons/movefileicon.png";
import trashicon from "../icons/trashicon.png";
import pdfIcon from "../icons/pdfFile.png";
import txtIcon from "../icons/txtFile.png";
import mp3Icon from "../icons/mp3icon.png";
import mp4Icon from "../icons/mp4icon.png";
import zipIcon from "../icons/zipicon.png";
import docXIcon from "../icons/docxicon.png";
import imgIcon from "../icons/imgIcon.png";
import PptIcon from "../icons/pptIcon.png";
import XlsxIcon from "../icons/xlsxIcon.png";
import UnknownIcon from "../icons/unknownFile.png";
import PdfViewer from "./PdfViewer";
import ImageViewer from "./ImageViewer";
import ShareItemModal from "./Modals/ShareItemModal";
import MediaPlayerModal from "./Modals/Multimedia.js";

function FileSection({
  files,
  handleDownload,
  handleDeleteFile,
  handleMoveClick,
  handleRenameClick,
  fileToRename,
  newFileName,
  setNewFileName,
  handleRenameFile,
  handleViewPdf,
  handleViewFile,
  fileURL,
  handleViewPdf2,
  handleViewImage,
  ViewerVisible,
  ImageUrl,
  selectedFiles,
  setSelectedFiles,
  selectedFolders,
  images,
  currentImageIndex,
  setCurrentImageIndex,
  setImages,
  setImageUrl,
  handleDownloadMultipleFiles,
  users,
  currentUser,
  setFileToRename,
  loadingmoreFiles,
  contentEndRef,
  loading4,
  setLoading4,
  showBanner,
  setItemType,
  deletionSuccess,
  itemType
}) {
  const [isSelecting, setIsSelecting] = useState(false);
  // eslint-disable-next-line
  const selectionRef = useRef(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [isPdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [isImgViewerOpen, setImgViewerOpen] = useState(false);
  const [pdfToView, setpdfToView] = useState(null);
  const [ImgToView, setImgToView] = useState(null);
  const [sharedFile, setSharedFile] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [mediaType, setMediaType] = useState(null);
  const [mediaSrc, setMediaSrc] = useState("");

  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownMenuRefs = useRef({});
  const dropdownButtonRefs = useRef({});

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideDropdown = Object.values(
        dropdownMenuRefs.current
      ).some((ref) => ref?.contains(event.target));
      const isClickOnButton = Object.values(dropdownButtonRefs.current).some(
        (ref) => ref?.contains(event.target)
      );

      if (!isClickInsideDropdown && !isClickOnButton) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (folderId) => {
    if (openDropdownId === folderId) {
      setOpenDropdownId(null); // Close the dropdown
    } else {
      // Close the currently open dropdown
      if (openDropdownId !== null && dropdownMenuRefs.current[openDropdownId]) {
        const currentDropdownMenu = dropdownMenuRefs.current[openDropdownId];
        currentDropdownMenu.classList.remove("show");
        void currentDropdownMenu.offsetWidth; // Trigger reflow
      }
      setOpenDropdownId(folderId); // Open the new dropdown
    }
  };

  const handleItemClick = (callback) => {
    callback();
    setOpenDropdownId(null); // Close the dropdown after clicking an item
  };

  const fileIconMap = {
    pdf: pdfIcon,
    txt: txtIcon,
    mp3: mp3Icon,
    mp4: mp4Icon,
    avi: mp4Icon,
    mov: mp4Icon,
    wmv: mp4Icon,
    mkv: mp4Icon,
    flv: mp4Icon,
    mpeg: mp4Icon,
    mpg: mp4Icon,
    "3gp": mp4Icon,
    webm: mp4Icon,
    ogv: mp4Icon,
    ogg: mp4Icon,
    m4v: mp4Icon,
    ts: mp4Icon,
    f4v: mp4Icon,
    m2ts: mp4Icon,
    vob: mp4Icon,
    zip: zipIcon,
    docx: docXIcon,
    doc: docXIcon,
    ppt: PptIcon,
    pptx: PptIcon,
    potx: PptIcon,
    pps: PptIcon,
    ppsx: PptIcon,
    xlsx: XlsxIcon,
    xls: XlsxIcon,
    csv: XlsxIcon,
    jpg: imgIcon,
    jpeg: imgIcon,
    png: imgIcon,
    gif: imgIcon,
    bmp: imgIcon,
    svg: imgIcon,
  };

  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    return fileIconMap[extension] || UnknownIcon;
  };

  const handleDoubleClick = (file, folderId) => {
    const extension = file.filename.split(".").pop().toLowerCase();
    if (extension === "pdf") {
      handleViewPdf2(file._id);
    } else if (["docx", "doc", "pptx", "xlsx"].includes(extension)) {
      handleViewFile(file._id); // Open .docx, .pptx, or .xlsx files
    } else if (
      ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension)
    ) {
      handleViewImage(file._id, folderId);
      console.log(folderId);
      console.log(file._id)
      setImgToView(file);
      setImgViewerOpen(true);
    } else if (
      ["mp3", "wav", "ogg", "m4a", "aac", "flac"].includes(extension)
    ) {
      setMediaType("audio");
      setMediaSrc(`https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/stream/${file._id}`);
      setIsMediaModalOpen(true);
    } else if (
      ["mp4", "mov", "avi", "wmv", "mkv", "webm", "flv", "m4v"].includes(
        extension
      )
    ) {
      setMediaType("video");
      setMediaSrc(`https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/stream/${file._id}`);
      setIsMediaModalOpen(true);
    }
  };

  const closePdfViewer = () => {
    setPdfViewerOpen(false);
  };

  const closeImgViewer = () => {
    setImgViewerOpen(false);
  };

  const infoRef = useRef(null);
  const handleMouseDown = (file) => {
    if (selectedFolders.length === 0) {
      selectionRef.current = setTimeout(() => {
        setIsSelecting(true);
        if (isSelecting) {
          setSelectedFiles((prevSelectedFiles) => {
            const fileIndex = prevSelectedFiles.findIndex(
              (f) => f._id === file._id
            );
            if (fileIndex > -1) {
              // If file is already selected, remove it
              const updatedSelection = prevSelectedFiles.filter(
                (f) => f._id !== file._id
              );
              return updatedSelection;
            } else {
              // If file is not selected, add it
              return [...prevSelectedFiles, file];
            }
          });
        }
      }, 500);
    } else {
    }
  };

  const handleMouseUp = () => {
    if (selectionRef.current) {
      clearTimeout(selectionRef.current);
      selectionRef.current = null;
    }
  };

  const handleMouseClick = (file, parentFolderId) => {
    if (!isSelecting) {
      setPdfViewerOpen(false);
      handleDoubleClick(file, parentFolderId);
    } else if (isSelecting) {
      if (selectedFolders.length === 0) {
        setSelectedFiles((prevSelectedFiles) => {
          const fileIndex = prevSelectedFiles.findIndex(
            (f) => f._id === file._id
          );
          if (fileIndex > -1) {
            // If file is already selected, remove it
            const updatedSelection = prevSelectedFiles.filter(
              (f) => f._id !== file._id
            );
            return updatedSelection;
          } else {
            // If file is not selected, add it
            return [...prevSelectedFiles, file];
          }
        });
      }
    }
  };

  const handleUnselect = () => {
    setSelectedFiles([]);
    setIsSelecting(false);
  };

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      if (selectionRef.current) {
        clearTimeout(selectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (infoRef.current) {
      if (isSelecting) {
        infoRef.current.classList.add("visible");
      } else {
        infoRef.current.classList.remove("visible");
      }
    }
  }, [isSelecting]);

  useEffect(() => {
    // Reset isSelecting to false if no files are selected
    if (selectedFiles.length === 0) {
      setIsSelecting(false);
    }
  }, [selectedFiles]);

  const handleDeleteSelectedFiles = () => {
    if (selectedFiles.length > 0) {
      // Extract the file IDs from the selectedFiles array
      const fileIds = selectedFiles.map((file) => file._id);
      handleDeleteFile(fileIds);
      setSelectedFiles([]);
    }
  };

  const handleDeleteFiler=async(file)=>{
    await handleDeleteFile([file._id]);
    if(deletionSuccess){
      showBanner('.banner.info','file delete');
    }
  }

  const handleDownloadSelectedFiles = () => {
    if (selectedFiles.length > 0) {
      const fileIds = selectedFiles.map((file) => file._id);
      if (selectedFiles.length === 1) {
        handleDownload(fileIds[0], selectedFiles[0].filename); // Pass file ID and filename
      } else {
        // Multiple files, no filename needed
        handleDownloadMultipleFiles(selectedFiles.map((file) => file._id)); // Pass only file IDs
      }
      setSelectedFiles([]);
    }
  };

  const handleShareClick = (file) => {
    if (file) {
      setSharedFile(file); // Set the currently selected file
      setIsShareModalOpen(true); // Open the share modal
      setItemType('file share');
    } else {
      console.error("No file selected");
    }
  };

  const handleShareClose = () => {
    setSharedFile(null); // Clear the selected file when modal closes
    setIsShareModalOpen(false); // Close the share modal
  };
  const handleMouseEnter = (fileId) => () => {
    if (hoveredItemId !== fileId) {
      setHoveredItemId(fileId);
    }
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  return (
    <div>
      <RenameFileModal
        fileToRename={fileToRename}
        newFileName={newFileName}
        setNewFileName={setNewFileName}
        handleRenameFile={handleRenameFile}
        setFileToRename={setFileToRename}
      />
      <MediaPlayerModal
        mediaType={mediaType}
        mediaSrc={mediaSrc}
        isOpen={isMediaModalOpen}
        setIsOpen={setIsMediaModalOpen}
      />
      <div ref={infoRef} className="selected-files-info">
        {isSelecting && (
          <>
            <span>
              {selectedFiles.length} file{selectedFiles.length > 1 ? "s" : ""}{" "}
              selected
            </span>
            <button onClick={handleUnselect}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            <button onClick={handleDeleteSelectedFiles}>
              <i
                className="fa-solid fa-trash fa-shake"
                style={{ color: "#ffffff" }}
              ></i>
            </button>
            <button onClick={handleDownloadSelectedFiles}>
              <i
                className="fa-solid fa-download fa-bounce"
                style={{ color: "#ffffff" }}
              ></i>
            </button>
          </>
        )}
      </div>
      {files.length > 0 && (
        <>
          <h3 style={{ color: "#ccc0fe" }}>Files</h3>
          <div className="selectionRef" ref={selectionRef}>
            {isShareModalOpen && (
              <ShareItemModal
                item={sharedFile}
                setItem={setSharedFile}
                onClose={handleShareClose}
                users={users}
                itemType={"file"}
                isShareModalOpen={isShareModalOpen}
                setIsShareModalOpen={setIsShareModalOpen}
                currentUser={currentUser}
                loading4={loading4}
                setLoading4={setLoading4}
                showBanner={showBanner}
                itemNote={"file share"}
              />
            )}
            <ul className="fileSection">
              {files.map((file) => {
                let isDropdownOpen = openDropdownId === file._id;
                return (
                  <li
                    key={file._id}
                    className={`fileSectionlistitem ${
                      selectedFiles.some(
                        (selectedFile) => selectedFile._id === file._id
                      )
                        ? "selected"
                        : ""
                    }`}
                    onMouseEnter={handleMouseEnter(file._id)}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={(event) => handleMouseDown(file, event)}
                    onMouseUp={handleMouseUp}
                    onTouchStart={(event) => handleMouseDown(file, event)}
                    onTouchEnd={handleMouseUp}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="fileitem">
                      <div
                        className="fileitemname d-flex align-items-center"
                        onDoubleClick={() =>
                          handleDoubleClick(file, file.parentFolderId)
                        }
                        onClick={() =>
                          handleMouseClick(file, file.parentFolderId)
                        }
                      >
                        <span className="filefoldername">
                          <img
                            src={getFileIcon(file.filename)}
                            alt="icon"
                            style={{ width: "2rem", marginRight: "0.5rem" }}
                          />
                          {file.filename.length <= 60
                            ? file.filename
                            : file.filename.substring(0, 60) + "..."}
                        </span>
                      </div>

                      <div className="dropdown dropup d-flex">
                        {file.filename.split(".").pop().toLowerCase() ===
                          "pdf" && (
                          <button
                            id="pdfEye"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleViewPdf(file._id);
                              setpdfToView(file);
                              setPdfViewerOpen(true);
                            }}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              width: "4rem",
                            }}
                          >
                            <i
                              className={`fa-solid fa-eye ${
                                selectedFiles.some(
                                  (selectedFile) =>
                                    selectedFile._id === file._id
                                )
                                  ? "selected-file-eye"
                                  : ""
                              }`}
                              id="jk"
                              style={{
                                color:
                                  hoveredItemId === file._id
                                    ? "white"
                                    : "white",
                              }}
                            ></i>
                          </button>
                        )}
                        <button
                          type="button"
                          className="dropdownMenuButton2 btn btn-secondary dropdown-toggle"
                          id={`dropdownMenuButton${file._id}`}
                          data-bs-toggle="dropdown"
                          aria-expanded={isDropdownOpen}
                          style={{
                            backgroundColor:
                              hoveredItemId === file._id
                                ? "transparent"
                                : "transparent",
                            width: "2rem",
                            borderRadius: "1rem",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDropdownToggle(file._id);
                          }}
                          ref={(el) =>
                            (dropdownButtonRefs.current[file._id] = el)
                          }
                        >
                          <i
                            className={`fa-solid fa-ellipsis-vertical ${
                              selectedFiles.some(
                                (selectedFile) => selectedFile._id === file._id
                              )
                                ? "selected-file-btn"
                                : ""
                            }`}
                            style={{
                              fontSize: "x-large",
                              color:
                                hoveredItemId === file._id ? "white" : "white",
                            }}
                          ></i>
                        </button>

                        <ul
                          className={`dropdown-menu filer ${
                            isDropdownOpen ? "show" : ""
                          }`}
                          aria-labelledby={`dropdownMenuButton${file._id}`}
                          ref={(el) =>
                            (dropdownMenuRefs.current[file._id] = el)
                          }
                        >
                          <li
                            className="dropdown-item"
                            onClick={(event) =>
                              handleItemClick(() => {
                                event.stopPropagation();
                                handleRenameClick({
                                  _id: file._id,
                                  filename: file.filename,
                                });
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={renameicon}
                                alt="icn"
                                style={{
                                  width: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              Rename
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) =>
                              handleItemClick(() => {
                                event.stopPropagation();
                                handleDownload(file._id, file.filename);
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={downloadicon}
                                alt="icn"
                                style={{
                                  width: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              Download
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) =>
                              handleItemClick(() => {
                                event.stopPropagation();
                                handleDeleteFiler(file);
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={trashicon}
                                alt="icn"
                                style={{
                                  width: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              Delete
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) =>
                              handleItemClick(() => {
                                event.stopPropagation();
                                handleMoveClick(file);
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <img
                                src={moveicon}
                                alt="icn"
                                style={{
                                  width: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              Move
                            </label>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleShareClick(file);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <label className="iconic">
                              <i className="fa-solid fa-share"></i>
                              Share
                            </label>
                          </li>
                        </ul>
                        <div ref={contentEndRef} />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            {loadingmoreFiles && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  className="display-6"
                  style={{
                    marginRight: "2rem",
                    fontSize: "1.5rem",
                    color: "white",
                  }}
                >
                  Loading
                </h1>
                <span className="loadingMorefiles"></span>
              </div>
            )}
          </div>
          {isPdfViewerOpen && fileURL && (
            <PdfViewer
              fileUrl={fileURL}
              onClose={closePdfViewer}
              file={pdfToView}
              newFileName={newFileName}
              setNewFileName={setNewFileName}
              handleRenameClick={handleRenameClick}
              handleDownload={handleDownload}
              handleDeleteFile={handleDeleteFile}
              handleMoveClick={handleMoveClick}
              handleRenameFile={handleRenameFile}
            />
          )}
          {isImgViewerOpen && ImageUrl && (
            <ImageViewer
              ImageUrl={ImageUrl}
              onClose={closeImgViewer}
              file={ImgToView}
              newFileName={newFileName}
              setNewFileName={setNewFileName}
              handleRenameClick={handleRenameClick}
              handleDownload={handleDownload}
              handleDeleteFile={handleDeleteFile}
              handleMoveClick={handleMoveClick}
              handleRenameFile={handleRenameFile}
              ViewerVisible={ViewerVisible}
              images={images}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
              setImageUrl={setImageUrl}
              setImages={setImages}
            />
          )}
        </>
      )}
    </div>
  );
}

export default FileSection;
