import React from "react";
import "../App.css";

const RenameFolderModal = ({ folderToRename, newFolderName, setNewFolderName, handleRenameFolder,setFolderToRename }) => {
  const modalRef2 = React.useRef(null);
  const bootstrapModal = React.useRef(null);

  // Handle showing the modal
  const showModal2 = () => {
    if (modalRef2.current) {
      bootstrapModal.current = new window.bootstrap.Modal(modalRef2.current);
      bootstrapModal.current.show();
      modalRef2.current.addEventListener("hide.bs.modal", handleModalHide);
    }
  };

  // Handle hiding the modal
  const hideModal = () => {
    if (bootstrapModal.current) {
      setFolderToRename(null);
      bootstrapModal.current.hide();
    }
  };

  const handleModalHide = () => {
    setFolderToRename(null);
    if (modalRef2.current) {
      modalRef2.current.removeEventListener("hide.bs.modal", handleModalHide);
    }
  };

  React.useEffect(() => {
    if (folderToRename) {
      showModal2();
    }
    // eslint-disable-next-line
  }, [folderToRename]);

  return (
    <div
      className="modal fade"
      id="renameFolderModal32"
      tabIndex="-1"
      aria-labelledby="renameModalLabel"
      aria-hidden="true"
      ref={modalRef2}
    >
      <div className="modal-dialog">
        <div className="modal-content" id="renamemodal">
          <div className="modal-header">
            <h5 className="modal-title" id="renameModalLabel">
              Rename Folder
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            ></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={newFolderName || ""}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter new folder name"
              className="form-control"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={hideModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleRenameFolder();
                hideModal();
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameFolderModal;
