// FileContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const FileContext = createContext();

// Create a provider component
export const FileProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Function to traverse file tree
  async function traverseFileTree(items) {
    const files = [];

    async function readEntries(directoryReader) {
      return new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    }

    async function processEntry(entry, path) {
      if (entry.isFile) {
        const file = await new Promise((resolve, reject) => {
          entry.file(resolve, reject);
        });
        file.fullPath = path + file.name;
        files.push(file);
      } else if (entry.isDirectory) {
        const directoryReader = entry.createReader();
        const entries = await readEntries(directoryReader);
        for (const subEntry of entries) {
          await processEntry(subEntry, path + entry.name + '/');
        }
      }
    }

    for (const item of items) {
      const entry = item.webkitGetAsEntry ? item.webkitGetAsEntry() : null;
      if (entry) {
        await processEntry(entry, '/');
      } else if (item instanceof File) {
        // Handle case where item is a File directly
        item.fullPath = '/'; // Default path for individual files
        files.push(item);
      } else {
        console.warn("Unsupported item type:", item);
      }
    }

    return files;
  }

  return (
    <FileContext.Provider value={{ selectedFiles, setSelectedFiles, traverseFileTree }}>
      {children}
    </FileContext.Provider>
  );
};

// Custom hook to use the FileContext
export const useFileContext = () => useContext(FileContext);
