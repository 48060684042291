import React, { useEffect, useRef } from "react";
import "../../App.css";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

const MediaPlayerModal = ({ mediaType, mediaSrc, isOpen, setIsOpen }) => {
  const modalRef = useRef(null);
  const bootstrapModal = useRef(null);

  // Show the modal
  const showModal = () => {
    if (modalRef.current) {
      bootstrapModal.current = new window.bootstrap.Modal(modalRef.current);
      bootstrapModal.current.show();
    }
  };

  // Hide the modal and reset the isOpen state
  const hideModal = () => {
    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      showModal();
    }

    const modalElement = modalRef.current;
    const handleOutsideClick = () => {
      setIsOpen(false);
    };

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleOutsideClick);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleOutsideClick);
      }
    };
  }, [isOpen, setIsOpen]);

  return (
    <div
      className="modal fade"
      id="mediaPlayerModal"
      tabIndex="-1"
      aria-labelledby="mediaPlayerModalLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="mediaPlayerModalLabel">
              Media Player
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            ></button>
          </div>
          <div className="modal-body">
            {mediaType === "audio" ? (
              <ReactAudioPlayer
                src={mediaSrc}
                controls
                onError={() => alert("Error loading audio file.")}
                preload="auto" // Ensure the file is loaded and buffered
              />
            ) : mediaType === "video" ? (
              <ReactPlayer
                url={mediaSrc}
                controls
                width="100%"
                height="auto"
                playing={true}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "anonymous",
                    },
                    // Enable buffering and other optimizations
                    forceAudio: mediaType === "audio",
                  },
                }}
                onError={() => alert("Error loading video file.")}
                light={true} // Use light mode to show a preview image
              />
            ) : (
              <p>Unsupported media type</p>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={hideModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPlayerModal;
