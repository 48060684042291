import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../App.css";
const Teams = ({
  users,
  currentUser,
  selectedTeam,
  setSelectedTeam,
  setShowIndiTeam,
  setShowTeams,
}) => {
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [teamName, setTeamName] = useState("");

  const [userIds, setUserIds] = useState([]);
  const [teamRequests, setTeamRequests] = useState({});

  const [userRole, setUserRole] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const modalRef = useRef(null);
  const bootstrapModal = useRef(null);
  const addMembersModalRef = useRef(null);
  const removeMembersModalRef = useRef(null);
  const managePermissionsModalRef = useRef(null);
  useEffect(() => {
    const filerElement = document.querySelector(".fileFolderaccess");
    if (filerElement) {
      filerElement.style.backgroundColor = "#000a147d";
    }
    return () => {
      if (filerElement) {
        filerElement.style.backgroundColor = "transparent";
      }
    };
  }, []);

  useEffect(() => {
    fetchTeams();
    fetchMyTeams();
    // eslint-disable-next-line
  }, [currentUser]);
  const fetchTeams = async () => {
    try {
      // Fetch all teams
      const responseAllTeams = await axios.get(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/teamsByName",
        {
          params: { name: "" }, // Adjust as needed if name filtering is used
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (responseAllTeams.status === 200) {
        const allTeamsData = responseAllTeams.data.teams; // Assume this returns an array of all teams
        setAllTeams(allTeamsData);

        // Fetch my teams
        const responseMyTeams = await axios.get(
          "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/myTeams",
          {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          }
        );

        if (responseMyTeams.status === 200) {
          const myTeamsData = responseMyTeams.data.teams; // Assume this returns an array of the user's teams

          // Set user teams
          setTeams(myTeamsData);
          const userRoleMap = {};
          myTeamsData.forEach((team) => {
            if (currentUser) {
              const isLeader = team.leader === currentUser._id;

              const permissions = isLeader
                ? {
                    canAdd: true,
                    canRemove: true,
                    isAdmin: true,
                  }
                : {
                    canAdd:
                      team.members.find(
                        (member) => member.user === currentUser._id
                      )?.canAddMembers || false,
                    canRemove:
                      team.members.find(
                        (member) => member.user === currentUser._id
                      )?.canRemoveMembers || false,
                    isAdmin:
                      team.members.find(
                        (member) => member.user === currentUser._id
                      )?.isAdmin || false,
                  };

              userRoleMap[team._id] = { isLeader, ...permissions };
            }
          });
          setUserRole(userRoleMap);

          const filteredTeams = allTeamsData.filter(
            (team) => !myTeamsData.some((userTeam) => userTeam._id === team._id)
          );

          setAllTeams(filteredTeams);
        }
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const fetchMyTeams = async () => {
    try {
      const response = await axios.get(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/myTeams",
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const myTeamsData = response.data.teams; // Assume this returns an array of teams
        setTeams(myTeamsData);
      }
    } catch (error) {
      console.error("Error fetching my teams:", error);
    }
  };

  const createTeam = async () => {
    try {
      const response = await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/createTeam",
        { name: teamName },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const createdTeam = response.data.team; // Assume this returns the created team
        setTeams([...teams, createdTeam]);
        setAllTeams([...allTeams, createdTeam]); // Also update all teams list
        setTeamName("");
      }
    } catch (error) {
      console.error("Error creating team:", error);
    }
  };

  const ws = useRef(null);

  useEffect(() => {
    if (currentUser && currentUser._id) {
      if (!ws.current) {
        ws.current = new WebSocket(
          "wss://secure-storage-system-mern-cloud-file.onrender.com"
        );

        ws.current.onopen = () => {
          if (currentUser && currentUser._id) {
            ws.current.send(
              JSON.stringify({
                type: "set-user-id",
                userId: currentUser._id,
              })
            );
          }
        };
        // Assuming `ws` is your WebSocket connection
        ws.current.onmessage = async (event) => {
          try {
            const data = JSON.parse(event.data);

            if (data.type === "team-request") {
              if (data.userId === currentUser._id) {
                await fetchMyTeams(); // Refresh team data
                await fetchTeams();
              }
            }
            if (
              data.type === "team-update" &&
              data.action === "remove-members"
            ) {
              const { removedUserIds } = data;

              if (removedUserIds.includes(currentUser._id)) {
                await fetchMyTeams();
                await fetchTeams();
              }
            }
            if (data.type === "team-update" && data.action === "add-members") {
              const { addUserIds } = data;

              if (addUserIds.includes(currentUser._id)) {
                await fetchMyTeams();
                await fetchTeams();
              }
            }
            if (
              data.type === "team-update" &&
              data.action === "permission-update"
            ) {
              const { UserIds } = data;

              if (UserIds.includes(currentUser._id)) {
                await fetchMyTeams();
                await fetchTeams();
              }
            }
          } catch (error) {
            console.error("Error processing WebSocket message:", error);
          }
        };

        ws.current.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.current.onclose = () => {
          // console.log("WebSocket connection closed");
        };

        return () => {
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.close();
          }
        };
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const addMembers = async () => {
    try {
      const response = await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/addMember",
        { teamId: selectedTeam._id, userIds },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const updatedTeam = response.data.team; // Assume this returns the updated team

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              type: "team-update",
              action: "add-members",
              addUserIds: userIds,
            })
          );
        }

        await fetchMyTeams();
        setTeams(
          teams.map((team) =>
            team._id === selectedTeam._id ? updatedTeam : team
          )
        );
        setUserIds([]);
      }
    } catch (error) {
      console.error("Error adding members:", error);
    }
  };

  const removeMembers = async () => {
    try {
      const response = await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/removeMembers",
        { teamId: selectedTeam._id, userIds },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const updatedTeam = response.data.team;

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              type: "team-update",
              action: "remove-members",
              removedUserIds: userIds,
            })
          );
        }

        await fetchMyTeams();
        setTeams((prevTeams) =>
          prevTeams.map((team) =>
            team._id === selectedTeam._id ? updatedTeam : team
          )
        );
        setUserIds([]); // Clear the selection after all removals
      }
    } catch (error) {
      console.error("Error removing members:", error);
    }
  };

  useEffect(() => {
    if (selectedTeam) {
      // Initialize permissions state based on selectedTeam data
      const permissions = selectedTeam.members.map((member) => ({
        userId: member.user,
        canAddMembers: member.canAddMembers ?? false,
        canRemoveMembers: member.canRemoveMembers ?? false,
        isAdmin: member.isAdmin ?? false,
      }));
      setSelectedPermissions(permissions);
    }
  }, [selectedTeam]);

  const handlePermissionChange = (userId, permissionType, value) => {
    setSelectedPermissions((prevPermissions) => {
      const userIndex = prevPermissions.findIndex((p) => p.userId === userId);
      if (userIndex !== -1) {
        const updatedPermissions = [...prevPermissions];
        updatedPermissions[userIndex][permissionType] = value;
        return updatedPermissions;
      } else {
        return [...prevPermissions, { userId, [permissionType]: value }];
      }
    });
  };

  const manageMemberPermission = async () => {
    try {
      const response = await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/manageMemberPermission",
        {
          teamId: selectedTeam._id,
          permissions: selectedPermissions,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      const userIds = selectedPermissions.map(
        (permission) => permission.userId
      );
      if (response.status === 200) {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              type: "team-update",
              action: "permission-update",
              UserIds: userIds,
            })
          );
        }

        const updatedTeam = response.data.team;
        setTeams(
          teams.map((team) =>
            team._id === selectedTeam._id ? updatedTeam : team
          )
        );
        setSelectedPermissions([]); // Clear selection after update
      }
    } catch (error) {
      console.error("Error managing member permissions:", error);
    }
  };

  const sendJoinRequest = async (teamId) => {
    try {
      await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/requestJoin",
        { teamId },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      alert("Join request sent");
    } catch (error) {
      console.error("Error sending join request:", error);
    }
  };

  const [openTeam, setOpenTeam] = useState(null);
  const collapseRefs = useRef({}); // Store refs for each team's collapse

  const handleCollapseToggle = async (teamId) => {
    if (openTeam === teamId) {
      // If the collapse is already open for this team, close it
      setOpenTeam(null);
    } else {
      // Fetch the requests first
      await fetchRequests(teamId);

      // Then set the open team, triggering the height adjustment
      setOpenTeam(teamId);
    }
  };

  useEffect(() => {
    const resizeObservers = {};

    // Dynamically adjust height based on content size changes
    Object.keys(collapseRefs.current).forEach((teamId) => {
      const element = collapseRefs.current[teamId];
      if (element) {
        // Set initial max-height based on open/closed state
        if (openTeam === teamId) {
          const contentHeight = element.scrollHeight;
          element.style.maxHeight = `${contentHeight}px`;
        } else {
          element.style.maxHeight = "0";
        }

        // Create a ResizeObserver to monitor content size changes
        resizeObservers[teamId] = new ResizeObserver(() => {
          if (openTeam === teamId) {
            const updatedHeight = element.scrollHeight;
            element.style.maxHeight = `${updatedHeight}px`;
          }
        });

        // Observe the collapse content for size changes
        resizeObservers[teamId].observe(element);
      }
    });

    // Cleanup observers when the component is unmounted or team changes
    return () => {
      Object.values(resizeObservers).forEach((observer) =>
        observer.disconnect()
      );
    };
  }, [openTeam]);

  const fetchRequests = async (teamId) => {
    try {
      const response = await axios.get(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/teamRequests/${teamId}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const requestsData = response.data.requests;
        setTeamRequests((prevRequests) => ({
          ...prevRequests,
          [teamId]: requestsData, // Store requests for this specific team
        }));
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  };

  const respondToRequest = async (team, userId, accept) => {
    try {
      const teamId = team._id;
      await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/respondRequest",
        { teamId, userId, accept },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      setSelectedTeam([]);
      setSelectedTeam(team);
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            type: "team-request",
            teamId,
            userId,
            accept,
            senderId: currentUser._id,
          })
        );
      }
      await fetchMyTeams();
      setTeamRequests((prevRequests) => {
        const updatedRequests = prevRequests[teamId].filter(
          (request) => request.user._id !== userId
        );
        return {
          ...prevRequests,
          [teamId]: updatedRequests,
        };
      });
    } catch (error) {
      console.error("Error responding to request:", error);
    }
  };

  const showModal = async (type) => {
    await fetchMyTeams();
    if (type === "manage") {
      if (modalRef.current) {
        bootstrapModal.current = new window.bootstrap.Modal(modalRef.current);
        bootstrapModal.current.show();
      }
    } else if (type === "addMem") {
      const updatedTeam = teams.find((team) => team._id === selectedTeam._id);
      setSelectedTeam(updatedTeam);
      if (addMembersModalRef.current) {
        bootstrapModal.current = new window.bootstrap.Modal(
          addMembersModalRef.current
        );
        bootstrapModal.current.show();
      }
    } else if (type === "remMem") {
      const updatedTeam = teams.find((team) => team._id === selectedTeam._id);
      setSelectedTeam(updatedTeam);
      if (removeMembersModalRef.current) {
        bootstrapModal.current = new window.bootstrap.Modal(
          removeMembersModalRef.current
        );
        bootstrapModal.current.show();
      }
    } else if (type === "manageMem") {
      const updatedTeam = teams.find((team) => team._id === selectedTeam._id);
      setSelectedTeam(updatedTeam);
      if (managePermissionsModalRef.current) {
        bootstrapModal.current = new window.bootstrap.Modal(
          managePermissionsModalRef.current
        );
        bootstrapModal.current.show();
      }
    }
  };

  const hideModal = () => {
    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
    }
  };

  const handleIndiTeamClick = (team) => {
    setSelectedTeam(team);
    setShowTeams(false);
    setShowIndiTeam(true);
  };

  const getUserNameById = (id) => {
    const user = users.find((user) => user._id === id);
    return user ? user.name : "Unknown";
  };

  return (
    <div
      className="teamElem"
      style={{ color: "#dfd5ff", margin: "0rem 2rem 0rem 0rem" }}
    >
      <h1 className="display-1">Teams</h1>
      {/* Teams where the user is a leader or member */}
      <div style={{ margin: "4rem 0rem" }}>
        <h3 className="display-6" style={{ marginBottom: "2rem" }}>
          My Teams
        </h3>
        {teams.length > 0 ? (
          teams.map((team) => (
            <div
              key={team._id}
              className="myTeamer"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "1rem 0rem",
                border: "0.5px solid #cebffd",
                borderRadius: "0.5rem",
                padding: "0.5rem 0.5rem",
              }}
            >
              <div
                className="myTeamer2"
                key={team._id}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0.8rem 0.8rem",
                }}
                onClick={() => handleIndiTeamClick(team)}
              >
                <h3
                  className="display-6"
                  style={{ fontSize: "1.5rem", fontWeight: "300" }}
                >
                  {team.name}
                </h3>
                <div
                  className="manageTeambut"
                  onClick={(e) => e.stopPropagation()}
                >
                  {userRole[team._id]?.canAdd ||
                  userRole[team._id]?.canRemove ||
                  userRole[team._id]?.isAdmin ? (
                    <button
                      className="btn btn-outline-light"
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        setSelectedTeam(team);
                        showModal("manage");
                      }}
                    >
                      Manage Team
                    </button>
                  ) : null}
                  {userRole[team._id]?.isLeader && (
                    <>
                      <button
                        className="btn"
                        type="button"
                        onClick={() => handleCollapseToggle(team._id)}
                      >
                        View Requests
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div
                className={`collapse-content ${
                  openTeam === team._id ? "open" : ""
                }`}
                ref={(el) => (collapseRefs.current[team._id] = el)}
                id={`collapseRequests-${team._id}`}
                style={{
                  width: "100%",
                  borderRadius: "0.5rem",
                }}
              >
                {teamRequests[team._id]?.length > 0 ? (
                  teamRequests[team._id].map((request) => (
                    <div
                      className="card card-body"
                      key={request.user._id}
                      style={{
                        backgroundColor: "#050014",
                        margin: "0.5rem 0rem",
                        padding: "0.7rem 1rem",
                      }}
                    >
                      <div
                        style={{
                          margin: "0.5rem 0rem",
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{request.user.name}</span>
                        <div>
                          <button
                            className="btn btn-success btn-sm"
                            style={{ marginLeft: "1rem" }}
                            onClick={() =>
                              respondToRequest(team, request.user._id, true)
                            }
                          >
                            Accept
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() =>
                              respondToRequest(team, request.user._id, false)
                            }
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="card card-body"
                    style={{
                      backgroundColor: "#050014",
                      margin: "0.5rem 0rem",
                      padding: "0.7rem 1rem",
                    }}
                  >
                    <p style={{ margin: "0", padding: "0.7rem 0.0rem" }}>
                      No requests found
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No teams found</p>
        )}
      </div>
      {/* Create Team */}

      <div style={{ margin: "4rem 0rem 2rem 0rem" }}>
        <h3 className="display-6" style={{ marginBottom: "2rem" }}>
          Create Team
        </h3>
        <div className="input-group mb-3" style={{ height: "3rem" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Team Name"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            value={teamName}
            style={{ backgroundColor: "#050014", color: "white" }}
            onChange={(e) => setTeamName(e.target.value)}
          />
          <button
            className="btn btn-outline-light"
            type="button"
            id="button-addon2"
            onClick={createTeam}
          >
            Create Team
          </button>
        </div>
      </div>
      {/* All Teams */}
      <div style={{ margin: "4rem 0rem" }}>
        <h3 className="display-6" style={{ marginBottom: "2rem" }}>
          Other Teams
        </h3>
        {allTeams
          .filter(
            (team) => !teams.some((userTeam) => userTeam._id === team._id)
          )
          .map((team) => (
            <div
              className="joiner"
              key={team._id}
              style={{
                cursor: "pointer",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "1rem 0rem",
                border: "0.5px solid #cebffd",
                padding: "0.8rem 0.8rem",
                borderRadius: "0.5rem",
              }}
            >
              <h3
                className="display-6"
                style={{ fontSize: "1.5rem", fontWeight: "300" }}
              >
                {team.name}
              </h3>
              <button
                className="btn btn-outline-light"
                onClick={() => sendJoinRequest(team._id)}
              >
                Request to Join
              </button>
            </div>
          ))}
      </div>
      {/* Modal for managing team */}
      <div
        className="modal fade"
        id="teamManagementModal"
        tabIndex="-1"
        aria-labelledby="teamManagementModalLabel"
        aria-hidden="true"
        ref={modalRef}
        style={{ backgroundColor: "#000000a8" }}
      >
        <div
          className="modal-dialog mobileModal"
          style={{ maxWidth: "100%", width: "100%" }}
        >
          <div
            className="modal-content modCon"
            style={{
              backgroundColor: "#0b0812f5",
              color: "white",
              height: "60%",
              width: "40%",
              borderRadius: "0.5rem",
              border: "1px solid #ccc0fe",
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingRight: "2rem",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
              }}
            >
              <div
                id="heading"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "flex-start",
                }}
              >
                <h5 className="display-6" style={{ fontSize: "2.3rem" }}>
                  EffortLessly manage your team
                </h5>
                <h5
                  className="modal-title display-6"
                  style={{ fontSize: "2rem", fontWeight: "400" }}
                  id="teamManagementModalLabel"
                >
                  {selectedTeam && `${selectedTeam.name}`}
                </h5>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                style={{ backgroundColor: "cyan" }}
                aria-label="Close"
                onClick={hideModal}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{ color: "white", padding: "1.2rem", overflowY: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  height: "100%",
                }}
              >
                {selectedTeam && userRole[selectedTeam._id]?.canAdd && (
                  <div
                    className="adder"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem 0rem",
                    }}
                  >
                    <h4
                      className="display-6"
                      style={{ fontSize: "xx-large", fontWeight: "300" }}
                    >
                      Add members to your team
                    </h4>
                    <button
                      className="teamModalButton aqua-button"
                      onClick={() => showModal("addMem")}
                      style={{ width: "10%" }}
                    ></button>
                  </div>
                )}
                {selectedTeam && userRole[selectedTeam._id]?.canRemove && (
                  <div
                    className="remover"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem 0rem",
                    }}
                  >
                    <h4
                      className="display-6"
                      style={{ fontSize: "xx-large", fontWeight: "300" }}
                    >
                      Remove members from your team
                    </h4>
                    <button
                      className="teamModalButton aqua-button"
                      onClick={() => showModal("remMem")}
                      style={{ width: "10%" }}
                    ></button>
                  </div>
                )}
                {selectedTeam && userRole[selectedTeam._id]?.isAdmin && (
                  <div
                    className="managerPer"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem 0rem",
                    }}
                  >
                    <h4
                      className="display-6"
                      style={{ fontSize: "xx-large", fontWeight: "300" }}
                    >
                      Manage Permissions
                    </h4>
                    <button
                      className="teamModalButton aqua-button "
                      onClick={() => showModal("manageMem")}
                      style={{ width: "10%" }}
                    ></button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Second Modal for adding members */}
      <div
        className={"modal fade"}
        id="addMembersModal"
        tabIndex="-1"
        aria-labelledby="addMembersModalLabel"
        aria-hidden="true"
        style={{
          backgroundColor: "#000000a8",
        }}
        ref={addMembersModalRef}
      >
        <div
          className="modal-dialog modal-lg mobileModal"
          style={{ maxWidth: "100%", width: "100%" }}
        >
          <div
            className="modal-content modCon"
            style={{
              backgroundColor: "#0b0812f5",
              color: "white",
              height: "70%",
              width: "30%",
              borderRadius: "0.5rem",
              border: "1px solid #ccc0fe",
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingRight: "2rem",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
              }}
            >
              <h5
                className="modal-title display-6"
                style={{ fontSize: "2.3rem" }}
                id="addMembersModalLabel"
              >
                Add Members
              </h5>

              <button
                type="button"
                className="btn-close"
                style={{ backgroundColor: "cyan" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => hideModal()}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                color: "white",
                padding: "1.2rem",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {selectedTeam &&
                users
                  .filter(
                    (user) =>
                      !selectedTeam.members.some(
                        (member) => member.user === user._id
                      )
                  )
                  .map((user) => (
                    <div
                      key={user._id}
                      className="form-check"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: "x-large",
                        color: "#050014",
                        padding: "1rem 2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const checkbox = document.getElementById(
                          `user-${user._id}`
                        );
                        checkbox.checked = !checkbox.checked;
                        checkbox.dispatchEvent(
                          new Event("change", { bubbles: true })
                        );
                      }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`user-${user._id}`}
                        value={user._id}
                        onChange={(e) => {
                          const { checked, value } = e.target;
                          setUserIds((prev) =>
                            checked
                              ? [...prev, value]
                              : prev.filter((id) => id !== value)
                          );
                        }}
                        style={{
                          height: "2.5rem",
                          width: "2.5rem",
                          borderRadius: "5rem",
                          marginRight: "1rem",
                          cursor: "pointer",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`user-${user._id}`}
                        style={{
                          color: "white",
                          height: "3rem",
                          fontSize: "x-large",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {user.name}
                      </label>
                    </div>
                  ))}
            </div>

            <div className="modal-footer d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="teamModalButton aqua-button"
                data-bs-dismiss="modal"
                onClick={() => hideModal()}
              >
                Close
              </button>
              <button
                type="button"
                className="teamModalButton aqua-button"
                onClick={() => {
                  addMembers();
                  hideModal();
                }}
              >
                Add Members
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="removeMembersModal"
        tabIndex="-1"
        aria-labelledby="removeMembersModalLabel"
        aria-hidden="true"
        style={{
          backgroundColor: "#000000a8",
        }}
        ref={removeMembersModalRef}
      >
        <div
          className="modal-dialog modal-lg mobileModal"
          style={{ maxWidth: "100%", width: "100%" }}
        >
          <div
            className="modal-content modCon"
            style={{
              backgroundColor: "#0b0812f5",
              color: "white",
              height: "70%",
              width: "30%",
              borderRadius: "0.5rem",
              border: "1px solid #ccc0fe",
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingRight: "2rem",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
              }}
            >
              <h5
                className="modal-title display-6"
                style={{ fontSize: "2.3rem" }}
                id="removeMembersModalLabel"
              >
                Remove Members
              </h5>
              <button
                type="button"
                className="btn-close"
                style={{ backgroundColor: "cyan" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => hideModal()}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                color: "white",
                padding: "1.2rem",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {selectedTeam &&
                selectedTeam.members.map((member) => (
                  <div
                    key={member.user}
                    className="form-check"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: "x-large",
                      color: "#050014",
                      padding: "1rem 2rem",
                      cursor: "pointer", // Make the whole div clickable
                    }}
                    onClick={() => {
                      if (selectedTeam.leader === member.user) {
                      } else {
                        const checkbox = document.getElementById(
                          `member-${member.user}`
                        );
                        checkbox.checked = !checkbox.checked;
                        checkbox.dispatchEvent(
                          new Event("change", { bubbles: true })
                        );
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`member-${member.user}`}
                      value={member.user}
                      onChange={(e) => {
                        const { checked, value } = e.target;
                        setUserIds((prev) =>
                          checked
                            ? [...prev, value]
                            : prev.filter((id) => id !== value)
                        );
                      }}
                      disabled={selectedTeam.leader === member.user}
                      style={{
                        height: "2.5rem",
                        width: "2.5rem",
                        borderRadius: "5rem",
                        marginRight: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`member-${member.user}`}
                      style={{
                        color: "white",
                        height: "3rem",
                        fontSize: "x-large",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {member.user === currentUser._id
                        ? currentUser.name + " (You)"
                        : getUserNameById(member.user)}
                    </label>
                  </div>
                ))}
            </div>
            <div className="modal-footer d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="teamModalButton aqua-button"
                data-bs-dismiss="modal"
                onClick={() => hideModal()}
              >
                Close
              </button>
              <button
                type="button"
                className="teamModalButton aqua-button"
                onClick={() => {
                  removeMembers(selectedTeam);
                  hideModal();
                }}
              >
                Remove Members
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="managePermissionsModal"
        tabIndex="-1"
        aria-labelledby="managePermissionsModalLabel"
        aria-hidden="true"
        style={{
          backgroundColor: "#000000a8",
        }}
        ref={managePermissionsModalRef}
      >
        <div
          className="modal-dialog modal-lg mobileModal"
          style={{ maxWidth: "100%", width: "100%" }}
        >
          <div
            className="modal-content modCon"
            style={{
              backgroundColor: "#0b0812f5",
              color: "white",
              height: "70%",
              width: "50%",
              borderRadius: "0.5rem",
              border: "1px solid #ccc0fe",
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingRight: "2rem",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
              }}
            >
              <h5
                className="modal-title display-6"
                style={{ fontSize: "2.3rem" }}
                id="managePermissionsModalLabel"
              >
                Manage Permissions
              </h5>
              <button
                type="button"
                className="btn-close"
                style={{ backgroundColor: "cyan" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => hideModal()}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                color: "white",
                padding: "1.2rem",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {selectedTeam && (
                <div
                  className="managerPer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {/* Header Row */}
                  <div
                    className="managerPerdiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginBottom: "0.25rem",
                      paddingBottom: "1rem",
                      borderBottom: "1px solid white",
                    }}
                  >
                    <h3
                      id="managerPername"
                      className="display-6"
                      style={{
                        flex: "1.3",
                        fontSize: "x-large",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Name
                    </h3>
                    <h3
                      className="display-6"
                      style={{
                        flex: "1",
                        fontSize: "large",
                        textAlign: "center",
                      }}
                    >
                      Add Members
                    </h3>
                    <h3
                      className="display-6"
                      style={{
                        flex: "1",
                        fontSize: "large",
                        textAlign: "center",
                      }}
                    >
                      Remove Members
                    </h3>
                    <h3
                      className="display-6"
                      style={{
                        flex: "1",
                        fontSize: "large",
                        textAlign: "center",
                      }}
                    >
                      Manage Permissions
                    </h3>
                  </div>

                  {/* Permissions Rows */}
                  {selectedTeam.members
                    .filter((member) => member.user !== currentUser._id)
                    .map((member) => (
                      <div
                        className="managerPerdiv"
                        key={member.user}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0.5rem",
                          fontSize: "x-large",
                          color: "white",
                          cursor: "pointer",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ flex: "1.5" }}>
                          {getUserNameById(member.user)}
                        </span>
                        <div className="X23container" style={{ flex: "1" }}>
                          <input
                            type="checkbox"
                            className="checkboxUltra"
                            id={`btn-${member.user}`}
                            checked={
                              selectedPermissions.find(
                                (p) => p.userId === member.user
                              )?.canAddMembers ?? false
                            }
                            onChange={(e) =>
                              handlePermissionChange(
                                member.user,
                                "canAddMembers",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="switch"
                            htmlFor={`btn-${member.user}`}
                          >
                            <span className="slider"></span>
                          </label>
                        </div>
                        <div className="X23container" style={{ flex: "1" }}>
                          <input
                            type="checkbox"
                            className="checkboxUltra"
                            id={`btn2-${member.user}`}
                            checked={
                              selectedPermissions.find(
                                (p) => p.userId === member.user
                              )?.canRemoveMembers ?? false
                            }
                            onChange={(e) =>
                              handlePermissionChange(
                                member.user,
                                "canRemoveMembers",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="switch"
                            htmlFor={`btn2-${member.user}`}
                          >
                            <span className="slider"></span>
                          </label>
                        </div>
                        <div className="X23container" style={{ flex: "1" }}>
                          <input
                            type="checkbox"
                            className="checkboxUltra"
                            id={`btn3-${member.user}`}
                            checked={
                              selectedPermissions.find(
                                (p) => p.userId === member.user
                              )?.isAdmin ?? false
                            }
                            onChange={(e) =>
                              handlePermissionChange(
                                member.user,
                                "isAdmin",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="switch"
                            htmlFor={`btn3-${member.user}`}
                          >
                            <span className="slider"></span>
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div className="modal-footer d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="teamModalButton aqua-button"
                data-bs-dismiss="modal"
                onClick={() => hideModal()}
              >
                Close
              </button>
              <button
                type="button"
                className="teamModalButton aqua-button"
                onClick={() => {
                  manageMemberPermission();
                  hideModal();
                }}
              >
                Update Permissions
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
