import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker"; // You may need to install this package
import "react-datepicker/dist/react-datepicker.css";
import pdfIcon from "../icons/pdfFile.png";
import txtIcon from "../icons/txtFile.png";
import mp3Icon from "../icons/mp3icon.png";
import mp4Icon from "../icons/mp4icon.png";
import zipIcon from "../icons/zipicon.png";
import docXIcon from "../icons/docxicon.png";
import imgIcon from "../icons/imgIcon.png";
import PptIcon from "../icons/pptIcon.png";
import XlsxIcon from "../icons/xlsxIcon.png";
import UnknownIcon from "../icons/unknownFile.png";
import sentIcon from "../icons/sent.png";
import recievedIcon from "../icons/recieved.png";
import normalFolder1 from "../icons/normalFolder1.png";
import normalFolder2 from "../icons/normalFolder2.png";
import normalFolder3 from "../icons/normalFolder3.png";
import normalFolder4 from "../icons/normalFolder4.png";
import seentrue from "../icons/check.png";
import seenfalse from "../icons/uncheck.png";
import editPf from "../icons/edit-pen.png";
import cloudIc from "../icons/cloudIc.png";
// import prof from "../icons/profilepic.png";
import { format, isToday, isYesterday } from "date-fns"; // You can use date-fns for formatting
import "./Dashboard.css";
import CropUpdater from "./Auth/CropUpdater";
const Dashboard = ({
  users,
  receivedTodayFolders,
  receivedTodayFiles,
  sentTodayFiles,
  sentTodayFolders,
  fetchSentItems,
  fetchReceivedTodayCounts,
  currentUser,
  profilePic,
  fetchProfilePic,
  totalFiles,
  totalFolders,
  fetchUserItemCounts,
  loading5,
}) => {
  const [receivedCount, setReceivedCount] = useState(0);
  const [receivedCount2, setReceivedCount2] = useState(0);
  const [sentCount, setSentCount] = useState(0);
  const [sentCount2, setSentCount2] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const fetchDashBoardCount = async () => {
    try {
      const response = await axios.get(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/count-summary",
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      const data = response.data;

      // Extract the values from the nested objects
      setSentCount(data.sentCount.files);
      setSentCount2(data.sentCount.folders);
      setReceivedCount(data.receivedCount.files);
      setReceivedCount2(data.receivedCount.folders);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  useEffect(() => {
    fetchDashBoardCount();
    if (currentUser) {
      fetchProfilePic(currentUser._id);
    }
    fetchUserItemCounts();
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchReceivedTodayCounts(selectedDate);
      await fetchSentItems(selectedDate);
    };

    fetchData(); // Call the async function
    // eslint-disable-next-line
  }, [selectedDate]);

  const fileIconMap = {
    pdf: pdfIcon,
    txt: txtIcon,
    mp3: mp3Icon,
    mp4: mp4Icon,
    avi: mp4Icon,
    mov: mp4Icon,
    wmv: mp4Icon,
    mkv: mp4Icon,
    flv: mp4Icon,
    mpeg: mp4Icon,
    mpg: mp4Icon,
    "3gp": mp4Icon,
    webm: mp4Icon,
    ogv: mp4Icon,
    ogg: mp4Icon,
    m4v: mp4Icon,
    ts: mp4Icon,
    f4v: mp4Icon,
    m2ts: mp4Icon,
    vob: mp4Icon,
    zip: zipIcon,
    docx: docXIcon,
    doc: docXIcon,
    ppt: PptIcon,
    pptx: PptIcon,
    potx: PptIcon,
    pps: PptIcon,
    ppsx: PptIcon,
    xlsx: XlsxIcon,
    xls: XlsxIcon,
    csv: XlsxIcon,
    jpg: imgIcon,
    jpeg: imgIcon,
    png: imgIcon,
    gif: imgIcon,
    bmp: imgIcon,
    svg: imgIcon,
  };

  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    return fileIconMap[extension] || UnknownIcon;
  };

  const getUserNameById = (id) => {
    const user = users.find((user) => user._id === id);
    return user ? user.name : "Unknown";
  };

  const getStatus = (item) => {
    if (currentUser) {
      const userId = currentUser._id;
      const sharedWith = item.sharedWith.find((share) => share.userId === userId);
      
      // Return the seenStatus if sharedWith exists
      return sharedWith ? sharedWith.seenStatus : "Unknown";
    }
    return "Unknown";
  };
  

  const getStatus2 = (item) => {
    // Get the status for each recipient
    const recipientName = getUserNameById(item.userId);
    const status = item.seenStatus ? "Seen" : "Unseen";
    return `${status} by ${recipientName}`;
  };

  // Pagination state
  const [currentPageReceivedFiles, setCurrentPageReceivedFiles] = useState(1);
  const [entriesPerPageReceivedFiles, setEntriesPerPageReceivedFiles] =
    useState(3);

  const [currentPageReceivedFolders, setCurrentPageReceivedFolders] =
    useState(1);
  const [entriesPerPageReceivedFolders, setEntriesPerPageReceivedFolders] =
    useState(3);

  const [currentPageSentFiles, setCurrentPageSentFiles] = useState(1);
  const [entriesPerPageSentFiles, setEntriesPerPageSentFiles] = useState(3);

  const [currentPageSentFolders, setCurrentPageSentFolders] = useState(1);
  const [entriesPerPageSentFolders, setEntriesPerPageSentFolders] = useState(3);

  // Pagination logic
  const getPaginatedData = (data, page, entriesPerPage) => {
    const maxEntries = entriesPerPage;
    return data.slice((page - 1) * maxEntries, page * maxEntries);
  };

  const totalPages = (items, entriesPerPage) => {
    if (entriesPerPage === 0 || !items.length) return 0;
    return Math.ceil(items.length / entriesPerPage);
  };

  // Handlers for pagination
  const handlePageChange =
    (setter, currentPage, data, entriesPerPage) => (direction) => {
      const total = totalPages(data, entriesPerPage);
      if (direction === "next" && currentPage < total) {
        setter(currentPage + 1);
      }
      if (direction === "prev" && currentPage > 1) {
        setter(currentPage - 1);
      }
    };

  // Format the selected date
  let formattedDate;

  if (isToday(selectedDate)) {
    formattedDate = "Today";
  } else if (isYesterday(selectedDate)) {
    formattedDate = "Yesterday";
  } else {
    formattedDate = format(selectedDate, "EEEE, do MMMM yyyy");
  }

  const getFolderIcon = (size) => {
    if (size < 10 * 1024 * 1024) {
      // Below 10MB
      return normalFolder1;
    } else if (size >= 10 * 1024 * 1024 && size < 25 * 1024 * 1024) {
      // 10MB to 25MB
      return normalFolder2;
    } else if (size >= 25 * 1024 * 1024 && size < 50 * 1024 * 1024) {
      // 25MB to 50MB
      return normalFolder3;
    } else {
      // Greater than 50MB
      return normalFolder4;
    }
  };
  const getReadableSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      // Size is less than 1 KB
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
      // Size is in KB
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
      // Size is in MB
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else {
      // Size is in GB
      return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
  };

  const getFirstName = (name) => {
    if (!name) return "";
    const hasWhitespace = name.includes(" ");
    if (name.length > 30) {
      return hasWhitespace ? name.split(" ")[0] : name.slice(0, 25);
    }
    return name;
  };

  const getPaginationPages = (currentPage, totalPages) => {
    const pages = [];
    const maxVisiblePages = 5; // Maximum visible page links

    if (totalPages <= maxVisiblePages) {
      // If total pages are less than or equal to max visible pages, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const [showCropper, setShowCropper] = useState(false);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        alert("Please upload a JPG or PNG image");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCrop = async (croppedBlob) => {
    const formData = new FormData();
    formData.append("profilePic", croppedBlob);

    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/updateProfilePic",
        {
          method: "POST",
          headers: {
            "auth-token": localStorage.getItem("token"), // Assuming you are using a token for auth
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update profile picture");
      }

      // Fetch the updated profile picture
      if (currentUser) {
        fetchProfilePic(currentUser._id);
      }
    } catch (error) {
      console.error("Error updating profile picture:", error.message);
    } finally {
      setShowCropper(false);
    }
  };

  const [userProfilePics, setUserProfilePics] = useState({}); // Store all user profile pics

  const fetchUserProfilePic = async (userId) => {
    try {
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/profilePic/${userId}`
      );
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setUserProfilePics((prevPics) => ({
          ...prevPics,
          [userId]: imageObjectURL,
        }));
      } else {
        console.error("Failed to fetch profile pic");
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
  };

  // Fetch profile pictures for all users
  useEffect(() => {
    users.forEach((user) => {
      if (!userProfilePics[user._id]) {
        fetchUserProfilePic(user._id);
      }
    });
    // eslint-disable-next-line
  }, [users]); // Re-run this effect when `users` array changes

  useEffect(() => {
    if (currentUser && !userProfilePics[currentUser._id]) {
      fetchUserProfilePic(currentUser._id);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const [openTeam, setOpenTeam] = useState(null);
  const collapseRefs = useRef({}); // Store refs for each team's collapse

  const handleCollapseToggle = async (teamId) => {
    if (openTeam === teamId) {
      setOpenTeam(null);
    } else {
      setOpenTeam(teamId);
    }
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row" style={{ marginBottom: "3rem" }}>
          {/* Uploaded Files Section */}
          <div className="col-12 grid-margin stretch-card">
            <div
              className="card card-statistics"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body"
                style={{
                  height: "14rem",
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="dashboarder"
                  style={{
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div className="titleer">
                    <h1 className="display-6">
                      Welcome back to your Dashboard,{" "}
                    </h1>
                    <h1 className="display-6" style={{ fontWeight: "400" }}>
                      {currentUser && getFirstName(currentUser.name)}
                    </h1>
                  </div>

                  <div
                    className="profilepic"
                    style={{
                      width: "15%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "4rem",
                    }}
                  >
                    <button
                      onClick={handleEditClick}
                      style={{
                        position: "relative",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        border: "none",
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "11rem",
                        height: "11rem",
                      }}
                    >
                      {loading5 ? (
                        <div className="spinner-border text-info" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <img
                          className="profilepIc"
                          src={profilePic}
                          alt="profile"
                          style={{
                            marginRight: "0rem",
                          }}
                        />
                      )}
                      <img
                        src={editPf}
                        className="editpfpic"
                        alt="edit"
                        style={{
                          width: "1rem",
                          position: "absolute",
                          top: "0",
                          right: "0",
                          zIndex: "2",
                        }}
                      />
                    </button>

                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  {showCropper && image && (
                    <CropUpdater image={image} onCrop={handleCrop} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row dashboarderRow">
          {/* Sent Files Section */}
          <div className="col-md-4 grid-margin stretch-card">
            <div
              className="card card-statistics"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body"
                style={{
                  height: "9rem",
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="dashboarder">
                  <div className="img">
                    <img
                      src={cloudIc}
                      alt="icon"
                      style={{ width: "5rem", marginRight: "1rem" }}
                    />
                  </div>

                  <div className="clearfix" style={{ margin: "0" }}>
                    <div
                      className="float-right"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <figure>
                        <blockquote className="blockquote">
                          <h1
                            className="display-6"
                            // style={{ padding: "0rem 1rem 0rem 0rem" }}
                          >
                            <small
                              className="text-body-secondary"
                              style={{ fontSize: "0.6em" }}
                            >
                              Files
                            </small>
                            <span style={{ fontWeight: "500" }}>
                              {totalFiles}
                            </span>
                          </h1>
                          <h1
                            className="display-6"
                            // style={{ padding: "0rem 0rem 0rem 1rem" }}
                          >
                            <small
                              className="text-body-secondary"
                              style={{ fontSize: "0.6em" }}
                            >
                              Folders
                            </small>
                            <span style={{ fontWeight: "500" }}>
                              {totalFolders}
                            </span>
                          </h1>
                        </blockquote>
                        <figcaption className="blockquote-footer">
                          in Synchronous Cloud{" "}
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 grid-margin stretch-card">
            <div
              className="card card-statistics"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body"
                style={{
                  height: "9rem",
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="dashboarder">
                  <div className="img">
                    <img
                      src={sentIcon}
                      alt="icon"
                      style={{ width: "5rem", marginRight: "1rem" }}
                    />
                  </div>

                  <div className="clearfix">
                    <div className="float-right">
                      <figure>
                        <blockquote className="blockquote">
                          <h1 className="display-6">
                            <small
                              className="text-body-secondary"
                              style={{ fontSize: "0.6em" }}
                            >
                              Files sent :{" "}
                            </small>
                            <span style={{ fontWeight: "500" }}>
                              {sentCount}
                            </span>
                          </h1>
                          <h1 className="display-6">
                            <small
                              className="text-body-secondary"
                              style={{ fontSize: "0.6em" }}
                            >
                              Folders sent :{" "}
                            </small>
                            <span style={{ fontWeight: "500" }}>
                              {sentCount2}
                            </span>
                          </h1>
                        </blockquote>
                        <figcaption className="blockquote-footer">
                          Number of Sent{" "}
                          <cite title="Source Title">Item(s)</cite>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Received Files Section */}
          <div className="col-md-4 grid-margin stretch-card">
            <div
              className="card card-statistics"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body"
                style={{
                  height: "9rem",
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="dashboarder">
                  <div className="img">
                    <img
                      src={recievedIcon}
                      alt="icon"
                      style={{ width: "5rem", marginRight: "1rem" }}
                    />
                  </div>

                  <div className="clearfix">
                    <div className="float-right">
                      <figure>
                        <blockquote className="blockquote">
                          <h1 className="display-6">
                            <small
                              className="text-body-secondary"
                              style={{ fontSize: "0.6em" }}
                            >
                              Files received :{" "}
                            </small>
                            <span style={{ fontWeight: "500" }}>
                              {receivedCount}
                            </span>
                          </h1>
                          <h1 className="display-6">
                            <small
                              className="text-body-secondary"
                              style={{ fontSize: "0.6em" }}
                            >
                              Folders received :{" "}
                            </small>
                            <span style={{ fontWeight: "500" }}>
                              {receivedCount2}
                            </span>
                          </h1>
                        </blockquote>
                        <figcaption className="blockquote-footer">
                          Number of Received{" "}
                          <cite title="Source Title">Item(s)</cite>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-lg-12">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="date-picker"
              maxDate={new Date()}
            />
          </div>
        </div>

        {/* Received Files for Today */}
        <div className="row">
          <div className="col-lg-12 mb-4 grid-margin">
            <div
              className="card"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body raga"
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="headrerCard">
                  <h4 className="card-title " style={{ fontWeight: "300" }}>
                    Received File(s) for {formattedDate}
                  </h4>
                  <div className="entries-selector">
                    <label htmlFor="entriesPerPageReceivedFiles">
                      Entries per page:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPageReceivedFiles"
                      value={entriesPerPageReceivedFiles}
                      onChange={(e) => {
                        setEntriesPerPageReceivedFiles(Number(e.target.value));
                        setCurrentPageReceivedFiles(1);
                      }}
                    >
                      {[3, 5, 10, 15, 20].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  className={`table-responsive ${
                    receivedTodayFiles.length === 0 ? "no-entries" : ""
                  }`}
                >
                  <table
                    id="datatable"
                    className={`table table-hover ${
                      receivedTodayFiles.length > 0 ? "table-with-data" : ""
                    }`}
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ID #</th>
                        <th>Sender</th>
                        <th>Title</th>
                        <th>File Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPaginatedData(
                        receivedTodayFiles,
                        currentPageReceivedFiles,
                        entriesPerPageReceivedFiles
                      ).map((file, index) => (
                        <tr key={index}>
                          <td>{file._id.slice(-8)}</td>
                          <td>
                            <div
                              className="togetherdpname2"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="Userprofilepic"
                                style={{ marginLeft: "0.5rem" }}
                              >
                                {loading5 ? (
                                  <div
                                    className="spinner-border text-info"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <img
                                    className="userprofilepIc"
                                    src={userProfilePics[file.user]}
                                    alt="profile"
                                    style={{ width: "3rem" }}
                                  />
                                )}
                              </div>
                              {getUserNameById(file.user)}
                            </div>
                          </td>
                          <td>
                            {file.filename.length <= 28
                              ? file.filename
                              : file.filename.substring(0, 26) + "..."}
                          </td>
                          <td>
                            <img
                              src={getFileIcon(file.filename)}
                              alt={file.filename.split(".").pop().toUpperCase()}
                              style={{ width: "2rem", height: "2rem" }}
                            />
                          </td>
                          <td>{getStatus(file) ? (
                              <img
                                src={seentrue}
                                alt=""
                                style={{ width: "3rem",marginRight:'0.5rem' }}
                              />
                            ) : (
                              <img
                                src={seenfalse}
                                alt=""
                                style={{ width: "3rem",marginRight:'0.5rem' }}
                              />
                            )}{getStatus(file)?'Seen':'Unseen'}</td>
                        </tr>
                      ))}

                      {getPaginatedData(
                        receivedTodayFiles,
                        currentPageReceivedFiles,
                        entriesPerPageReceivedFiles
                      ).length > 0 &&
                        getPaginatedData(
                          receivedTodayFiles,
                          currentPageReceivedFiles,
                          entriesPerPageReceivedFiles
                        ).length < entriesPerPageReceivedFiles &&
                        Array.from({
                          length:
                            entriesPerPageReceivedFiles -
                            getPaginatedData(
                              receivedTodayFiles,
                              currentPageReceivedFiles,
                              entriesPerPageReceivedFiles
                            ).length,
                        }).map((_, index) => (
                          <tr
                            key={`placeholder-${index}`}
                            className="table-placeholder"
                          >
                            <td colSpan="5">&nbsp;</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="pagination-container">
                    <ul className="pagination">
                      <li
                        className={
                          currentPageReceivedFiles === 1 ? "disabled" : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageReceivedFiles,
                              currentPageReceivedFiles,
                              receivedTodayFiles,
                              entriesPerPageReceivedFiles
                            )("prev");
                          }}
                        >
                          Prev
                        </a>
                      </li>
                      {getPaginationPages(
                        currentPageReceivedFiles,
                        totalPages(
                          receivedTodayFiles,
                          entriesPerPageReceivedFiles
                        )
                      ).map((page, index) => (
                        <li
                          key={index}
                          className={
                            currentPageReceivedFiles === page
                              ? "active pager"
                              : "pager"
                          }
                        >
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              if (page !== "...") {
                                setCurrentPageReceivedFiles(page);
                              }
                            }}
                          >
                            {page}
                          </a>
                        </li>
                      ))}
                      <li
                        className={
                          currentPageReceivedFiles ===
                          totalPages(
                            receivedTodayFiles,
                            entriesPerPageReceivedFiles
                          )
                            ? "disabled"
                            : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageReceivedFiles,
                              currentPageReceivedFiles,
                              receivedTodayFiles,
                              entriesPerPageReceivedFiles
                            )("next");
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Received Folders for Today */}
          <div className="col-lg-12 mb-4 grid-margin">
            <div
              className="card"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body raga"
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="headrerCard">
                  <h4 className="card-title " style={{ fontWeight: "300" }}>
                    Received Folder(s) for {formattedDate}
                  </h4>
                  <div className="entries-selector">
                    <label htmlFor="entriesPerPageReceivedFolders">
                      Entries per page:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPageReceivedFolders"
                      value={entriesPerPageReceivedFolders}
                      onChange={(e) => {
                        setEntriesPerPageReceivedFolders(
                          Number(e.target.value)
                        );
                        setCurrentPageReceivedFolders(1);
                      }}
                    >
                      {[3, 5, 10, 15, 20].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className={`table-responsive ${
                    receivedTodayFolders.length === 0 ? "no-entries" : ""
                  }`}
                >
                  <table
                    id="datatable"
                    className={`table table-hover ${
                      receivedTodayFolders.length > 0 ? "table-with-data" : ""
                    }`}
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ID #</th>
                        <th>Sender</th>
                        <th>Title</th>
                        <th>Folder Size</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPaginatedData(
                        receivedTodayFolders,
                        currentPageReceivedFolders,
                        entriesPerPageReceivedFolders
                      ).map((folder, index) => (
                        <tr key={index}>
                          <td>{folder._id.slice(-8)}</td>
                          <td>
                            <div
                              className="togetherdpname2"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="Userprofilepic"
                                style={{ marginLeft: "0.5rem" }}
                              >
                                {loading5 ? (
                                  <div
                                    className="spinner-border text-info"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <img
                                    className="userprofilepIc"
                                    src={userProfilePics[folder.user]}
                                    alt="profile"
                                    style={{ width: "3rem" }}
                                  />
                                )}
                              </div>
                              {getUserNameById(folder.user)}
                            </div>
                          </td>
                          <td>
                            {folder.name.length <= 28
                              ? folder.name
                              : folder.name.substring(0, 26) + "..."}
                          </td>
                          <td>
                            <img
                              src={getFolderIcon(folder.size)}
                              alt={folder.size}
                              style={{
                                width: "24px",
                                height: "24px",
                                marginRight: "1rem",
                              }}
                            />
                            <span>{getReadableSize(folder.size)}</span>
                          </td>
                          <td>
                            {getStatus(folder) ? (
                              <img
                                src={seentrue}
                                alt=""
                                style={{ width: "3rem",marginRight:'0.5rem' }}
                              />
                            ) : (
                              <img
                                src={seenfalse}
                                alt=""
                                style={{ width: "3rem",marginRight:'0.5rem' }}
                              />
                            )}
                            {getStatus(folder)?'Seen':'Unseen'}
                          </td>
                        </tr>
                      ))}

                      {getPaginatedData(
                        receivedTodayFolders,
                        currentPageReceivedFolders,
                        entriesPerPageReceivedFolders
                      ).length > 0 &&
                        getPaginatedData(
                          receivedTodayFolders,
                          currentPageReceivedFolders,
                          entriesPerPageReceivedFolders
                        ).length < entriesPerPageReceivedFolders &&
                        Array.from({
                          length:
                            entriesPerPageReceivedFolders -
                            getPaginatedData(
                              receivedTodayFolders,
                              currentPageReceivedFolders,
                              entriesPerPageReceivedFolders
                            ).length,
                        }).map((_, index) => (
                          <tr
                            key={`placeholder-${index}`}
                            className="table-placeholder"
                          >
                            <td colSpan="5">&nbsp;</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="pagination-container">
                    <ul className="pagination">
                      <li
                        className={
                          currentPageReceivedFolders === 1 ? "disabled" : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageReceivedFolders,
                              currentPageReceivedFolders,
                              receivedTodayFolders,
                              entriesPerPageReceivedFolders
                            )("prev");
                          }}
                        >
                          Prev
                        </a>
                      </li>
                      {getPaginationPages(
                        currentPageReceivedFolders,
                        totalPages(
                          receivedTodayFolders,
                          entriesPerPageReceivedFolders
                        )
                      ).map((page, index) => (
                        <li
                          key={index}
                          className={
                            currentPageReceivedFolders === page
                              ? "active pager"
                              : "pager"
                          }
                        >
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              if (page !== "...") {
                                setCurrentPageReceivedFolders(page);
                              }
                            }}
                          >
                            {page}
                          </a>
                        </li>
                      ))}
                      <li
                        className={
                          currentPageReceivedFolders ===
                          totalPages(
                            receivedTodayFolders,
                            entriesPerPageReceivedFolders
                          )
                            ? "disabled"
                            : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageReceivedFolders,
                              currentPageReceivedFolders,
                              receivedTodayFolders,
                              entriesPerPageReceivedFolders
                            )("next");
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sent Files for Today */}
        <div className="row">
          <div className="col-lg-12 mb-4 grid-margin">
            <div
              className="card"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body raga"
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="headrerCard">
                  <h4 className="card-title " style={{ fontWeight: "300" }}>
                    Sent File(s) for {formattedDate}
                  </h4>
                  <div className="entries-selector">
                    <label htmlFor="entriesPerPageSentFiles">
                      Entries per page:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPageSentFiles"
                      value={entriesPerPageSentFiles}
                      onChange={(e) => {
                        setEntriesPerPageSentFiles(Number(e.target.value));
                        setCurrentPageSentFiles(1);
                      }}
                    >
                      {[3, 5, 10, 15, 20].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className={`table-responsive ${
                    sentTodayFiles.length === 0 ? "no-entries" : ""
                  }`}
                >
                  <table
                    id="datatable"
                    className={`table table-hover ${
                      sentTodayFiles.length > 0 ? "table-with-data" : ""
                    }`}
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ID #</th>
                        <th>Recipient(s)</th>
                        <th>Title</th>
                        <th>File Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPaginatedData(
                        sentTodayFiles,
                        currentPageSentFiles,
                        entriesPerPageSentFiles
                      ).map((file, index) => (
                        <tr key={index}>
                          <td>{file._id.slice(-8)}</td>
                          <td>
                            <button
                              className="btn btn-outline-light tdButDash"
                              type="button"
                              onClick={() => handleCollapseToggle(file._id)}
                            >
                              View Recipients
                            </button>

                            <div
                              className={`collapse-content zroda ${
                                openTeam === file._id ? "open" : ""
                              }`}
                              ref={(el) =>
                                (collapseRefs.current[file._id] = el)
                              }
                              id={`collapseRequests-${file._id}`}
                              style={{
                                width: "100%",
                                borderRadius: "0.5rem",
                              }}
                            >
                              {file.sharedWith.map((share) => (
                                <span
                                  className="togetherdpname2"
                                  style={{ margin: "0.5rem 0rem" }}
                                  key={share._id}
                                >
                                  <span
                                    className="Userprofilepic"
                                    style={{ marginLeft: "0.5rem" }}
                                  >
                                    {loading5 ? (
                                      <div
                                        className="spinner-border text-info"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      <img
                                        className="userprofilepIc"
                                        src={userProfilePics[share.userId]}
                                        alt="profile"
                                        style={{ width: "3rem" }}
                                      />
                                    )}
                                  </span>
                                  <span style={{ width: "100%", display:'flex', justifyContent:'center' }}>
                                    {getUserNameById(share.userId)}
                                  </span>
                                </span>
                              ))}
                            </div>
                          </td>
                          <td>
                            {file.filename.length <= 28
                              ? file.filename
                              : file.filename.substring(0, 26) + "..."}
                          </td>
                          <td>
                            <img
                              src={getFileIcon(file.filename)}
                              alt={file.filename.split(".").pop().toUpperCase()}
                              style={{ width: "2rem", height: "2rem" }}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-light tdButDash"
                              type="button"
                              onClick={() => handleCollapseToggle(file._id)}
                            >
                              View Status
                            </button>

                            <div
                              className={`collapse-content zroda ${
                                openTeam === file._id ? "open" : ""
                              }`}
                              ref={(el) =>
                                (collapseRefs.current[file._id] = el)
                              }
                              id={`collapseRequests-${file._id}`}
                              style={{
                                width: "100%",
                                borderRadius: "0.5rem",
                              }}
                            >
                              {file.sharedWith.map((share) => (
                                <span
                                  className="togetherdpname2"
                                  style={{ margin: "0.5rem 0rem" }}
                                  key={share._id}
                                >
                                  {share.seenStatus ? (
                                    <img
                                      src={seentrue}
                                      alt=""
                                      style={{ width: "3rem" }}
                                    />
                                  ) : (
                                    <img
                                      src={seenfalse}
                                      alt=""
                                      style={{ width: "3rem" }}
                                    />
                                  )}

                                  <span style={{ width: "100%", display:'flex', justifyContent:'center'}}>
                                    {getStatus2(share)}
                                  </span>
                                </span>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}

                      {getPaginatedData(
                        sentTodayFiles,
                        currentPageSentFiles,
                        entriesPerPageSentFiles
                      ).length > 0 &&
                        getPaginatedData(
                          sentTodayFiles,
                          currentPageSentFiles,
                          entriesPerPageSentFiles
                        ).length < entriesPerPageSentFiles &&
                        Array.from({
                          length:
                            entriesPerPageSentFiles -
                            getPaginatedData(
                              sentTodayFiles,
                              currentPageSentFiles,
                              entriesPerPageSentFiles
                            ).length,
                        }).map((_, index) => (
                          <tr
                            key={`placeholder-${index}`}
                            className="table-placeholder"
                          >
                            <td colSpan="5">&nbsp;</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="pagination-container">
                    <ul className="pagination">
                      <li
                        className={currentPageSentFiles === 1 ? "disabled" : ""}
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageSentFiles,
                              currentPageSentFiles,
                              sentTodayFiles,
                              entriesPerPageSentFiles
                            )("prev");
                          }}
                        >
                          Prev
                        </a>
                      </li>
                      {getPaginationPages(
                        currentPageSentFiles,
                        totalPages(sentTodayFiles, entriesPerPageSentFiles)
                      ).map((page, index) => (
                        <li
                          key={index}
                          className={
                            currentPageSentFiles === page
                              ? "active pager"
                              : "pager"
                          }
                        >
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              if (page !== "...") {
                                setCurrentPageSentFiles(page);
                              }
                            }}
                          >
                            {page}
                          </a>
                        </li>
                      ))}
                      <li
                        className={
                          currentPageSentFiles ===
                          totalPages(sentTodayFiles, entriesPerPageSentFiles)
                            ? "disabled"
                            : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageSentFiles,
                              currentPageSentFiles,
                              sentTodayFiles,
                              entriesPerPageSentFiles
                            )("next");
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Sent Folders for Today */}
          <div className="col-lg-12 mb-4 grid-margin">
            <div
              className="card"
              style={{
                border: "none",
                color: "white",
                borderRadius: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 10px 15px, rgba(0, 0, 0, 0.25) 0px -12px 30px, rgb(0 0 0 / 25%) 0px 4px 6px, rgb(63 63 63 / 25%) 0px 12px 13px, rgb(255 255 255 / 25%) 0px -3px 12px",
                backgroundColor: "#5035a721",
              }}
            >
              <div
                className="card-body raga"
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="headrerCard">
                  <h4 className="card-title " style={{ fontWeight: "300" }}>
                    Sent Folder(s) for {formattedDate}
                  </h4>
                  <div className="entries-selector">
                    <label htmlFor="entriesPerPageSentFolders">
                      Entries per page:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPageSentFolders"
                      value={entriesPerPageSentFolders}
                      onChange={(e) => {
                        setEntriesPerPageSentFolders(Number(e.target.value));
                        setCurrentPageSentFolders(1);
                      }}
                    >
                      {[3, 5, 10, 15, 20].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className={`table-responsive ${
                    sentTodayFolders.length === 0 ? "no-entries" : ""
                  }`}
                >
                  <table
                    id="datatable"
                    className={`table table-hover ${
                      sentTodayFolders.length > 0 ? "table-with-data" : ""
                    }`}
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ID #</th>
                        <th>Recipient(s)</th>
                        <th>Title</th>
                        <th>Folder Size</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPaginatedData(
                        sentTodayFolders,
                        currentPageSentFolders,
                        entriesPerPageSentFolders
                      ).map((folder, index) => (
                        <tr key={index}>
                          <td>{folder._id.slice(-8)}</td>
                          <td>
                            <button
                              className="btn btn-outline-light tdButDash"
                              type="button"
                              onClick={() => handleCollapseToggle(folder._id)}
                            >
                              View Recipients
                            </button>

                            <div
                              className={`collapse-content zroda ${
                                openTeam === folder._id ? "open" : ""
                              }`}
                              ref={(el) =>
                                (collapseRefs.current[folder._id] = el)
                              }
                              id={`collapseRequests-${folder._id}`}
                              style={{
                                width: "100%",
                                borderRadius: "0.5rem",
                              }}
                            >
                              {folder.sharedWith.map((share) => (
                                <span
                                  className="togetherdpname2"
                                  style={{ margin: "0.5rem 0rem" }}
                                  key={share._id}
                                >
                                  <span
                                    className="Userprofilepic"
                                    style={{ marginLeft: "0.5rem" }}
                                  >
                                    {loading5 ? (
                                      <div
                                        className="spinner-border text-info"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      <img
                                        className="userprofilepIc"
                                        src={userProfilePics[share.userId]}
                                        alt="profile"
                                        style={{ width: "3rem" }}
                                      />
                                    )}
                                  </span>
                                  <span style={{ width: "100%", display:'flex', justifyContent:'center' }}>
                                    {getUserNameById(share.userId)}
                                  </span>
                                </span>
                              ))}
                            </div>
                          </td>
                          <td>
                            {folder.name.length <= 28
                              ? folder.name
                              : folder.name.substring(0, 26) + "..."}
                          </td>
                          <td>
                            <img
                              src={getFolderIcon(folder.size)}
                              alt={folder.size}
                              style={{
                                width: "24px",
                                height: "24px",
                                marginRight: "1rem",
                              }}
                            />
                            <span>{getReadableSize(folder.size)}</span>
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-light tdButDash"
                              type="button"
                              onClick={() => handleCollapseToggle(folder._id)}
                            >
                              View Status
                            </button>

                            <div
                              className={`collapse-content zroda ${
                                openTeam === folder._id ? "open" : ""
                              }`}
                              ref={(el) =>
                                (collapseRefs.current[folder._id] = el)
                              }
                              id={`collapseRequests-${folder._id}`}
                              style={{
                                width: "100%",
                                borderRadius: "0.5rem",
                              }}
                            >
                              {folder.sharedWith.map((share) => (
                                <span
                                  className="togetherdpname2"
                                  style={{ margin: "0.5rem 0rem" }}
                                  key={share._id}
                                >
                                  {share.seenStatus ? (
                                    <img
                                      src={seentrue}
                                      alt=""
                                      style={{ width: "3rem" }}
                                    />
                                  ) : (
                                    <img
                                      src={seenfalse}
                                      alt=""
                                      style={{ width: "3rem" }}
                                    />
                                  )}

                                  <span style={{ width: "100%", display:'flex', justifyContent:'center'}}>
                                    {getStatus2(share)}
                                  </span>
                                </span>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}

                      {getPaginatedData(
                        sentTodayFolders,
                        currentPageSentFolders,
                        entriesPerPageSentFolders
                      ).length > 0 &&
                        getPaginatedData(
                          sentTodayFolders,
                          currentPageSentFolders,
                          entriesPerPageSentFolders
                        ).length < entriesPerPageSentFolders &&
                        Array.from({
                          length:
                            entriesPerPageSentFolders -
                            getPaginatedData(
                              sentTodayFolders,
                              currentPageSentFolders,
                              entriesPerPageSentFolders
                            ).length,
                        }).map((_, index) => (
                          <tr
                            key={`placeholder-${index}`}
                            className="table-placeholder"
                          >
                            <td colSpan="5">&nbsp;</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="pagination-container">
                    <ul className="pagination">
                      <li
                        className={
                          currentPageSentFolders === 1 ? "disabled" : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageSentFolders,
                              currentPageSentFolders,
                              sentTodayFolders,
                              entriesPerPageSentFolders
                            )("prev");
                          }}
                        >
                          Prev
                        </a>
                      </li>
                      {getPaginationPages(
                        currentPageSentFolders,
                        totalPages(sentTodayFolders, entriesPerPageSentFolders)
                      ).map((page, index) => (
                        <li
                          key={index}
                          className={
                            currentPageSentFolders === page
                              ? "active pager"
                              : "pager"
                          }
                        >
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              if (page !== "...") {
                                setCurrentPageSentFolders(page);
                              }
                            }}
                          >
                            {page}
                          </a>
                        </li>
                      ))}
                      <li
                        className={
                          currentPageSentFolders ===
                          totalPages(
                            sentTodayFolders,
                            entriesPerPageSentFolders
                          )
                            ? "disabled"
                            : ""
                        }
                      >
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(
                              setCurrentPageSentFolders,
                              currentPageSentFolders,
                              sentTodayFolders,
                              entriesPerPageSentFolders
                            )("next");
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
