import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "../../App.css";

const ShareItemModal = ({
  item,
  setItem,
  itemType,
  onClose,
  users,
  currentUser,
  setIsShareModalOpen,
  loading4,
  setLoading4,
  showBanner,
  itemNote,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [permissions, setPermissions] = useState("view");
  const modalSharerRef = useRef(null);
  const bootstrapModal = useRef(null);
  const ws = useRef(null);
  // Handle showing the modal
  const showModal = () => {
    if (modalSharerRef.current) {
      bootstrapModal.current = new window.bootstrap.Modal(
        modalSharerRef.current
      );
      bootstrapModal.current.show();
      modalSharerRef.current.addEventListener("hide.bs.modal", handleModalHide);
    }
  };

  // Handle hiding the modal
  const hideModal = () => {
    item = null;
    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
      setIsShareModalOpen(false);
    }
  };

  const handleModalHide = () => {
    setItem(null); // Reset the file to rename
    // Remove the event listener to avoid memory leaks
    if (modalSharerRef.current) {
      modalSharerRef.current.removeEventListener(
        "hide.bs.modal",
        handleModalHide
      );
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (!ws.current) {
        ws.current = new WebSocket("wss://secure-storage-system-mern-cloud-file.onrender.com");

        ws.current.onopen = () => {
          if (currentUser && currentUser._id) {
            ws.current.send(
              JSON.stringify({
                type: "set-user-id",
                userId: currentUser._id,
              })
            );
          }
        };
        ws.current.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.current.onclose = () => {
          // console.log("WebSocket connection closed");
        };

        return () => {
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.close();
          }
        };
      }
    }
  }, [currentUser]);


  const handleShare = async (item) => {
    if (item && currentUser) {
      hideModal();
      setLoading4(true);
      try {
        const response = await axios.post(
          "https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/fastshare",
          {
            itemId: item._id,
            itemType,
            userIds: selectedUsers,
            permissions,
          },
          {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          }
        );

        if (response.status === 200) {
          showBanner(".banner.success",itemNote);
          onClose();

          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(
              JSON.stringify({
                type: "share-item",
                senderId: currentUser._id,
                userIds: selectedUsers,
                itemId: item._id,
                itemType,
              })
            );
          }
        } else {
          console.error("Sharing item failed");
          showBanner(".banner.info",itemNote);
        }
      } catch (error) {
        console.error("Error sharing item:", error);
      } finally {
        setLoading4(false); // End loading
      }
    } else {
      console.error("No item to share");
      showBanner(".banner.error",itemNote);
    }
  };

  const [blockedByUsers, setBlockedByUsers] = useState([]);
  useEffect(() => {
    const fetchBlockedByUsers = async () => {
      try {
        const response = await axios.get(
          "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/blocked-by",
          {
            headers: {
              "auth-token": localStorage.getItem("token"), // Add your auth token from local storage
            },
          }
        );

        if (response.status === 200) {
          setBlockedByUsers(response.data); // Set the array of user IDs
        }
      } catch (error) {
        console.error(
          "Error fetching users who have blocked the current user:",
          error
        );
      }
    };

    fetchBlockedByUsers();
  }, [blockedByUsers]);

  useEffect(() => {
    if (item) {
      showModal();
    }
    // eslint-disable-next-line
  }, [item]);

  return (
    <>

      {loading4 && <div className="loader"></div>}
      {item && (
        <div
          className="modal fade"
          id="shareItemModal"
          tabIndex="-1"
          aria-labelledby="shareItemModalLabel"
          aria-hidden="true"
          ref={modalSharerRef}
          style={{ backgroundColor: "#000000a8" }}
        >
          <div
            className="modal-dialog mobileModal"
            style={{ maxWidth: "100%", width: "100%" }}
          >
            <div
              className="modal-content modCon"
              style={{
                backgroundColor: "#0b0812f5",
                color: "white",
                height: "80%",
                width: "40%",
                borderRadius: "0.5rem",
                border: "1px solid #ccc0fe",
              }}
            >
              <div
                className="modal-header"
                style={{
                  borderBottom: "none",
                  paddingRight: "2rem",
                  backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
                }}
              >
                <h5 className="modal-title" id="shareItemModalLabel">
                  Share {itemType.charAt(0).toUpperCase() + itemType.slice(1)}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: "cyan" }}
                  aria-label="Close"
                  onClick={() => {
                    hideModal();
                    onClose();
                  }}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ color: "white", padding: "1.2rem", overflowY: "auto" }}
              >
                <div
                  className="nklwe"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      height: "3rem",
                      fontSize: "large",
                      margin: "0",
                      display: "flex",
                    }}
                  >
                    {itemType.charAt(0).toUpperCase() + itemType.slice(1)} Name:{" "}
                    <strong style={{ margin: "0rem 1rem" }}>
                      {item.filename || item.name}
                    </strong>
                  </p>
                  <div>
                    <label>Permissions:</label>
                    <select
                      value={permissions}
                      onChange={(e) => setPermissions(e.target.value)}
                      className="form-select"
                    >
                      <option value="view">View</option>
                      <option value="edit">Edit</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label>Users:</label>
                  {users.map((user) => {
                    const isBlocked = blockedByUsers.includes(user._id);
                    return (
                      <div
                        key={user._id}
                        className="form-check"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: "x-large",
                          color: "#050014",
                          padding: "1rem 2rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const checkbox = document.getElementById(
                            `user-${user._id}`
                          );
                          checkbox.checked = !checkbox.checked;
                          checkbox.dispatchEvent(
                            new Event("change", { bubbles: true })
                          );
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`user-${user._id}`}
                          value={user._id}
                          disabled={isBlocked}
                          onChange={(e) => {
                            const { checked, value } = e.target;
                            setSelectedUsers((prev) =>
                              checked
                                ? [...prev, value]
                                : prev.filter((id) => id !== value)
                            );
                          }}
                          style={{
                            height: "2.5rem",
                            width: "2.5rem",
                            borderRadius: "5rem",
                            marginRight: "1rem",
                            cursor: "pointer",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <label
                          className="form-check-labeler"
                          htmlFor={`user-${user._id}`}
                          style={{
                            color: "white",
                            height: "3rem",
                            fontSize: "large",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {user.name} ({user.email})
                          {isBlocked && (
                            <span style={{ color: "red" }}> - Blocked you</span>
                          )}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="aqua-button"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    hideModal();
                    onClose();
                  }}
                  style={{ padding: "0.5rem 2rem" }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="zerodha aqua-button"
                  onClick={() => handleShare(item)}
                  style={{ padding: "0.5rem 2rem" }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareItemModal;
