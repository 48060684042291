import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../App.css";
import ObjectId from "bson-objectid";
import trashicon from "../icons/trash2.png";
import gifer from "../icons/gifer.png";
import Picker from "@emoji-mart/react";
import GifPicker from "gif-picker-react";
import sentIcon from "../icons/sent.png";
import replyIcon from "../icons/reply.png";
import ownerIcon from "../icons/owner.png";
import adminIcon from "../icons/admin.png";
import { Tooltip } from "bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css"; // Import the CSS
const MemoizedEmojiPicker = React.memo(Picker);
const MemoizedGifPicker = React.memo(GifPicker);
const IndividualTeam = ({
  team,
  users,
  onClose,
  currentUser,
  setShowIndiTeam,
  setShowTeams,
  setLoading5,
  loading5,
}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [ShowChatGrp, setShowChatGrp] = useState(true);
  const [ShowQuestionSec, setShowQuestionSec] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(null);
  const [isInDeletion, setIsInDeletion] = useState(false);
  // const [hoveredQuestionId, setHoveredQuestionId] = useState(null);
  const messagesEndRef = useRef(null);
  const ws = useRef(null);
  const modalRefaddQues = useRef(null);
  const modalRefaddReply = useRef(null);
  const bootstrapModal = useRef(null);
  const emojiPickerRef = useRef(null);
  const GifPickerRef = useRef(null);
  const [bigChatOn, setBigChatOn] = useState(false);
  const bigChat = () => {
    setBigChatOn(true);
    const sidebar = document.querySelector(".SideBar");
    const fileFolderaccess = document.querySelector(".fileFolderaccess");
    const header = document.querySelector(".indiTeamHeaderX256");
    const indiTeam = document.querySelector(".individual-teamX256");
    const chatArea = document.querySelector(".chat-areaX256");
    const chatAreamsg = document.querySelector(".messagesX256");
    const chatAreafoot = document.querySelector(".charAreafoot");
    const mobileNav = document.querySelector(".navbar");
  
    const isMobile = window.innerWidth < 1600;
  
    if (isMobile) {
      // On mobile, hide the sidebar and show the navbar
      if (mobileNav) mobileNav.style.display = "none";
      if (fileFolderaccess) fileFolderaccess.classList.add("full-screen-chat");
      if (fileFolderaccess) fileFolderaccess.classList.add("full-screen-top");
      if (header) header.style.display = "none";
      if (chatArea) chatArea.classList.add("full-screen-chat");
      if (indiTeam) indiTeam.classList.add("full-screen-chat");
      if (chatAreamsg) chatAreamsg.style.height = "85%";
      if (chatAreafoot) chatAreafoot.style.height = "7%";
    } else {
      // On larger screens, hide the mobile navbar and expand the chat area
      if (sidebar) sidebar.style.display = "none";
      if (fileFolderaccess) fileFolderaccess.classList.add("full-screen-chat");
      if (header) header.style.display = "none";
      if (chatArea) chatArea.classList.add("full-screen-chat");
      if (indiTeam) indiTeam.classList.add("full-screen-chat");
      if (chatAreamsg) chatAreamsg.style.height = "85%";
      if (chatAreafoot) chatAreafoot.style.height = "7%";
      if (chatAreafoot) chatAreafoot.classList.add('margner');
    }
  };
  
  const bigChatRemove = () => {
    setBigChatOn(false);
    const sidebar = document.querySelector(".SideBar");
    const fileFolderaccess = document.querySelector(".fileFolderaccess");
    const header = document.querySelector(".indiTeamHeaderX256");
    const indiTeam = document.querySelector(".individual-teamX256");
    const chatArea = document.querySelector(".chat-areaX256");
    const chatAreamsg = document.querySelector(".messagesX256");
    const chatAreafoot = document.querySelector(".charAreafoot");
    const mobileNav = document.querySelector(".navbar");
  
    const isMobile = window.innerWidth < 1600;
  
    if (isMobile) {
      // On mobile, show the sidebar and hide the navbar
      if (mobileNav) mobileNav.style.display = "flex";
      if (fileFolderaccess) fileFolderaccess.classList.remove("full-screen-chat");
      if (fileFolderaccess) fileFolderaccess.classList.remove("full-screen-top");
      if (header) header.style.display = "flex";
      if (chatArea) chatArea.classList.remove("full-screen-chat");
      if (indiTeam) indiTeam.classList.remove("full-screen-chat");
      if (chatAreamsg) chatAreamsg.style.height = "80%";
      if (chatAreafoot) chatAreafoot.style.height = "10%";
    } else {
      // On larger screens, show the sidebar and revert the chat area
      if (sidebar) sidebar.style.display = "block";
      if (fileFolderaccess) fileFolderaccess.classList.remove("full-screen-chat");
      if (header) header.style.display = "flex";
      if (chatArea) chatArea.classList.remove("full-screen-chat");
      if (indiTeam) indiTeam.classList.remove("full-screen-chat");
      if (chatAreamsg) chatAreamsg.style.height = "80%";
      if (chatAreafoot) chatAreafoot.style.height = "10%";
      if (chatAreafoot) chatAreafoot.classList.remove('margner');
    }
  };
  
  const CodeHighlighterOp = React.memo(({ text }) => {
    // Code highlighting logic
    return (
      <div style={{ margin: "1rem 0rem" }}>
        <CodeHighlighter text={text} />
      </div>
    );
  });

  const ImageGalleryOp = React.memo(({ images }) => (
    <PhotoProvider>
      <div
        className="image-gallery"
        style={{
          display: "flex",
          gap: "10px",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          overflow: "hidden",
        }}
      >
        {images.map((image) => (
          <PhotoView key={image.id} src={image.url}>
            <img
              src={image.url}
              alt="Problem details"
              style={{
                cursor: "pointer",
                borderRadius: "5px",
                objectFit: "contain",
                overflow: "hidden",
              }}
            />
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  ));

  const languagePatterns = {
    javascript: /```javascript([\s\S]*?)```/g,
    python: /```python([\s\S]*?)```/g,
    java: /```java([\s\S]*?)```/g,
    c: /```c([\s\S]*?)```/g,
    cpp: /```cpp([\s\S]*?)```/g,
    bash: /```bash([\s\S]*?)```/g,
    html: /```html([\s\S]*?)```/g,
    sql: /```sql([\s\S]*?)```/g,
    "c#": /```c#([\s\S]*?)```/g,
  };

  // Function to detect and format code blocks
  const detectAndHighlightCode = (text) => {
    const codeBlocks = [];
    let formattedText = text;
    let lastIndex = 0;

    // Loop over each language pattern
    for (const [language, pattern] of Object.entries(languagePatterns)) {
      let match;
      while ((match = pattern.exec(text)) !== null) {
        const start = match.index;
        const end = pattern.lastIndex;

        // Avoid processing the same block more than once
        if (lastIndex < start) {
          // Add the matched code block to the list of code blocks
          codeBlocks.push({ language, code: match[1] });

          // Replace the code block in the formatted text with a placeholder
          formattedText = formattedText.replace(
            match[0],
            `<code-block-${codeBlocks.length - 1} />`
          );
          lastIndex = end;
        }
      }
    }

    return { formattedText, codeBlocks };
  };

  // Component to render highlighted code
  const CodeHighlighter = ({ text }) => {
    const { formattedText, codeBlocks } = detectAndHighlightCode(text);

    // Split formattedText by code block placeholders
    const parts = formattedText.split(
      /(<code-block-\d+ \/>)|(<\/code-block-\d+>)/
    );

    return (
      <div>
        {parts.map((part, index) => {
          if (typeof part === "string") {
            const match = part.match(/<code-block-(\d+) \/>/);

            if (match) {
              const blockIndex = parseInt(match[1], 10);
              const block = codeBlocks[blockIndex];

              return (
                <SyntaxHighlighter
                  key={index}
                  language={block.language}
                  style={oneDark}
                >
                  {block.code}
                </SyntaxHighlighter>
              );
            }

            return <span key={index}>{part}</span>;
          }

          // Handle unexpected cases
          return <span key={index}></span>;
        })}
      </div>
    );
  };

  useEffect(() => {
    if (!ws.current) {
      ws.current = new WebSocket("wss://secure-storage-system-mern-cloud-file.onrender.com");

      ws.current.onopen = () => {
        if (currentUser && currentUser._id) {
          ws.current.send(
            JSON.stringify({
              type: "set-user-id",
              userId: currentUser._id,
            })
          );
        }
      };

      ws.current.onmessage = async (event) => {
        try {
          const data = JSON.parse(event.data);

          // Handle new message
          if (data.type === "send-grpmessage") {
            const {
              senderId,
              teamId,
              message,
              timestamp,
              tempId,
              isGif,
              repliedToMessageId,
            } = data;

            if (team._id === teamId && currentUser._id !== senderId) {
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  _id: tempId,
                  teamId,
                  senderId,
                  message,
                  timestamp,
                  isGif,
                  repliedToMessageId,
                },
              ]);
            }
          }

          // Handle message deletion
          if (data.type === "delete-grpmessage") {
            const { msgId, teamId } = data;
            if (team._id === teamId) {
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg._id === msgId ? { ...msg, deleted: true } : msg
                )
              );
            }
          }

          if (data.type === "new-question") {
            const { teamer } = data;
            if (team._id === teamer._id) {
              const response = await axios.get(
                `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/questions/${teamer._id}`,
                {
                  headers: {
                    "auth-token": localStorage.getItem("token"),
                  },
                }
              );

              setQuestions(response.data.questions);
            }
          }

          if (data.type === "new-reply") {
            const { teamer, quesd } = data;
            if (team._id === teamer._id) {
              await fetchQuestionDetails(quesd);
            }
          }
        } catch (error) {
          console.error("Error processing WebSocket message:", error);
        }
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.current.onclose = () => {
        // Connection closed logic if needed
      };

      return () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.close();
        }
      };
    }
    // eslint-disable-next-line
  }, [currentUser, team, selectedQuestion]);

  const handleGrpChatClick = () => {
    setShowChatGrp(true);
    setShowQuestionSec(false);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };
  const handleQuesSecClick = () => {
    setShowQuestionSec(true);
    setShowChatGrp(false);
  };

  const [newQuestion, setNewQuestion] = useState({
    title: "",
    detailsOfProblem: "",
    whatDidYouTry: "",
    detailsOfProblemImages: [],
    whatDidYouTryImages: [],
  });

  // Function to get the username by ID
  const getUserNameById = (id) => {
    const user = users.find((user) => user._id === id);
    return user ? user.name : "Unknown";
  };

  // Fetch messages when the team changes
  useEffect(() => {
    if (!team || !team._id) return;

    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/getGrpMessages/${team._id}`,
          {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Failed to fetch messages", error);
      }
    };

    fetchMessages();
  }, [team]);

  // Fetch questions when the team changes
  useEffect(() => {
    if (!team || !team._id) return;

    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/questions/${team._id}`,
          {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        setQuestions(response.data.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [team]); // Depend on team, ensuring it’s defined before fetching

  // Function to send a new message

  const [replyingToMessage, setReplyingToMessage] = useState(null);

  const handleReply = (message) => {
    setReplyingToMessage(message);
  };
  const cancelReply = () => {
    setReplyingToMessage(null); // Reset reply state
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    const tempId = new ObjectId().toHexString();
    try {
      const response = await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/sendGrpMessage",
        {
          teamId: team._id,
          messageId: tempId,
          message: newMessage,
          isGif: false,
          repliedToMessageId: replyingToMessage ? replyingToMessage._id : null,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      ws.current.send(
        JSON.stringify({
          type: "send-grpmessage",
          senderId: currentUser._id,
          teamId: team._id,
          message: newMessage,
          timestamp: Date.now(),
          tempId,
          isGif: false,
          repliedToMessageId: replyingToMessage ? replyingToMessage._id : null,
        })
      );

      setMessages([...messages, { ...response.data.message, _id: tempId }]);
      setNewMessage("");
      setReplyingToMessage(null);
    } catch (error) {
      console.error("Failed to send message", error);
    }
  };

  // Function to send a new message
  const sendGifMessage = async (gifUrl) => {
    if (!gifUrl.trim()) return;
    const tempId = new ObjectId().toHexString();
    try {
      const response = await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/sendGrpMessage",
        {
          teamId: team._id,
          messageId: tempId,
          message: gifUrl,
          isGif: true,
          repliedToMessageId: replyingToMessage ? replyingToMessage._id : null,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      ws.current.send(
        JSON.stringify({
          type: "send-grpmessage",
          senderId: currentUser._id,
          teamId: team._id,
          message: gifUrl,
          timestamp: Date.now(),
          tempId,
          isGif: true,
          repliedToMessageId: replyingToMessage ? replyingToMessage._id : null,
        })
      );

      setMessages([...messages, { ...response.data.message, _id: tempId }]);
      setNewMessage("");
      setReplyingToMessage(null);
    } catch (error) {
      console.error("Failed to send message", error);
    }
  };

  // Function to delete a message
  const deleteMessage = async (messageId) => {
    try {
      // Instantly update the message state on the client side
      setIsInDeletion(true);
      console.log(messageId);
      // Call the server to delete the message
      await axios.delete("https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/deleteGrpMessage", {
        data: {
          teamId: team._id,
          messageId,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      ws.current.send(
        JSON.stringify({
          type: "delete-grpmessage",
          msgId: messageId,
          teamId: team._id,
        })
      );
    } catch (error) {
      console.error("Failed to delete message", error);
      // Optionally revert the state if the server request fails

      setMessages(
        messages.map((msg) =>
          msg._id === messageId ? { ...msg, deleted: false } : msg
        )
      );
    }
  };
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };
  useEffect(() => {
    if (!isInDeletion) {
      scrollToBottom();
    } else {
      setIsInDeletion(false);
    }
    // eslint-disable-next-line
  }, [messages]);

  const closer = () => {
    onClose();
    setShowIndiTeam(false);
    setShowTeams(true);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  // Fetch question details
  const fetchQuestionDetails = async (questionId) => {
    try {
      const response = await axios.get(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/question/${questionId}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      setQuestionDetails(response.data.question);
    } catch (error) {
      console.error("Error fetching question details:", error);
    }
  };

  const handleNewQuestionSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    Object.keys(newQuestion).forEach((key) => {
      if (!["detailsOfProblemImages", "whatDidYouTryImages"].includes(key)) {
        formData.append(key, newQuestion[key]);
      }
    });

    // Append multiple images for each section
    if (newQuestion.detailsOfProblemImages.length) {
      Array.from(newQuestion.detailsOfProblemImages).forEach((file) => {
        formData.append("detailsOfProblemImages", file);
      });
    }

    if (newQuestion.whatDidYouTryImages.length) {
      Array.from(newQuestion.whatDidYouTryImages).forEach((file) => {
        formData.append("whatDidYouTryImages", file);
      });
    }

    formData.append("teamId", team._id);

    try {
      await axios.post(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/askQuestionWithImage",
        formData,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Refresh questions after submitting
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            type: "new-question",
            team: team,
          })
        );
      }
      const response = await axios.get(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/questions/${team._id}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      setQuestions(response.data.questions);
      setNewQuestion({
        title: "",
        detailsOfProblem: "",
        whatDidYouTry: "",
        detailsOfProblemImages: [],
        whatDidYouTryImages: [],
      });
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  const [replyText, setReplyText] = useState({
    replyDetail: "",
    replyImages: [],
  });

  const handleReplySubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("replyText", replyText.replyDetail);

    // Append multiple images
    if (replyText.replyImages.length) {
      Array.from(replyText.replyImages).forEach((file) => {
        formData.append("replyImages", file);
      });
    }

    try {
      await axios.post(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/replyToQuestionWithImage/${selectedQuestion}`,
        formData,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Handle success
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            type: "new-reply",
            team,
            selectedQuestion,
          })
        );
      }
      // Refresh question details or handle the UI update
      await fetchQuestionDetails(selectedQuestion);
      setReplyText({
        replyDetail: "",
        replyImages: [],
      });
    } catch (error) {
      console.error("Error submitting reply:", error);
    }
  };

  const handleVote = async (type, id, voteType) => {
    try {
      const response = await axios.post(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/vote/${type}/${id}`,
        { voteType },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      // Update the question or reply based on the response
      if (type === "question") {
        // Update the questionDetails state after voting
        setQuestionDetails((prevDetails) => ({
          ...prevDetails,
          upvotes: response.data.item.upvotes,
          downvotes: response.data.item.downvotes,
        }));

        // Also update the questions list if needed
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question._id === id
              ? {
                  ...question,
                  upvotes: response.data.item.upvotes,
                  downvotes: response.data.item.downvotes,
                }
              : question
          )
        );
      } else if (type === "reply") {
        // Update the replies inside questionDetails for reply votes
        setQuestionDetails((prevDetails) => ({
          ...prevDetails,
          replies: prevDetails.replies.map((reply) =>
            reply._id === id
              ? {
                  ...reply,
                  upvotes: response.data.item.upvotes,
                  downvotes: response.data.item.downvotes,
                }
              : reply
          ),
        }));
      }
    } catch (error) {
      console.error("Failed to vote", error);
    }
  };

  const handleQuestionClick = (questionId) => {
    if (selectedQuestion === questionId) {
      // If the clicked question is already selected, clear selection
      setSelectedQuestion(null);
    } else {
      // Otherwise, set the selected question and fetch details
      setSelectedQuestion(questionId);
      fetchQuestionDetails(questionId);
    }
  };
  const handleQuestionBack = (questionId) => {
    if (selectedQuestion === questionId) {
      setSelectedQuestion(null);
    }
  };
  const selectionRef = useRef(null);
  const dropdownRef = useRef(null);
  const mousePositionRef = useRef({ x: 0, y: 0 });
  const handleMouseDown = (msgId, event) => {
    console.log(msgId);
    mousePositionRef.current = { x: event.clientX, y: event.clientY };
    selectionRef.current = setTimeout(() => {
      setShowDeleteButton((prev) => (prev === msgId ? null : msgId));
    }, 500);
  };
  const handleMouseUp = () => {
    // Clear the timeout if the mouse is released before 500ms
    if (selectionRef.current) {
      clearTimeout(selectionRef.current);
    }
    setTimeout(() => {
      selectionRef.current = null;
    }, 500);
  };

  const handleDeleteClick = (msgId, event) => {
    event.stopPropagation(); // Prevent dropdown from reopening
    deleteMessage(msgId); // Custom delete logic
    setShowDeleteButton(null); // Close the dropdown
  };

  const handleReplyClick = (msg, event) => {
    event.stopPropagation(); // Prevent dropdown from reopening
    handleReply(msg); // Custom reply logic
    setShowDeleteButton(null); // Close the dropdown
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDeleteButton(null);
      }
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        closeEmojiPicker();
      }
      if (
        GifPickerRef.current &&
        !GifPickerRef.current.contains(event.target)
      ) {
        closeGifPicker();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiData, setEmojiData] = useState(null);
  const [showGifPicker, setShowGifPicker] = useState(false);
  useEffect(() => {
    const fetchEmojiData = async () => {
      const response = await fetch(
        "https://cdn.jsdelivr.net/npm/@emoji-mart/data"
      );
      const data = await response.json();
      setEmojiData(data);
    };

    fetchEmojiData();
  }, []);

  const handleEmojiClick = (emoji) => {
    setNewMessage((prevMessage) => prevMessage + emoji.native);
  };

  const toggleEmojiPicker = () => {
    setShowGifPicker(false);
    setShowEmojiPicker((prev) => !prev);
  };
  const closeEmojiPicker = () => {
    setShowEmojiPicker(false);
  };
  const closeGifPicker = () => {
    setShowGifPicker(false);
  };

  const handleGifSelect = (gif) => {
    sendGifMessage(gif.url);
  };

  const toggleGifPicker = () => {
    setShowEmojiPicker(false);
    setShowGifPicker((prev) => !prev);
  };

  const showModal = (type) => {
    if (type === "addQues") {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      tooltipTriggerList.forEach(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );
      if (modalRefaddQues.current) {
        bootstrapModal.current = new window.bootstrap.Modal(
          modalRefaddQues.current
        );
        bootstrapModal.current.show();
      }
    } else if (type === "addRep") {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      tooltipTriggerList.forEach(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );
      if (modalRefaddReply.current) {
        bootstrapModal.current = new window.bootstrap.Modal(
          modalRefaddReply.current
        );
        bootstrapModal.current.show();
      }
    }
  };
  const hideModal = () => {
    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
    }
  };

  const handleFileChange = (e, fieldName) => {
    const files = Array.from(e.target.files);
    if (files.length > 4) {
      alert("You can upload a maximum of 4 images.");
      return;
    }

    setNewQuestion((prevState) => ({
      ...prevState,
      [fieldName]: files,
    }));
  };

  const handleReplyChange = (e, fieldName) => {
    const files = Array.from(e.target.files);
    if (files.length > 4) {
      alert("You can upload a maximum of 4 images.");
      return;
    }

    setReplyText((prevState) => ({
      ...prevState,
      [fieldName]: files,
    }));
  };

  const typingTimeoutRef = useRef(null);
  const handleDebouncedChange = (e) => {
    const { value } = e.target;
    // Clear the previous timeout if the user is still typing
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timeout to update the reply text after 0.5 seconds
    typingTimeoutRef.current = setTimeout(() => {
      setReplyText((prev) => ({
        ...prev,
        replyDetail: value,
      }));
    }, 200); // 0.5 seconds debounce time
  };

  const [userProfilePics, setUserProfilePics] = useState({}); // Store all user profile pics

  const fetchUserProfilePic = async (userId) => {
    try {
      setLoading5(true);
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/profilePic/${userId}`
      );
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setUserProfilePics((prevPics) => ({
          ...prevPics,
          [userId]: imageObjectURL,
        }));
      } else {
        console.error("Failed to fetch profile pic");
      }
      setLoading5(false);
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      setLoading5(false);
    }
  };

  // Fetch profile pictures for all users
  useEffect(() => {
    users.forEach((user) => {
      if (!userProfilePics[user._id]) {
        fetchUserProfilePic(user._id);
      }
    });
    // eslint-disable-next-line
  }, [users]); // Re-run this effect when `users` array changes

  useEffect(() => {
    if (currentUser && !userProfilePics[currentUser._id]) {
      fetchUserProfilePic(currentUser._id);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const isWithinOneMinute = (prevTimestamp, currentTimestamp) => {
    return new Date(currentTimestamp) - new Date(prevTimestamp) <= 60000; // 1 minute = 60000 milliseconds
  };

  const getReplyingToName = (msgId) => {
    const msg = messages.find((msg) => msg._id === msgId);
    return {
      msgId: msg.senderId,
      msgContent: msg.message,
      senderId: msg.senderId,
      isGif: msg.isGif,
    };
  };

  const isOnlyEmojis = (text) => {
    const emojiRegex =
      /^(?:[\uD83C-\uDBFF\uDC00-\uDFFF]|[\u2600-\u27BF]|[\u1F300-\u1F6FF]|[\u1F900-\u1F9FF]|[\u1FA70-\u1FAFF]|\u200D)+$/u;
    return emojiRegex.test(text) && !/[a-zA-Z0-9]/.test(text); // Ensure no letters or numbers are present
  };

  const getMessageFontSize = (text) => {
    return isOnlyEmojis(text) ? `2.5rem` : `1rem`;
  };

  if (!team) return null;

  return (
    <div className="individual-teamX256">
      <div className="indiTeamHeaderX256">
        <div
          className="together d-flex justify-content-flex-start align-items-center"
          style={{ width: "70%" }}
        >
          <button
            style={{
              marginRight: "1rem",
              boxShadow: "none",
              border: "none",
              background: "transparent",
            }}
            onClick={closer}
          >
            <i
              className="fa-solid fa-arrow-left"
              style={{ color: "#cebffd", fontSize: "xx-large" }}
            ></i>
          </button>
          <h2 className="display-6">{team.name}</h2>
        </div>

        <div className="sharedselector" style={{ width: "30%" }}>
          <button
            className="shselectbutton"
            style={{
              backgroundColor: ShowChatGrp ? "aqua" : "white",
              borderRadius: "2rem 0rem 0rem 2rem",
              width: "50%",
            }}
            onClick={handleGrpChatClick}
          >
            ChatBox
          </button>
          <button
            className="shselectbutton"
            style={{
              backgroundColor: ShowQuestionSec ? "aqua" : "white",
              borderRadius: "0rem 2rem 2rem 0rem",
              width: "50%",
            }}
            onClick={handleQuesSecClick}
          >
            Questions
          </button>
        </div>
      </div>

      {ShowChatGrp && (
        <div className="chat-areaX256">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4
              className="display-6"
              style={{
                padding: "0rem 1rem",
                margin: "1rem 0rem",
                fontSize: "x-large",
              }}
            >
              Group Chat
            </h4>
            <div className="bigger">
              {bigChatOn ? (
                <button
                  className="btn btn-info" style={{boxShadow:'none', border:'none',background:'transparent'}}
                  onClick={() => bigChatRemove()}
                >
                  {" "}
                  <i className="fa-solid fa-minimize chatCollapser"></i>
                </button>
              ) : (
                <button className="btn btn-info" onClick={() => bigChat()} style={{boxShadow:'none', border:'none',background:'transparent'}}>
                  {" "}
                  <i className="fa-solid fa-expand chatExpander"></i>
                </button>
              )}
            </div>
          </div>

          <div
            style={{ padding: "1rem 1rem", transition: "all 0.2s ease-in-out" }}
            className="messagesX256"
            ref={selectionRef}
          >
            {messages.map((msg, index) => {
              const prevMessage = index > 0 ? messages[index - 1] : null;

              const isDifferentSender =
                !prevMessage || prevMessage.senderId !== msg.senderId;
              const isTimeGap =
                !prevMessage ||
                !isWithinOneMinute(prevMessage.timestamp, msg.timestamp);

              const showProfilePic = isDifferentSender || isTimeGap;

              return (
                <div
                  className="message-content d-flex justify-content-start"
                  key={msg._id}
                  style={{
                    width: "100%",
                    marginTop:
                      isDifferentSender || isTimeGap
                        ? "2.5rem"
                        : msg.deleted
                        ? "0.5rem"
                        : "0rem",
                    transition: "all 0.2s ease-in-out",
                    cursor: "pointer",
                    alignItems: msg.isGif ? "" : "center",
                  }}
                  onMouseDown={(e) =>
                    !msg.deleted && handleMouseDown(msg._id, e)
                  }
                  onMouseUp={handleMouseUp}
                  onTouchStart={(e) =>
                    !msg.deleted && handleMouseDown(msg._id, e)
                  }
                  onTouchEnd={handleMouseUp}
                >
                  {showProfilePic ? (
                    <div className="Userprofilepic">
                      {loading5 ? (
                        <div className="spinner-border text-info" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <img
                          className="userprofilepIcnonemp"
                          src={userProfilePics[msg.senderId]}
                          alt="profile"
                          style={{ width: "4rem" }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="Userprofilepic">
                      <div
                        className="userprofilepIcemp"
                        style={{ width: "4rem" }}
                      />
                    </div>
                  )}
                  <div
                    className="messageX256"
                    style={{
                      cursor: "pointer",
                      backgroundColor: msg.deleted
                        ? "#d3d3d3"
                        : msg.repliedToMessageId && !msg.deleted
                        ? getReplyingToName(msg.repliedToMessageId).senderId ===
                          currentUser._id
                          ? "#444038"
                          : "transparent"
                        : "transparent",
                    }}
                  >
                    {msg.repliedToMessageId && !msg.deleted && (
                      <div className="replied-message">
                        <small>
                          <span style={{ color: "aqua" }}>
                            @
                            {getReplyingToName(msg.repliedToMessageId).msgId ===
                            currentUser._id
                              ? currentUser.name
                              : getUserNameById(
                                  getReplyingToName(msg.repliedToMessageId)
                                    .msgId
                                )}
                          </span>{" "}
                          <span style={{ color: "white" }}>
                            {getReplyingToName(msg.repliedToMessageId).isGif ? (
                              <i style={{ color: "yellow" }}>gif attachment</i>
                            ) : getReplyingToName(msg.repliedToMessageId)
                                .msgContent.length > 30 ? (
                              getReplyingToName(
                                msg.repliedToMessageId
                              ).msgContent.slice(0, 30) + "..."
                            ) : (
                              getReplyingToName(msg.repliedToMessageId)
                                .msgContent
                            )}
                          </span>
                        </small>
                      </div>
                    )}
                    {showProfilePic && (
                      <div className="msg-head d-flex justify-content-between align-items-center">
                        <strong
                          style={{
                            color:
                              team.leader === msg.senderId
                                ? "Red"
                                : msg.deleted
                                ? "black"
                                : "white",
                            fontWeight:
                              team.leader === msg.senderId ? "500" : "500",
                            fontSize:
                              team.leader === msg.senderId ? "1.1rem" : "1rem",
                          }}
                        >
                          {msg.senderId === currentUser._id
                            ? `${currentUser.name} (You)`
                            : getUserNameById(msg.senderId)}
                          <span>
                            {team.leader === msg.senderId ? (
                              <img
                                className="roleIconGrp"
                                src={ownerIcon}
                                alt=""
                                style={{ width: "2rem", marginLeft: "0.5rem" }}
                              />
                            ) : team.members.some(
                                (m) => m.user === msg.senderId && m.isAdmin
                              ) ? (
                              <img
                                className="roleIconGrp"
                                src={adminIcon}
                                alt=""
                                style={{ width: "2rem", marginLeft: "0.5rem" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </strong>
                        <div style={{ paddingLeft: "1rem", color: "white" }}>
                          {formatTimestamp(msg.timestamp)}
                        </div>
                      </div>
                    )}

                    {msg.deleted ? (
                      <div>has deleted this message</div>
                    ) : msg.isGif ? (
                      <div className="giferX246">
                        <img
                          src={msg.message}
                          alt="GIF"
                          className="gifGrpmessage"
                          onLoad={() => scrollToBottom()}
                        />
                      </div>
                    ) : (
                      <div className="grpmsgbody" style={{ color: "white" }}>
                        <p
                          style={{
                            margin: "0",
                            fontSize: getMessageFontSize(msg.message),
                          }}
                        >
                          {msg.message}{" "}
                        </p>
                      </div>
                    )}
                    {showDeleteButton === msg._id && (
                      <ul
                        className="dropdown-menu grpChat show"
                        ref={dropdownRef}
                        style={{
                          top: `${mousePositionRef.current.y}px`, // Use ref for positioning
                          left: `${mousePositionRef.current.x}px`,
                        }}
                      >
                        {!msg.deleted &&
                          (msg.senderId === currentUser._id ||
                            team.leader === currentUser._id ||
                            team.members.some(
                              (m) => m.user === currentUser._id && m.isAdmin
                            )) && (
                            <>
                              <li
                                className="dropdown-item"
                                onClick={(event) => {
                                  handleDeleteClick(msg._id, event);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                }}
                              >
                                <label className="iconic">
                                  <img
                                    src={trashicon}
                                    alt="delete"
                                    style={{
                                      width: "1.5rem",
                                      marginRight: "8px",
                                    }}
                                  />
                                  Delete message
                                </label>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                            </>
                          )}

                        <li
                          className="dropdown-item"
                          onClick={(event) => {
                            handleReplyClick(msg, event);
                          }}
                          style={{ cursor: "pointer", color: "white" }}
                        >
                          <label className="iconic">
                            <img
                              src={replyIcon}
                              alt="reply"
                              style={{
                                width: "20px",
                                marginRight: "8px",
                              }}
                            />
                            Reply to this message
                          </label>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>

          <div className="charAreafoot" style={{ position: "relative" }}>
            {replyingToMessage && (
              <div className="replying-to-message">
                <strong>Replying to {replyingToMessage.senderName}:</strong>
                <p>{replyingToMessage.message.slice(0, 50)}...</p>
                <button
                  onClick={cancelReply}
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    border: "none",
                    color: "red",
                  }}
                >
                  Cancel Reply
                </button>
              </div>
            )}
            <button
              onClick={toggleEmojiPicker}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <span role="img" aria-label="emoji">
                <i
                  className="fa-regular fa-face-smile"
                  style={{ color: "#ffffff", fontSize: "2.6rem" }}
                ></i>
              </span>
            </button>
            {showEmojiPicker && emojiData && (
              <div className="emojiPickerContainer" ref={emojiPickerRef}>
                <MemoizedEmojiPicker onEmojiSelect={handleEmojiClick} />
              </div>
            )}
            <button
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={toggleGifPicker}
            >
              <img src={gifer} alt="gif" style={{ width: "3rem" }} />
            </button>
            {showGifPicker && (
              <div className="gifPickerContainer" ref={GifPickerRef}>
                <MemoizedGifPicker
                  tenorApiKey={"AIzaSyB5oXqUW5nKyMwA4zg2K9s76IgAtcndrns"}
                  onGifClick={handleGifSelect}
                />
              </div>
            )}

            <textarea
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
              rows="3"
              style={{
                width: "80%",
                resize: "none",
                padding: "1rem",
                borderRadius: "5rem",
                outline: "none",
                overflow: "hidden",
                caretColor: "red",
                fontSize: "x-large",
              }}
            ></textarea>
            <button
              className="sendmsg"
              onClick={sendMessage}
              style={{
                width: "12%",
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            >
              <img src={sentIcon} alt="Send" style={{ width: "4rem" }} />
            </button>
          </div>
        </div>
      )}
      {ShowQuestionSec && (
        <div className="questions-areaX256">
          {!selectedQuestion && (
            <>
              <div
                className="quesHeader"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <h4 className="display-6" style={{ fontSize: "xx-large" }}>
                  Questions
                </h4>
                <button
                  className="btn btn-outline-light"
                  onClick={() => showModal("addQues")}
                >
                  Add New Question
                </button>
              </div>

              <div className="questions-listX256">
                {questions.map((question) => (
                  <div
                    key={question._id}
                    className="question-itemX256"
                    onClick={() => handleQuestionClick(question._id)}
                  >
                    <div
                      className="headerQues"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <small
                        style={{
                          padding: "0rem 1rem",
                          fontWeight: "400",
                          fontSize: "1rem",
                        }}
                      >
                        Upvotes : {question.upvotes.length}
                      </small>
                      <small
                        style={{
                          padding: "0rem 1rem",
                          fontWeight: "400",
                          fontSize: "1rem",
                        }}
                      >
                        Downvotes : {question.downvotes.length}
                      </small>
                    </div>

                    <h5
                      className="display-6"
                      style={{
                        fontSize: "x-large",
                      }}
                    >
                      {question.title}
                    </h5>
                    <p
                      className="display-6"
                      style={{
                        fontSize: "large",
                      }}
                    >
                      {question.detailsOfProblem.substring(0, 100)}...
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}

          <div
            className="modal fade"
            id="addQuesModal"
            tabIndex="-1"
            aria-labelledby="addQuesModalLabel"
            aria-hidden="true"
            ref={modalRefaddQues}
            style={{ backgroundColor: "#000000a8" }}
          >
            <div
              className="modal-dialog"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div
                className="modal-content"
                style={{
                  backgroundColor: "#0b0812f5",
                  color: "white",
                  height: "95%",
                  width: "90%",
                  borderRadius: "0.5rem",
                  border: "1px solid #ccc0fe",
                }}
              >
                <div
                  className="modal-header"
                  style={{
                    borderBottom: "none",
                    paddingRight: "2rem",
                    backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
                  }}
                >
                  <div
                    className="heading"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "flex-start",
                    }}
                  >
                    <h5 className="display-6" style={{ fontSize: "2.3rem" }}>
                      Add your question
                    </h5>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    style={{ backgroundColor: "cyan" }}
                    aria-label="Close"
                    onClick={hideModal}
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{
                    color: "white",
                    padding: "1.2rem",
                    overflowY: "auto",
                  }}
                >
                  <form
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                    onSubmit={handleNewQuestionSubmit}
                  >
                    <div className="mb-3">
                      <label htmlFor="quesTitleId" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="quesTitleId"
                        aria-describedby="quesTitleHelp"
                        value={newQuestion.title}
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                        onChange={(e) =>
                          setNewQuestion({
                            ...newQuestion,
                            title: e.target.value,
                          })
                        }
                        required
                      />
                      <div id="quesTitleHelp" className="form-text">
                        Be specific and imagine you’re asking a question to
                        another person.
                      </div>
                    </div>
                    <div className="mb-3" style={{ height: "30rem" }}>
                      <label htmlFor="quesDetail" className="form-label">
                        Details of the problem
                      </label>
                      <textarea
                        className="form-control"
                        id="quesDetail"
                        aria-describedby="quesDetailHelp"
                        value={newQuestion.detailsOfProblem}
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                          height: "65%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-html="true"
                        data-bs-title="For writing code snippets enclose your code like this <strong>```(your coding language name) your code```</strong> without brackets"
                        onChange={(e) =>
                          setNewQuestion({
                            ...newQuestion,
                            detailsOfProblem: e.target.value,
                          })
                        }
                        required
                      />
                      <div id="quesDetailHelp" className="form-text">
                        Introduce the problem and expand on what you put in the
                        title.
                      </div>
                    </div>

                    <div className="input-group mb-3">
                      <input
                        className="form-control"
                        id="detailsOfProblemFileInput"
                        type="file"
                        multiple
                        accept="image/jpeg, image/png"
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                        onChange={(e) =>
                          handleFileChange(e, "detailsOfProblemImages")
                        }
                      />
                      <label
                        className="input-group-text"
                        htmlFor="detailsOfProblemFileInput"
                      >
                        Upload
                      </label>
                    </div>

                    <div className="mb-3" style={{ height: "30rem" }}>
                      <label htmlFor="quesTry" className="form-label">
                        What did you try / What were you expecting?
                      </label>
                      <textarea
                        className="form-control"
                        id="quesTry"
                        aria-describedby="quesTryHelp"
                        value={newQuestion.whatDidYouTry}
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                          height: "65%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-html="true"
                        data-bs-title="For writing code snippets enclose your code like this <strong>```(your coding language name) your code```</strong> without brackets"
                        onChange={(e) =>
                          setNewQuestion({
                            ...newQuestion,
                            whatDidYouTry: e.target.value,
                          })
                        }
                        required
                      />
                      <div id="quesTryHelp" className="form-text">
                        Describe what you tried, what you expected to happen,
                        and what actually resulted.
                      </div>
                    </div>

                    <div className="input-group mb-3">
                      <input
                        className="form-control"
                        id="whatDidYouTryFileInput"
                        type="file"
                        multiple
                        accept="image/jpeg, image/png"
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                        onChange={(e) =>
                          handleFileChange(e, "whatDidYouTryImages")
                        }
                      />
                      <label
                        className="input-group-text"
                        htmlFor="whatDidYouTryFileInput"
                      >
                        Upload
                      </label>
                    </div>
                    <div
                      className="formbut"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="teamModalButton aqua-button"
                        type="button"
                        onClick={hideModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="teamModalButton aqua-button"
                        type="submit"
                        onClick={hideModal}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {selectedQuestion && questionDetails && (
            <div className="question-detailsX256">
              <div
                className="quesdethead"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <div
                  className="backerHead"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => handleQuestionBack(questionDetails._id)}
                    style={{
                      boxShadow: "none",
                      border: "none",
                      backgroundColor: "transparent",
                      textAlign: "center",
                    }}
                  >
                    <i
                      className="fa-solid fa-arrow-left"
                      style={{ color: "white", fontSize: "1.6rem" }}
                    ></i>
                  </button>
                  <h4
                    className="display-6"
                    style={{
                      fontSize: "1.7rem",
                      paddingLeft: "1rem",
                      marginBottom: "0",
                    }}
                  >
                    {questionDetails.title}
                  </h4>
                </div>
                <div className="togetherdpname2">
                  <strong>
                    Asked by{" "}
                    {questionDetails.user === currentUser._id
                      ? `you`
                      : getUserNameById(questionDetails.user)}
                  </strong>
                  <div
                    className="Userprofilepic"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    {loading5 ? (
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <img
                        className="userprofilepIc"
                        src={userProfilePics[questionDetails.user]}
                        alt="profile"
                        style={{ width: "3rem" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="questiontriang"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85%",
                }}
              >
                <div
                  className="vote-buttons"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "10%",
                    marginRight: "2rem",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <button
                    style={{
                      height: "3rem",
                      width: "3rem",
                      borderRadius: "5rem",
                      margin: "0.2rem 0rem",
                      boxShadow: "none",
                      border: "none",
                    }}
                    onClick={() =>
                      handleVote("question", questionDetails._id, "upvote")
                    }
                    className={`vote-btn ${
                      questionDetails.upvotes.includes(currentUser._id)
                        ? "voted"
                        : ""
                    }`}
                  >
                    <i
                      className="fa-solid fa-caret-up"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0.5rem 0rem",
                      fontWeight: "500",
                      fontSize: "x-large",
                    }}
                  >
                    {questionDetails.upvotes.length -
                      questionDetails.downvotes.length}
                  </div>
                  <button
                    style={{
                      height: "3rem",
                      width: "3rem",
                      borderRadius: "5rem",
                      margin: "0.2rem 0rem",
                      boxShadow: "none",
                      border: "none",
                    }}
                    onClick={() =>
                      handleVote("question", questionDetails._id, "downvote")
                    }
                    className={`vote-btn ${
                      questionDetails.downvotes.includes(currentUser._id)
                        ? "voted"
                        : ""
                    }`}
                  >
                    <i
                      className="fa-solid fa-caret-down"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </button>
                </div>
                <div className="question-textX256">
                  <CodeHighlighterOp text={questionDetails.detailsOfProblem} />

                  <ImageGalleryOp
                    images={questionDetails.detailsOfProblemImages}
                  />

                  <CodeHighlighterOp text={questionDetails.whatDidYouTry} />
                  <ImageGalleryOp
                    images={questionDetails.whatDidYouTryImages}
                  />
                </div>
              </div>

              <div className="repliesX256">
                <h5 className="display-6" style={{ fontSize: "1.7rem" }}>
                  Replies
                </h5>
                {questionDetails.replies &&
                  questionDetails.replies.map((reply) => (
                    <div className="replyer" key={reply._id}>
                      <div
                        className="headerQues"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        <div className="togetherdpname2">
                          <div
                            className="Userprofilepic"
                            style={{ marginLeft: "0.5rem" }}
                          >
                            {loading5 ? (
                              <div
                                className="spinner-border text-info"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <img
                                className="userprofilepIc"
                                src={userProfilePics[reply.user._id]}
                                alt="profile"
                                style={{ width: "3rem" }}
                              />
                            )}
                          </div>
                          <h1
                            className="display-6"
                            style={{ fontSize: "large", fontWeight: "400" }}
                          >
                            {reply.user._id === currentUser._id
                              ? `You`
                              : getUserNameById(reply.user._id)}{" "}
                            replied:
                          </h1>
                        </div>
                      </div>

                      <div
                        className="replytriang"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "85%",
                        }}
                      >
                        <div
                          className="vote-buttons"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "10%",
                            marginRight: "2rem",
                            alignItems: "center",
                            paddingTop: "2rem",
                          }}
                        >
                          <button
                            style={{
                              height: "3rem",
                              width: "3rem",
                              borderRadius: "5rem",
                              margin: "0.2rem 0rem",
                              boxShadow: "none",
                              border: "none",
                            }}
                            onClick={() =>
                              handleVote("reply", reply._id, "upvote")
                            }
                            className={`vote-btn ${
                              questionDetails.upvotes.includes(currentUser._id)
                                ? "voted"
                                : ""
                            }`}
                          >
                            <i
                              className="fa-solid fa-caret-up"
                              style={{ fontSize: "1.8rem" }}
                            ></i>
                          </button>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: "0.5rem 0rem",
                              fontWeight: "500",
                              fontSize: "x-large",
                            }}
                          >
                            {reply.upvotes.length - reply.downvotes.length}
                          </div>
                          <button
                            style={{
                              height: "3rem",
                              width: "3rem",
                              borderRadius: "5rem",
                              margin: "0.2rem 0rem",
                              boxShadow: "none",
                              border: "none",
                            }}
                            onClick={() =>
                              handleVote("reply", reply._id, "downvote")
                            }
                            className={`vote-btn ${
                              questionDetails.downvotes.includes(
                                currentUser._id
                              )
                                ? "voted"
                                : ""
                            }`}
                          >
                            <i
                              className="fa-solid fa-caret-down"
                              style={{ fontSize: "1.8rem" }}
                            ></i>
                          </button>
                        </div>
                        <div className="reply-textX256">
                          <CodeHighlighterOp text={reply.replyText} />

                          <ImageGalleryOp images={reply.replyImages} />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* Button to show reply form */}
              <div
                className="buttner"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-info"
                  onClick={() => showModal("addRep")}
                >
                  Add Reply
                </button>
              </div>

              {/* Reply Form Modal */}
              <div
                className="modal fade"
                id="addRepModal"
                tabIndex="-1"
                aria-labelledby="addRepModalLabel"
                aria-hidden="true"
                ref={modalRefaddReply}
                style={{ backgroundColor: "#000000a8" }}
              >
                <div
                  className="modal-dialog"
                  style={{ maxWidth: "100%", width: "100%" }}
                >
                  <div
                    className="modal-content"
                    style={{
                      backgroundColor: "#0b0812f5",
                      color: "white",
                      height: "60%",
                      width: "40%",
                      borderRadius: "0.5rem",
                      border: "1px solid #ccc0fe",
                    }}
                  >
                    <div
                      className="modal-header"
                      style={{
                        borderBottom: "none",
                        paddingRight: "2rem",
                        backgroundImage: `url("https://www.transparenttextures.com/patterns/xv.png")`,
                      }}
                    >
                      <div
                        className="heading"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          alignItems: "flex-start",
                        }}
                      >
                        <h5
                          className="display-6"
                          style={{ fontSize: "2.3rem" }}
                        >
                          Add Reply
                        </h5>
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        style={{ backgroundColor: "cyan" }}
                        aria-label="Close"
                        onClick={() => hideModal()}
                      ></button>
                    </div>
                    <div
                      className="modal-body"
                      style={{
                        color: "white",
                        padding: "1.2rem",
                        overflowY: "auto",
                      }}
                    >
                      <form
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                        onSubmit={handleReplySubmit}
                      >
                        <div className="mb-3">
                          <label htmlFor="replyDetail" className="form-label">
                            Title
                          </label>
                          <textarea
                            className="form-control"
                            id="replyDetail"
                            placeholder="Write your reply here"
                            aria-describedby="replyDetailHelp"
                            data-bs-toggle="tooltip"
                            data-bs-custom-class="custom-tooltip"
                            data-bs-html="true"
                            data-bs-title="For writing code snippets enclose your code like this <strong>```(your coding language name) your code```</strong> without brackets"
                            onChange={handleDebouncedChange}
                            style={{
                              backgroundColor: "transparent",
                              color: "white",
                              height: "65%",
                            }}
                            required
                          />
                          <div id="replyDetailHelp" className="form-text">
                            Reply the problem and expand on what you think might
                            be the solution.
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            className="form-control"
                            id="detailsOfReplyFileInput"
                            type="file"
                            multiple
                            accept="image/jpeg, image/png"
                            style={{
                              backgroundColor: "transparent",
                              color: "white",
                            }}
                            onChange={(e) =>
                              handleReplyChange(e, "replyImages")
                            }
                          />
                          <label
                            className="input-group-text"
                            htmlFor="detailsOfProblemFileInput"
                          >
                            Upload
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "1rem 0rem",
                          }}
                        >
                          <button
                            className="teamModalButton aqua-button"
                            type="button"
                            onClick={() => hideModal()}
                          >
                            Cancel
                          </button>
                          <button
                            className="teamModalButton aqua-button"
                            type="submit"
                            onClick={() => hideModal()}
                          >
                            Submit Reply
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default IndividualTeam;
