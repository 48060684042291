import React, { useContext,useEffect } from "react";
import NewButtonDropdown from "./NewbuttonDropdown";
import GreatContext from "../context/GreatContext";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import logo from "../icons/synchronexaLogo.png";
const Sidebar = () => {
  const SideConst = useContext(GreatContext);
  const {
    isFolder,
    setIsFolder,
    handleFileChange,
    handleUpload,
    folderName,
    setFolderName,
    handleCreateFolder,
    currentUser
  } = SideConst;

  const history = useHistory();
  const handlelogOut = (e) => {
    e.preventDefault();
    if (localStorage.getItem("isRef3Clicked")) {
      localStorage.removeItem("isRef3Clicked");
    }
    if (localStorage.getItem("token")) {
      localStorage.removeItem("isRef3Clicked");
      localStorage.removeItem("token");
      localStorage.setItem("state", "logout");
      history.push("/");
    }
  };
  const handleNavigation = (path) => {
    history.push(path);
  };
  useEffect(() => {
    // Check if the script is already added
    if (!document.querySelector('script[src="https://checkout.razorpay.com/v1/payment-button.js"]')) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.setAttribute("data-payment_button_id", "pl_P1r2jcL2Aku1CI");
      script.async = true;
  
      // Append script inside the form after it mounts
      const formElement = document.getElementById("razorpay-payment-form");
      formElement.appendChild(script);
    }
  }, [currentUser]);
  
  return (
    <>
      <div
        className="SideBar"
        style={{ width: "15%", padding: "0", margin: "0" }}
      >
        <img
          src={logo}
          alt="Synchronexa logo"
          style={{ width: "16rem", padding: "1rem", marginBottom: "1rem" }}
        />
        <div className="row flex-nowrap" style={{ height: "100%" }}>
          <div
            className="col-auto col-md-3 col-xl-2 px-sm-2 px-0"
            style={{
              width: "100%",
              height: "80%",
              border: "2px solid white",
              position: "relative",
              borderRadius: "0rem 10rem 10rem 0rem",
            }}
          >
            <div
              className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100"
              style={{ position: "absolute", top: "3rem" }}
            >
              <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                id="menu"
              >
                <li
                  className="nav-item2"
                  style={{ paddingLeft: "0.2rem" }}
                  id="meca208"
                >
                  <NewButtonDropdown
                    isFolder={isFolder}
                    setIsFolder={setIsFolder}
                    handleFileChange={handleFileChange}
                    handleUpload={handleUpload}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    handleCreateFolder={handleCreateFolder}
                    inMobile={false}
                  />
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavigation("/home/dashboard")}
                >
                  <i className="fs-4 bi-folder"></i>
                  <Link to="/home/dashboard">My DashBoard</Link>
                </li>
                <li
                  style={{
                    height: "1px",
                    backgroundColor: "#464646",
                    width: "100%",
                  }}
                >
                  <hr className="dropdown-divider" />
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavigation("/home")}
                >
                  <i className="fs-4 bi-folder"></i>
                  <Link to="/home">My Drive</Link>
                </li>
                <li
                  style={{
                    height: "1px",
                    backgroundColor: "#464646",
                    width: "100%",
                  }}
                >
                  <hr className="dropdown-divider" />
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavigation("/home/sharedVault")}
                >
                  <i className="fs-4 bi-file-earmark"></i>
                  <Link to="/home/sharedVault">Shared With me</Link>
                </li>
                <li
                  style={{
                    height: "1px",
                    backgroundColor: "#464646",
                    width: "100%",
                  }}
                >
                  <hr className="dropdown-divider" />
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavigation("/home/chatbox")}
                >
                  <i className="fs-4 bi-file-earmark"></i>
                  <Link to="/home/chatbox">My Chats</Link>
                </li>
                <li
                  style={{
                    height: "1px",
                    backgroundColor: "#464646",
                    width: "100%",
                  }}
                >
                  <hr className="dropdown-divider" />
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavigation("/home/teams")}
                >
                  <i className="fs-4 bi-file-earmark"></i>
                  <Link to="/home/teams">Teams</Link>
                </li>
                <li
                  style={{
                    height: "1px",
                    backgroundColor: "#464646",
                    width: "100%",
                  }}
                >
                  <hr className="dropdown-divider" />
                </li>
                <li className="nav-item" onClick={handlelogOut}>
                  <i className="fs-4 bi-file-earmark"></i>
                  LogOut
                </li>
                <li
                  style={{
                    height: "1px",
                    backgroundColor: "#464646",
                    width: "100%",
                  }}
                >
                  <hr className="dropdown-divider" />
                </li>
                <li style={{padding:'1rem 1rem 1rem 0rem'}}>
                  <form id="razorpay-payment-form" style={{color:'black'}}></form> 
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
