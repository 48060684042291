import React, { useState, useEffect } from "react";
import GreatContext from "./GreatContext";
import { format } from "date-fns";
import { useFileContext } from "../Components/traverse";
import axios from "axios";
const GreatState = (props) => {
  const { selectedFiles, setSelectedFiles, traverseFileTree } =
    useFileContext();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isFolder, setIsFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [fileToMove, setFileToMove] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [fileToRename, setFileToRename] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [folderToRename, setFolderToRename] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [navigationHistory2, setNavigationHistory2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [zippingProgress, setZippingProgress] = useState(0);
  const [fetchingProgress, setFetchingProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isZipping, setIsZipping] = useState(false);
  const [isFetching, setIsFetching] = useState(false);



  const [isDownloading, setIsDownloading] = useState(false); // Track if zipping is ongoing
  const [downloadType, setDownloadType] = useState(null); // 'file' or 'folder'
  const [sharedItems, setSharedItems] = useState({ files: [], folders: [] });
  // eslint-disable-next-line
  const [breadcrumb, setBreadcrumb] = useState([
    { name: "Home", link: "/folders" },
  ]);
  const [breadcrumb2, setBreadcrumb2] = useState([
    { name: "Home", link: "/folders" },
  ]);

  useEffect(() => {
    const ws = new WebSocket("wss://secure-storage-system-mern-cloud-file.onrender.com");

    ws.onopen = () => {
      // console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "download-progress") {
        if (data.downloadType === "folder") {
          setDownloadType("folder");
          if (data.status === "fetching") {
            setIsZipping(false); // Ensure zipping state is false during fetching
            setIsFetching(true); // Set fetching state
            setFetchingProgress(data.progress);
          } else if (data.status === "zipping") {
            setIsFetching(false); // Fetching complete, start zipping
            setIsZipping(true);
            setZippingProgress(data.progress);
          } else if (data.status === "zipping-complete") {
            setIsZipping(false); // Zipping complete, start downloading
            setIsDownloading(true);
            setDownloadProgress(0);
          } else if (data.status === "downloading" && !isZipping) {
            setDownloadProgress(data.progress);
          }
        } else if (data.downloadType === "file") {
          setDownloadType("file");
          setIsZipping(false);
          setIsDownloading(true);
          if (data.status === "downloading") {
            setDownloadProgress(data.progress);
          }
        } 
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      // console.log("WebSocket connection closed");
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
    // eslint-disable-next-line
  }, []);

  // 1. Handle File Change -------------------------------------------------------------------------------------------------
  const handleFileChange = async (event) => {
    const items = event.target.files;
    const files = await traverseFileTree(items);
    setSelectedFiles(files);

    if (isFolder) {
      const folder = items[0].webkitRelativePath.split("/")[0];
      setFolderName(folder);
    }
  };

  // 2. Handle Upload -------------------------------------------------------------------------------------------------
  const handleUpload = async (event) => {
    event.preventDefault();

    setLoading3(true); // Start loading
    setLoading4(true);
    setUploadProgress(0); // Reset progress

    try {
      if (isFolder) {
        // Upload folder structure
        const folderStructure = buildFolderStructure(selectedFiles);
        await uploadFolderWithProgress(folderStructure); // Updated to track progress
      } else {
        // Upload individual files
        await uploadFilesWithProgress(selectedFiles); // Updated to track progress
      }
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setLoading3(false); // End loading
      setLoading4(false);
    }
  };

  //3. Folder Structure -------------------------------------------------------------------------------------------------
  const buildFolderStructure = (fileList, parentId = null) => {
    const rootFolder = {
      name: "root",
      path: "",
      parentId: parentId,
      subfolders: {},
      files: [],
    };

    fileList.forEach((file) => {
      const pathSegments = file.webkitRelativePath.split("/");
      let currentFolder = rootFolder;

      for (let i = 0; i < pathSegments.length; i++) {
        const segment = pathSegments[i];
        if (i === pathSegments.length - 1) {
          currentFolder.files.push(file);
        } else {
          if (!currentFolder.subfolders[segment]) {
            currentFolder.subfolders[segment] = {
              name: segment,
              path: pathSegments.slice(0, i + 1).join("/"),
              parentId: currentFolder.parentId,
              subfolders: {},
              files: [],
            };
          }
          currentFolder = currentFolder.subfolders[segment];
        }
      }
    });

    return convertToArray(rootFolder);
  };

  const convertToArray = (folder) => {
    return {
      name: folder.name,
      path: folder.path,
      parentId: folder.parentId,
      subfolders: Object.values(folder.subfolders).map((subfolder) =>
        convertToArray(subfolder)
      ),
      files: folder.files.map((file) => ({
        name: file.name,
        path: file.webkitRelativePath, // Ensure full path is included
      })),
    };
  };

  //6. Handle create folder -------------------------------------------------------------------------------------------------
  const handleCreateFolder = async () => {
    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/createFolder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            name: folderName,
            parentFolderId: currentFolder,
          }),
        }
      );

      if (response.ok) {
        const newFolder = await response.json(); // Get the newly created folder from the response
        console.log("Folder created successfully:", newFolder);
        setFolders((prevFolders) => [...prevFolders, newFolder]); // Update the state with the new folder
        setFolderName(""); // Reset the folder name input
      } else {
        console.error("Folder creation failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //7. Fetch Folders -------------------------------------------------------------------------------------------------
  const fetchFolders = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/fetchFolders",
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"), // Use environment variable
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.folders && data.files) {
          setFolders(data.folders);
          setFiles(data.files);
        } else {
          console.error("Unexpected response structure:", data);
        }
      } else {
        console.error("Fetching folders failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
    
    }finally{
      setLoading(false);
    } 
  };

  //8. Fetch folder contents -------------------------------------------------------------------------------------------------
  const fetchFolderContents = async (folderId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/folder/${folderId}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCurrentFolder(folderId);
        setFiles(data.files);
        setFolders(data.folders);
      } else {
        console.error("Fetching folder contents failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  //9. Navigation back -------------------------------------------------------------------------------------------------
  const handleBack = async () => {
    setLoading(true);
    const newHistory = [...navigationHistory];
    const previousFolder = newHistory.pop();
    if (previousFolder) {
      await fetchFolderContents(previousFolder);
      setNavigationHistory(newHistory);

      // Update breadcrumb
      setBreadcrumb((prevBreadcrumb) => {
        const updatedBreadcrumb = prevBreadcrumb.slice(0, -1); // Remove last breadcrumb
        return updatedBreadcrumb;
      });
    } else {
      await fetchFolders();
      setCurrentFolder(null);
      setNavigationHistory([]);
      setBreadcrumb([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    }
    setLoading(false);
  };
  const handleBack2 = async () => {
    setLoading(true);
    const newHistory = [...navigationHistory2];
    const previousFolder = newHistory.pop();
    if (previousFolder && newHistory.length!==0) {
      const folderContents = await fetchSharedFolderContents(previousFolder);
      if (folderContents) {
        setSharedItems({
          files: folderContents.files, // Assuming folderContents contains files
          folders: folderContents.folders, // Assuming folderContents contains folders
        });
      }
      setNavigationHistory2(newHistory);

      // Update breadcrumb
      setBreadcrumb2((prevBreadcrumb) => {
        const updatedBreadcrumb = prevBreadcrumb.slice(0, -1); // Remove last breadcrumb
        return updatedBreadcrumb;
      });
    } else {
      await fetchSharedItems();
      setCurrentFolder(null);
      setNavigationHistory2([]);
      setBreadcrumb2([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    }
    setLoading(false);
  };

  //10. Handle File download -------------------------------------------------------------------------------------------------
  const handleDownload = async (fileId, fileName) => {
    setLoading2(true);
    setDownloadProgress(0);
    setIsDownloading(true);
    try {
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/downloadFile/${fileId}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const contentLength = response.headers.get("Content-Length");
        const total = parseInt(contentLength, 10);
        if (isNaN(total) || total <= 0) {
          console.error("Invalid or missing Content-Length:", contentLength);
          return;
        }
        let loaded = 0;

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            function push() {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  loaded += value.length;
                  const progress = (loaded / total) * 100;
                  setDownloadProgress(Math.floor(progress)); // Update progress

                  controller.enqueue(value);
                  push(); // Continue reading
                })
                .catch((error) => {
                  console.error("Stream read error:", error);
                  controller.error(error);
                });
            }
            push();
          },
        });

        const blob = await new Response(stream).blob(); // Create a blob from the stream
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error("Download failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading2(false);
      setDownloadType(null);
    }
  };

  //Download Multiple files

  const handleDownloadMultipleFiles = async (fileIds) => {
    setLoading2(true); // Show loading spinner or progress indicator
    setZippingProgress(0);
    setDownloadProgress(0);
    setIsZipping(true); // Set zipping to true initially
    setIsDownloading(false); // Reset downloading state
    setDownloadProgress("files");

    try {
      const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/downloadFiles`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ fileIds }),
      });

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const contentLength = response.headers.get("Content-Length");
      const totalBytes = contentLength ? +contentLength : 0;

      const reader = response.body.getReader();
      let receivedLength = 0; // Keep track of how much data is received

      const progressStream = new ReadableStream({
        start(controller) {
          function push() {
            reader
              .read()
              .then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                receivedLength += value.length;
                const progress = totalBytes
                  ? Math.floor((receivedLength / totalBytes) * 100)
                  : Math.floor(
                      (receivedLength / (receivedLength + 100000)) * 100
                    );
                setDownloadProgress(progress);
                controller.enqueue(value);
                push(); // Continue reading
              })
              .catch((error) => {
                console.error("Stream read error:", error);
                controller.error(error);
              });
          }
          push();
        },
      });

      const blob = await new Response(progressStream).blob(); // Create a blob from the stream
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "yourDownloadedFiles | byteHaven.zip"; // Default filename for multiple files
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl); // Clean up the URL object
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading2(false);
      setDownloadType(null);
    }
  };

  //11. Handle folder Download -------------------------------------------------------------------------------------------------
  const handleFolderDownload = async (folderId, folderName) => {
    try {
      setLoading2(true); // Show loading spinner or progress indicator
      // Reset progress states
      setZippingProgress(0);
      setDownloadProgress(0);
      setIsZipping(true); // Set zipping to true initially
      setIsDownloading(false); // Reset downloading state
      setDownloadProgress("folder");
      console.log(folderId);
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/downloadFolder/${folderId}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const totalBytes = response.headers.has("Content-Length")
        ? +response.headers.get("Content-Length")
        : 0;

      const reader = response.body.getReader();
      let receivedLength = 0; // Keep track of how much data is received

      const stream = new ReadableStream({
        start(controller) {
          function push() {
            reader
              .read()
              .then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                receivedLength += value.length;
                if (totalBytes) {
                  setDownloadProgress(
                    Math.floor((receivedLength / totalBytes) * 100)
                  );
                } else {
                  // Handle cases where Content-Length is not available
                  setDownloadProgress(
                    Math.floor(
                      (receivedLength / (receivedLength + 100000)) * 100
                    )
                  );
                }
                controller.enqueue(value);
                push(); // Continue reading
              })
              .catch((error) => {
                console.error("Stream read error:", error);
                controller.error(error);
              });
          }
          push();
        },
      });
      const blob = await new Response(stream).blob(); // Create a blob from the stream
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${folderName}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading2(false);
      setDownloadType(null);
    }
  };

  //12. Handle file deletion -------------------------------------------------------------------------------------------------
  const handleDeleteFile = async (fileIds) => {
    setLoading4(true);
    try {
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/deleteFile`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ fileIds }), // Send the array of file IDs
        }
      );

      if (response.ok) {
        setDeletionSuccess(true);
        console.log("Files deleted successfully");
        // Update state to remove deleted files
        setFiles((prevFiles) =>
          prevFiles.filter((file) => !fileIds.includes(file._id))
        );
        
      } else {
        console.error("File deletion failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading4(false);
    }
  };
  const [deletionSuccess,setDeletionSuccess]=useState(true)
  //13. Handle folder deletion -------------------------------------------------------------------------------------------------
  const handleDeleteFolder = async (folderId) => {
    try {
      setLoading4(true);
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/deleteFolder/${folderId}`,
        {
          method: "DELETE",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        console.log("Folder deleted successfully");
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => folder._id !== folderId)
        );
        setDeletionSuccess(true);

      } else {
        console.error("Folder deletion failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading4(false);
    }
  };

  //14. File move into other folder -------------------------------------------------------------------------------------------------
  const handleMoveClick = (file) => {
    setFileToMove(file);
    setShowDropdown(true);
  };

  //15. Handle select folder -------------------------------------------------------------------------------------------------
  const handleSelectFolder = (event) => {
    setSelectedFolder(event.target.value);
  };

  //16. Handle move files -------------------------------------------------------------------------------------------------
  const handleMoveFiles = async () => {
    if (!selectedFolder) {
      console.error("No folder selected");
      return;
    }

    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/move",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            itemId: fileToMove._id,
            newParentId: selectedFolder,
            isFile: true,
          }),
        }
      );

      if (response.ok) {
        console.log("File moved successfully");
        setFileToMove(null);
        setSelectedFolder("");
        setShowDropdown(false);
        fetchFolders();
      } else {
        console.error("Move file failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //17. Handle rename of file button -------------------------------------------------------------------------------------------------
  const handleRenameClick = (file) => {
    setFileToRename(file);
    setNewFileName(file.filename);
  };

  //18. Handle rename of file -------------------------------------------------------------------------------------------------
  const handleRenameFile = async () => {
    if (!fileToRename || !newFileName) {
      console.error("No file selected or new name is empty");
      return;
    }

    try {
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/renameFile/${fileToRename._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ newName: newFileName }),
        }
      );

      if (response.ok) {
        console.log("File renamed successfully");
        setFileToRename(null);
        setNewFileName("");
        if (currentFolder) {
          fetchFolderContents(currentFolder);
        } else {
          fetchFolders();
        }
      } else {
        console.error("Rename file failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //19. Handle rename of folder button -------------------------------------------------------------------------------------------------
  const handleRenameClick2 = (folder) => {
    setFolderToRename(folder);
    setNewFolderName(folder.foldername);
  };

  //20. Handle rename of folder -------------------------------------------------------------------------------------------------

  const handleRenameFolder = async () => {
    if (!folderToRename || !newFolderName) {
      console.error("No file selected or new name is empty");
      return;
    }

    try {
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/renameFolder/${folderToRename._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ newName: newFolderName }),
        }
      );

      if (response.ok) {
        const updatedFolder = await response.json(); // Get the updated folder from the response
        console.log("Folder renamed successfully:", updatedFolder);

        // Update the state with the renamed folder
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder._id === updatedFolder._id ? updatedFolder : folder
          )
        );

        // Reset state variables
        setFolderToRename(null);
        setNewFolderName("");
      } else {
        console.error("Rename folder failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //21. Reset naviagtion history

  const resetNavigationHistory = () => {
    setNavigationHistory([]);
  };
  const resetNavigationHistory2 = () => {
    setNavigationHistory2([]);
  };

  //23. Upload Progress Calculator

  const uploadFolderWithProgress = (folderStructure) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/uploadFolder",
        true
      );

      xhr.setRequestHeader("auth-token", localStorage.getItem("token"));

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress); // Update progress
          setUploadType('folder');
        }
      };

      xhr.onload = async () => {
        if (xhr.status === 200) {
          try {
            const result = JSON.parse(xhr.responseText);
            if (result.success) {
              const { parentFolder } = result;
              setFolders((prevFolders) => [...prevFolders, parentFolder]);
              // setSelectedFiles([]);
              resolve(result);
            } else {
              console.error("Failed to upload folder", result.message);
              reject(new Error(result.message));
            }
          } catch (e) {
            console.error("Failed to parse response:", e);
            reject(new Error("Failed to parse response"));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            console.error("Upload failed:", errorData);
            reject(new Error(errorData.message || "Upload failed"));
          } catch (e) {
            console.error("Failed to parse error response:", e);
            reject(new Error("Upload failed"));
          }
        }
      };

      xhr.onerror = () => {
        reject(new Error("Upload error"));
      };

      const formData = new FormData();

      // Append files
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });

      // Append folder structure
      formData.append("folderStructure", JSON.stringify(folderStructure));

      // Build fileFolderPaths for mapping
      const fileFolderPaths = selectedFiles.reduce((acc, file) => {
        const parentPath = file.webkitRelativePath.substring(
          0,
          file.webkitRelativePath.lastIndexOf("/")
        );
        acc[file.webkitRelativePath] = parentPath;
        return acc;
      }, {});

      formData.append("fileFolderPaths", JSON.stringify(fileFolderPaths));

      xhr.send(formData);
      setUploadType(null);
    });
  };

  const uploadFilesWithProgress = (files) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/uploadFiles",
        true
      );

      xhr.setRequestHeader("auth-token", localStorage.getItem("token"));

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress); // Update progress
          setUploadType('file');
        }
      };

      xhr.onload = async () => {
        if (xhr.status === 200) {
          console.log("Upload successful");
          const response = JSON.parse(xhr.responseText);

          if (Array.isArray(response.files)) {
            const newFiles = response.files;
            setSelectedFiles([]);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            resolve(xhr.response);
          } else {
            console.error("Response does not contain 'files' key");
            reject(new Error("Invalid response format"));
          }
        } else {
          const errorData = JSON.parse(xhr.responseText);
          console.error("Upload failed:", errorData);
          reject(new Error("Upload failed"));
        }
      };

      xhr.onerror = () => {
        reject(new Error("Upload error"));
      };

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name || files[i].fullPath);
      }
      xhr.send(formData);
      setUploadType(null);
    });
  };

  //24. docx ppt excel file opener

  const handleViewFile = (fileId) => {
    const authUrl = `https://secure-storage-system-mern-cloud-file.onrender.com/api/googleAPI/auth/google?fileId=${fileId}`;
    const width = 610;
    const height = 700;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      authUrl,
      "authPopup",
      `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars`
    );
  };

  //view pdf inside
  const [fileURL, setFileURL] = useState("");
  const handleViewPdf = (fileId) => {
    setLoading(true);
    setFileURL(`https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/viewPdf/${fileId}`);
    setLoading(false);
  };

  // view pdf outside
  const handleViewPdf2 = async (fileId) => {
    setLoading(true);
    try {
      // Construct the URL for the PDF view endpoint
      const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/viewPdf2/${fileId}`;

      // Fetch the PDF with the auth token
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // Open the PDF in a new tab if the response is successful
        const pdfBlob = await response.blob(); // Convert response to blob
        const pdfUrl = URL.createObjectURL(pdfBlob); // Create a URL for the blob
        window.open(pdfUrl, "_blank"); // Open the URL in a new tab
      } else {
        console.error("Failed to fetch PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error opening PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const [ImageUrl, setImageUrl] = useState("");
  const [ViewerVisible, setViewerVisible] = useState("");
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Fetch and set all images from the directory
  const [imageCache, setImageCache] = useState(new Map());

  const handleViewImage = async (fileId, parentFolderId) => {
    setImages([]);
    setLoading(true);
    try {
      // Fetch the image list from the parent folder
      const imageList = await fetchImagesInDirectory(parentFolderId);

      // Find the index of the current fileId in the image list
      const currentIndex = imageList.findIndex((img) => img._id === fileId);

      // Function to fetch image URL and cache it
      const getImageUrl = async (img) => {
        if (imageCache.has(img._id)) {
          return { src: imageCache.get(img._id), ...img };
        }

        const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/viewImage/${img._id}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        });

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageCache((prevCache) =>
            new Map(prevCache).set(img._id, imageUrl)
          );
          return { src: imageUrl, ...img };
        } else {
          console.error("Failed to fetch image:", response.statusText);
          return null;
        }
      };

      // Fetch images and cache them
      const imageObjects = await Promise.all(imageList.map(getImageUrl));

      // Filter out any null values in case of failed requests
      const validImageObjects = imageObjects.filter((img) => img !== null);

      // Set images with metadata and current index
      setImages(validImageObjects);
      setCurrentImageIndex(currentIndex);
      setImageUrl(validImageObjects[currentIndex].src);
      setViewerVisible(true); // Show the viewer
    } catch (error) {
      console.error("Error opening image:", error);
    } finally {
      setLoading(false);
    }
  };

  const [ImageUrlsh, setImageUrlsh] = useState("");
  const [ViewerVisiblesh, setViewerVisiblesh] = useState("");
  const [imagessh, setImagessh] = useState([]);
  const [currentImageIndexsh, setCurrentImageIndexsh] = useState(0);
  const [imageCachesh, setImageCachesh] = useState(new Map());
  // Fetch and set all images from the directory
  const handleViewImagesh = async (fileId, parentFolderId) => {
    setImagessh([]);
    setLoading(true);
    try {
      // Convert 'null' to an empty string for root directory
      const folderId =
        !parentFolderId || parentFolderId === "null" ? "" : parentFolderId;

      // Fetch the image list from the parent folder
      const imageList = await fetchImagesInDirectorysh(folderId);

      // Find the index of the current fileId in the image list
      const currentIndex = imageList.findIndex((img) => img._id === fileId);

      if (currentIndex === -1) {
        throw new Error("File not found in the image list.");
      }

      // Fetch image URLs for all images in the list
      const getImageUrl = async (img) => {
        if (imageCachesh.has(img._id)) {
          return { src: imageCachesh.get(img._id), ...img };
        }

        const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/viewSharedImage/${img._id}`;
        const response = await fetch(url, {
          method: "GET",
        });

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          // Return the image URL along with file metadata
          setImageCachesh((prevCache) =>
            new Map(prevCache).set(img._id, imageUrl)
          );
          return { src: imageUrl, ...img };
        } else {
          console.error("Failed to fetch image:", response.statusText);
          return null;
        }
      };

      const imageObjects = await Promise.all(imageList.map(getImageUrl));

      // Filter out any null values in case of failed requests
      const validImageObjects = imageObjects.filter((img) => img !== null);

      if (validImageObjects.length === 0) {
        throw new Error("No valid images were fetched.");
      }

      // Set images with metadata and current index
      setImagessh(validImageObjects);
      setCurrentImageIndexsh(currentIndex);
      setImageUrlsh(validImageObjects[currentIndex].src);
      setViewerVisiblesh(true); // Show the viewer
    } catch (error) {
      console.error("Error opening image:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //fetchimagesinDirectory
  const fetchImagesInDirectory = async (folderId) => {
    if (!folderId || folderId === "null") {
      folderId = "root"; // Set to 'root' for root folder
    }
    try {
      const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/Imgfolder/${folderId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const { files } = await response.json();
        // Filter image files
        const imageFiles = files.filter((file) => {
          const extension = file.filename.split(".").pop().toLowerCase();
          return ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(
            extension
          );
        });
        return imageFiles;
      } else {
        console.error("Failed to fetch folder contents:", response.statusText);
        return [];
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      return [];
    }
  };

  //fetch shared images in directory
  const fetchImagesInDirectorysh = async (folderId) => {
    if (!folderId || folderId === "null") {
      folderId = "root"; // Set to 'root' for root folder
    }

    try {
      const url = `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/imageDirectory/${folderId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const { files } = await response.json();
        // Filter image files
        const imageFiles = files.filter((file) => {
          const extension = file.filename.split(".").pop().toLowerCase();
          return ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(
            extension
          );
        });
        return imageFiles;
      } else {
        console.error("Failed to fetch folder contents:", response.statusText);
        return [];
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      return [];
    }
  };

  //fetch all users
  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/fetchUsers",
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.users) {
          setUsers(data.users);
        } else {
          console.error("Unexpected response structure:", data);
        }
      } else {
        console.error("Fetching users failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  //fetch shared items
  const fetchSharedItems = async () => {
    try {
      const response = await axios.get(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/fastsharedWMe",
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const { files, folders } = response.data;

        setSharedItems({
          files: files,
          folders: folders,
        });
      } else {
        console.error("Failed to fetch shared items:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching shared items:", error);
    }
  };

  // Get Recieved Today count
  const [receivedTodayFiles, setReceivedTodayFiles] = useState([]);
  const [receivedTodayFolders, setReceivedTodayFolders] = useState([]);
  const fetchReceivedTodayCounts = async (date) => {
    try {
      const formattedDate = format(date, "yyyy-MM-dd");
      const response = await axios.get(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/receivedDateCounts?date=${formattedDate}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setReceivedTodayFiles(response.data.files);
        setReceivedTodayFolders(response.data.folders);
      } else {
        console.error(
          "Failed to fetch received today counts:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching received today counts:", error);
    }
  };

  //Fetch sent items data
  const [sentTodayFiles, setSentTodayFiles] = useState([]);
  const [sentTodayFolders, setSentTodayFolders] = useState([]);

  const fetchSentItems = async (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    try {
      const response = await axios.get(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/sent-items?date=${formattedDate}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const { files, folders } = response.data;

        // No need to filter on the frontend since the backend already handles it
        setSentTodayFiles(files);
        setSentTodayFolders(folders);
      } else {
        console.error("Failed to fetch sent items:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching sent items:", error);
    }
  };

  //Fetching Shared-Folders and File Contents
  const fetchSharedFolderContents = async (folderId) => {
    try {
      const response = await axios.get(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/shared-folder-contents/${folderId}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        console.error(
          "Failed to fetch shared folder contents:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching shared folder contents:", error);
    }
  };

  //get logged in user details
  const [currentUser, setCurrentUser] = useState(null);
  const fetchCurrentUser = async () => {
    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/auth/getUser", // Ensure the backend route matches this path
        {
          method: "POST", // Assuming this is a POST request as per your previous setup
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data) {
          setCurrentUser(data); // Set the full user details in state
        } else {
          console.error("Unexpected response structure:", data);
        }
      } else {
        console.error("Fetching user details failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  //Fetch Profile pic
  const[loading5, setLoading5]=useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const fetchProfilePic = async (userId) => {
    try {
      setLoading5(true);
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/profilePic/${userId}`
      );
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setProfilePic(imageObjectURL);
        setLoading5(false)
      } else {
        // Use default profile picture if fetching the user's picture fails
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      // Use default profile picture in case of an error
      setProfilePic("/default-profile-pic.jpg");
    } 
  };
  const [userprofilePic, setuserProfilePic] = useState(null);
  const fetchUserProfilePic = async (userId) => {
    try {
      setLoading5(true);
      const response = await fetch(
        `https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/profilePic/${userId}`
      );
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setuserProfilePic(imageObjectURL);
        setLoading5(false)
      } else {
        // Use default profile picture if fetching the user's picture fails
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      // Use default profile picture in case of an error
      setProfilePic("/default-profile-pic.jpg");
    } 
  };

  const [totalFiles, setTotalFiles] = useState(null);
  const [totalFolders, setTotalFolders] = useState(null);

  const fetchUserItemCounts = async () => {
    try {
      const response = await fetch(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/fileRoutes/countUserItems",
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.totalFolders !== undefined && data.totalFiles !== undefined) {
          setTotalFolders(data.totalFolders);
          setTotalFiles(data.totalFiles);
        } else {
          console.error("Unexpected response structure:", data);
        }
      } else {
        console.error("Fetching item counts failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching item counts:", error);
    }
  };

  const [selectedFiles1, setSelectedFiles1] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [inDashBoard, setinDashboard] = useState(false);
  const [inChatBox, setinChatBox] = useState(false);
  const [inTeams, setInTeams] = useState(false);
  const [sharedInternal, setSharedInternal] = useState(false);
  const [sharedSection, setsharedSection] = useState(false);
  const [showIndiTeam, setShowIndiTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  return (
    <GreatContext.Provider
      value={{
        fetchUserProfilePic,
        userprofilePic, 
        setuserProfilePic,
        setFolderToRename,
        setFileToRename,
        setSelectedTeam,
        selectedTeam,
        setShowIndiTeam,
        showIndiTeam,
        setsharedSection,
        sharedSection,
        setSharedInternal,
        sharedInternal,
        setInTeams,
        inTeams,
        setinChatBox,
        inChatBox,
        selectedFiles1,
        setSelectedFiles1,
        setinDashboard,
        inDashBoard,
        setSelectedFolders,
        selectedFolders,
        uploadType,
        loading5,
        totalFiles,
        totalFolders,
        fetchUserItemCounts,
        profilePic,
        fetchProfilePic,
        currentUser,
        fetchCurrentUser,
        fetchReceivedTodayCounts,
        setNavigationHistory,
        setNavigationHistory2,
        sentTodayFiles,
        sentTodayFolders,
        fetchSentItems,
        receivedTodayFolders,
        receivedTodayFiles,
        breadcrumb2,
        setBreadcrumb2,
        handleBack2,
        setLoading5,
        fetchSharedItems,
        fetchSharedFolderContents,
        sharedItems,
        setSharedItems,
        users,
        fetchUsers,
        fetchImagesInDirectory,
        setImageUrl,
        images,
        currentFolder,
        setImages,
        currentImageIndex,
        setCurrentImageIndex,
        fileURL,
        fetchFolders,
        navigationHistory,
        navigationHistory2,
        handleBack,
        loading,
        loading2,
        handleViewPdf2,
        loading3,
        loading4,
        setLoading4,
        setLoading3,
        downloadProgress,
        uploadProgress,
        folders,
        isZipping,
        isDownloading,
        zippingProgress,
        fetchFolderContents,
        handleFolderDownload,
        handleDeleteFolder,
        deletionSuccess,
        setDeletionSuccess,
        handleRenameClick2,
        folderToRename,
        newFolderName,
        setNewFolderName,
        handleRenameFolder,
        setLoading,
        files,
        setFiles,
        setFolders,
        handleDownload,
        handleDeleteFile,
        handleMoveClick,
        handleRenameClick,
        fileToRename,
        newFileName,
        setNewFileName,
        handleRenameFile,
        showDropdown,
        selectedFolder,
        handleSelectFolder,
        handleMoveFiles,
        isFolder,
        setIsFolder,
        handleFileChange,
        handleUpload,
        folderName,
        setFolderName,
        handleCreateFolder,
        resetNavigationHistory,
        resetNavigationHistory2,
        breadcrumb,
        setBreadcrumb,
        handleViewPdf,
        downloadType,
        isFetching,
        fetchingProgress,
        handleViewFile,
        handleViewImage,
        ViewerVisible,
        ImageUrl,
        handleDownloadMultipleFiles,
        handleViewImagesh,
        ViewerVisiblesh,
        ImageUrlsh,
        setImageUrlsh,
        imagessh,
        setImagessh,
        currentImageIndexsh,
        setCurrentImageIndexsh,
      }}
    >
      {props.children}
    </GreatContext.Provider>
  );
};

export default GreatState;
