import React, { useContext, useEffect, useState, useRef } from "react";
import "../App.css";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "./Spinner";
import axios from "axios";
import ProgressBar from "./progressBar";
import Sidebar from "./Sidebar";
import GreatContext from "../context/GreatContext";
import Navbar from "./Navbar";
import SharedWithMe from "./SharedWithMe";
import SharedWithMe2 from "./SharedWithMe2";
import emptyPage from "../icons/homePageempty.png";
function ShareWithMeComp() { 
  useEffect(() => {
    // On page load, set overflow to auto for the .filer class
    const filerElement = document.querySelector(".fileFolderaccess");
    if (filerElement) {
      filerElement.style.overflow = "hidden"; // or "scroll"
    }
    return () => {
      if (filerElement) {
        filerElement.style.overflow = "auto";
      }
    };
  }, []);

  // let history = useHistory();
  // useEffect(() => {
  //   const timeoutRef = localStorage.getItem("logtime");
  //   const currentTime = new Date().toISOString();

  //   const tenMinutesInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds

  //   // Calculate the difference between current time and timeout reference time
  //   const timeDifference = new Date(currentTime) - new Date(timeoutRef);

  //   // Check if the time difference exceeds 10 minutes
  //   if (timeDifference >= tenMinutesInMilliseconds) {
  //     // Perform your action here, e.g., logout the user
  //     localStorage.removeItem("logtime");
  //     localStorage.removeItem("token");
  //     // Redirect to login page or any other desired action after logout
  //     history.push("/login");
  //   } else {
  //     const currentTime2 = new Date().toISOString();
  //     localStorage.setItem("logtime", currentTime2);
  //   }
  // }, [history]);
  const ImgComponent = () => (
    <img
      src={emptyPage}
      alt=""
      style={{
        marginRight: "0rem",
        width: "50%",
        borderRadius: "5rem 0rem",
      }}
    />
  );

  const MemorizedImg1 = React.memo(ImgComponent);
  const GrConst = useContext(GreatContext);
  const {
    loading,
    loading2,
    loading4,
    setLoading,
    downloadProgress,
    fetchFolderContents,
    handleFolderDownload,
    handleDeleteFolder,
    handleRenameClick2,
    folderToRename,
    newFolderName,
    setNewFolderName,
    handleRenameFolder,
    handleDownload,
    handleDeleteFile,
    handleMoveClick,
    handleRenameClick,
    fileToRename,
    newFileName,
    setNewFileName,
    handleRenameFile,
    resetNavigationHistory2,
    handleViewPdf,
    uploadProgress,
    loading3,
    zippingProgress,
    isZipping,
    isDownloading,
    downloadType,
    isFetching,
    fetchingProgress,
    handleViewFile,
    fileURL,
    handleViewPdf2,
    handleDownloadMultipleFiles,
    users,
    setFolderToRename,
    setFileToRename,
    fetchUsers,
    sharedItems,
    setSharedItems,
    fetchSharedItems,
    fetchSharedFolderContents,
    breadcrumb2,
    setBreadcrumb2,
    handleBack2,
    navigationHistory2,
    handleViewImagesh,
    ViewerVisiblesh,
    ImageUrlsh,
    setImageUrlsh,
    imagessh,
    setImagessh,
    currentImageIndexsh,
    setCurrentImageIndexsh,
    setNavigationHistory2,
    currentUser,
    fetchCurrentUser,
    uploadType,
    setsharedSection,
    sharedSection,
    selectedFiles1,
    setSelectedFiles1,
    setSelectedFolders,
    selectedFolders,
  } = GrConst;
  useEffect(() => {
    fetchUsers();
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);

  const fetchSharedItemsComp = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://secure-storage-system-mern-cloud-file.onrender.com/api/sharer2/fastsharedWMe",
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const { files, folders } = response.data;

        setSharedItems({
          files: files,
          folders: folders,
        });
      } else {
        console.error("Failed to fetch shared items:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching shared items:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSharedItemsComp();
    setBreadcrumb2([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    resetNavigationHistory2();
    // eslint-disable-next-line
  }, []);

  const handleToggleSection = () => {
    setsharedSection((prev) => !prev);
    setBreadcrumb2([{ name: "Home", link: "/folders" }]); // Reset breadcrumb to Home
    resetNavigationHistory2();
  };

  const [borderRadius, setBorderRadius] = useState("8rem 0rem 0rem 8rem");
  const [height, setHeight] = useState("85%");
  const [borderBottom, setborderBottom] = useState("2px solid white");
  const [loadingmoreFiles, setloadingmoreFiles] = useState(false);
  const contentRef = useRef(null);
  const contentEndRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const contentDiv = contentRef.current;

        // Check scroll position and adjust border radius and height
        if (contentDiv.scrollTop > 0) {
          setBorderRadius("3rem 0rem 0rem 0rem");
          setHeight("89%");
          setborderBottom("none");
        } else {
          setBorderRadius("10rem 0rem 0rem 10rem");
          setHeight("85%");
          setborderBottom("2px solid white");
        }
      }
    };

    const contentDiv = contentRef.current;

    if (contentDiv) {
      contentDiv.addEventListener("scroll", handleScroll);

      // Call handleScroll initially to set height and border radius based on initial state
      handleScroll();

      return () => {
        contentDiv.removeEventListener("scroll", handleScroll);
      };
    }
  });

  const[showNav,setShowNav]=useState(false);
  const handleResize = () => {
    const isMobile = window.innerWidth < 1600; // Adjust the width threshold as necessary
    setShowNav(isMobile);
  };

  useEffect(() => {
    // Set initial state based on current width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  return (
    <>
      <div className="wrapper">
        {loading && <Spinner />}
        {showNav ? <Navbar /> : <Sidebar/>}

        <div id="wrapper">
          <div className="fileFolderaccess">
            {loading4 && <div className="loader"></div>}
            {loading2 &&
              (downloadType === "folder" || downloadType === "file") && (
                <div className="fixed-progress-bar">
                  <div>
                    {downloadType === "folder" ? (
                      isFetching ? (
                        <h6>Processing...</h6>
                      ) : isZipping ? (
                        <h6>Downloading Folder...</h6>
                      ) : (
                        <h6>Finalizing...</h6>
                      )
                    ) : downloadType === "file" ? (
                      isDownloading ? (
                        <h6>Downloading File...</h6>
                      ) : (
                        <h6>Preparing File...</h6> // Optional: Handle the case if needed
                      )
                    ) : (
                      <h6>Unknown Download Type...</h6> // Optional: Handle unexpected cases
                    )}
                  </div>
                  <ProgressBar
                    progress={
                      downloadType === "folder"
                        ? isFetching
                          ? Math.round(fetchingProgress ?? 0)
                          : isZipping
                          ? Math.round(zippingProgress ?? 0)
                          : Math.round(downloadProgress ?? 0)
                        : Math.round(downloadProgress ?? 0)
                    }
                  />
                </div>
              )}
            {loading3 && (
              <div className="fixed-progress-bar">
                <div>
                  {Math.round(uploadProgress) !== 100 ? (
                    <>
                      <h6>Uploading...</h6>
                      <ProgressBar progress={Math.round(uploadProgress)} />
                    </>
                  ) : uploadType === "file" ? (
                    <>
                      <h6
                        className="display-6"
                        style={{ fontSize: "large", fontWeight: "500" }}
                      >
                        Upload completed
                      </h6>
                      <h6
                        className="display-6"
                        style={{ fontSize: "medium", fontWeight: "400" }}
                      >
                        <br />
                        <br />
                        Processing your file, you're free to surf around...
                      </h6>
                    </>
                  ) : (
                    <>
                      <h6
                        className="display-6"
                        style={{ fontSize: "large", fontWeight: "500" }}
                      >
                        Upload completed
                      </h6>
                      <h6
                        className="display-6"
                        style={{ fontSize: "medium", fontWeight: "400" }}
                      >
                        <br />
                        <br />
                        Processing your folder, you're free to surf around...
                      </h6>
                    </>
                  )}
                </div>
              </div>
            )}
            {sharedSection ? (
              <>
                <div className="sharedHead">
                  <h2 className="sharedVault">Shared Vault</h2>
                  <div className="sharedselector">
                    <button
                      className="shselectbutton"
                      onClick={handleToggleSection}
                      style={{
                        backgroundColor: sharedSection ? "aqua" : "white",
                        borderRadius: "2rem 0rem 0rem 2rem",
                        width: "50%",
                      }}
                    >
                      Files
                    </button>
                    <button
                      className="shselectbutton"
                      onClick={handleToggleSection}
                      style={{
                        backgroundColor: !sharedSection ? "aqua" : "white",
                        borderRadius: "0rem 2rem 2rem 0rem",
                        width: "50%",
                      }}
                    >
                      Folders
                    </button>
                  </div>
                </div>

                {currentUser &&
                sharedItems.files.length > 0 &&
                sharedItems.files.some((file) =>
                  file.sharedWith.some(
                    (sharedUser) => sharedUser.userId === currentUser._id
                  )
                ) ? (
                  <div
                    className="content"
                    ref={contentRef}
                    style={{
                      transition: "all 0.3s ease-in-out",
                      height: height,
                      borderRadius: borderRadius,
                      borderBottom: borderBottom,
                    }}
                  >
                    <h3 style={{ color: "#ccc0fe" }}>Files</h3>
                    <SharedWithMe
                      users={users}
                      handleViewPdf2={handleViewPdf2}
                      handleDownload={handleDownload}
                      handleDeleteFile={handleDeleteFile}
                      handleMoveClick={handleMoveClick}
                      handleRenameClick={handleRenameClick}
                      fileToRename={fileToRename}
                      newFileName={newFileName}
                      setNewFileName={setNewFileName}
                      handleRenameFile={handleRenameFile}
                      handleViewPdf={handleViewPdf}
                      handleViewFile={handleViewFile}
                      fileURL={fileURL}
                      setFileToRename={setFileToRename}
                      handleViewImagesh={handleViewImagesh}
                      ViewerVisiblesh={ViewerVisiblesh}
                      ImageUrlsh={ImageUrlsh}
                      selectedFiles={selectedFiles1}
                      setSelectedFiles={setSelectedFiles1}
                      selectedFolders={selectedFolders}
                      imagessh={imagessh}
                      currentImageIndexsh={currentImageIndexsh}
                      setCurrentImageIndexsh={setCurrentImageIndexsh}
                      setImageUrlsh={setImageUrlsh}
                      setImagessh={setImagessh}
                      handleDownloadMultipleFiles={handleDownloadMultipleFiles}
                      sharedItems={sharedItems}
                      setSharedItems={setSharedItems}
                      fetchSharedItems={fetchSharedItems}
                      fetchSharedFolderContents={fetchSharedFolderContents}
                      currentUser={currentUser}
                      loadingmoreFiles={loadingmoreFiles}
                      contentEndRef={contentEndRef}
                    />
                  </div>
                ) : (
                  sharedItems.files.length === 0 &&
                  !loading && (
                    <div className="emptyVault">
                      <MemorizedImg1 />
                      <h4
                        className="display-6"
                        style={{ color: "white", marginTop: "1.5rem" }}
                      >
                        Probably someone shared folder..
                      </h4>
                    </div>
                  )
                )}
              </>
            ) : (
              !sharedSection && (
                <>
                  <div className="sharedHead">
                    <h2 className="sharedVault">Shared Vault</h2>
                    <div className="sharedselector">
                      <button
                        className="shselectbutton"
                        onClick={handleToggleSection}
                        style={{
                          backgroundColor: sharedSection ? "aqua" : "white",
                          borderRadius: "2rem 0rem 0rem 2rem",
                          width: "50%",
                        }}
                      >
                        Files
                      </button>
                      <button
                        className="shselectbutton"
                        onClick={handleToggleSection}
                        style={{
                          backgroundColor: !sharedSection ? "aqua" : "white",
                          borderRadius: "0rem 2rem 2rem 0rem",
                          width: "50%",
                        }}
                      >
                        Folders
                      </button>
                    </div>
                  </div>
                  {sharedItems.folders.length ||
                  sharedItems.files.length > 0 ? (
                    <div
                      className="content"
                      ref={contentRef}
                      style={{
                        transition: "all 0.3s ease-in-out",
                        height: height,
                        borderRadius: borderRadius,
                        borderBottom: borderBottom,
                      }}
                    >
                      <div className="sharedVaultFileFolder">
                        <SharedWithMe2
                          setNavigationHistory2={setNavigationHistory2}
                          setLoading={setLoading}
                          fetchFolderContents={fetchFolderContents}
                          handleFolderDownload={handleFolderDownload}
                          handleDeleteFolder={handleDeleteFolder}
                          handleRenameClick2={handleRenameClick2}
                          folderToRename={folderToRename}
                          newFolderName={newFolderName}
                          setNewFolderName={setNewFolderName}
                          handleRenameFolder={handleRenameFolder}
                          navigationHistory2={navigationHistory2}
                          handleBack2={handleBack2}
                          resetNavigationHistory2={resetNavigationHistory2}
                          selectedFiles={selectedFiles1}
                          selectedFolders={selectedFolders}
                          setSelectedFolders={setSelectedFolders}
                          users={users}
                          setFolderToRename={setFolderToRename}
                          sharedItems={sharedItems}
                          setSharedItems={setSharedItems}
                          fetchSharedItems={fetchSharedItems}
                          fetchSharedFolderContents={fetchSharedFolderContents}
                          setBreadcrumb2={setBreadcrumb2}
                          breadcrumb2={breadcrumb2}
                          currentUser={currentUser}
                          setloadingmoreFiles={setloadingmoreFiles}
                          contentRef={contentRef}
                        />
                        <div className="vaultfile">
                          <SharedWithMe
                            users={users}
                            handleViewPdf2={handleViewPdf2}
                            handleDownload={handleDownload}
                            handleDeleteFile={handleDeleteFile}
                            handleMoveClick={handleMoveClick}
                            handleRenameClick={handleRenameClick}
                            fileToRename={fileToRename}
                            newFileName={newFileName}
                            setNewFileName={setNewFileName}
                            handleRenameFile={handleRenameFile}
                            handleViewPdf={handleViewPdf}
                            handleViewFile={handleViewFile}
                            fileURL={fileURL}
                            setFileToRename={setFileToRename}
                            handleViewImagesh={handleViewImagesh}
                            ViewerVisiblesh={ViewerVisiblesh}
                            ImageUrlsh={ImageUrlsh}
                            selectedFiles={selectedFiles1}
                            setSelectedFiles={setSelectedFiles1}
                            selectedFolders={selectedFolders}
                            imagessh={imagessh}
                            currentImageIndexsh={currentImageIndexsh}
                            setCurrentImageIndexsh={setCurrentImageIndexsh}
                            setImageUrlsh={setImageUrlsh}
                            setImagessh={setImagessh}
                            handleDownloadMultipleFiles={
                              handleDownloadMultipleFiles
                            }
                            sharedItems={sharedItems}
                            setSharedItems={setSharedItems}
                            fetchSharedItems={fetchSharedItems}
                            fetchSharedFolderContents={
                              fetchSharedFolderContents
                            }
                            currentUser={currentUser}
                            contentEndRef={contentEndRef}
                            loadingmoreFiles={loadingmoreFiles}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    sharedItems.folders.length === 0 &&
                    sharedItems.files.length === 0 &&
                    !loading && (
                      <div className="emptyVault">
                        <MemorizedImg1 />
                        <h4
                          className="display-6"
                          style={{ color: "white", marginTop: "1.5rem" }}
                        >
                          Folders will appear once someone shares them with
                          you..
                        </h4>
                      </div>
                    )
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShareWithMeComp;
