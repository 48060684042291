import React, { useEffect, useState, useContext } from "react";
import "./firstpage.css";
import { Link } from "react-router-dom";
import logosym from "../icons/logoSym.png";
import xicon from "../icons/xicon.png";
import inicon from "../icons/inicon.png";
import giticon from "../icons/giticon.png";
import discordicon from "../icons/discordicon.png";
import GreatContext from "../context/GreatContext";
const FirstPage = () => {
  useEffect(() => {
    // On page load, set overflow to auto (or scroll)
    document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);
  useEffect(() => {
    const e = document.documentElement;

    // Ensure `js` class is added
    e.classList.remove("no-js");
    e.classList.add("js");

    // Dynamically add `has-animations` class to the body if it's missing
    if (!document.body.classList.contains("has-animations")) {
      document.body.classList.add("has-animations");
    }

    // Initialize ScrollReveal animations
    if (document.body.classList.contains("has-animations")) {
      window.sr = window.ScrollReveal();
      window.sr.reveal(".feature, .pricing-table-inner", {
        duration: 600,
        distance: "20px",
        easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
        origin: "bottom",
        interval: 100,
      });

      // Add anime-ready class to enable Anime.js animations
      e.classList.add("anime-ready");

      // Anime.js animations
      window.anime
        .timeline({ targets: ".hero-figure-box-05" })
        .add({
          duration: 400,
          easing: "easeInOutExpo",
          scaleX: [0.05, 0.05],
          scaleY: [0, 1],
          perspective: "500px",
          delay: window.anime.random(0, 400),
        })
        .add({ duration: 400, easing: "easeInOutExpo", scaleX: 1 })
        .add({
          duration: 800,
          rotateY: "-15deg",
          rotateX: "8deg",
          rotateZ: "-1deg",
        });

      window.anime
        .timeline({ targets: ".hero-figure-box-06, .hero-figure-box-07" })
        .add({
          duration: 400,
          easing: "easeInOutExpo",
          scaleX: [0.05, 0.05],
          scaleY: [0, 1],
          perspective: "500px",
          delay: window.anime.random(0, 400),
        })
        .add({ duration: 400, easing: "easeInOutExpo", scaleX: 1 })
        .add({ duration: 800, rotateZ: "20deg" });

      window.anime({
        targets:
          ".hero-figure-box-01, .hero-figure-box-02, .hero-figure-box-03, .hero-figure-box-04, .hero-figure-box-08, .hero-figure-box-09, .hero-figure-box-10",
        duration: window.anime.random(600, 800),
        delay: window.anime.random(600, 800),
        rotate: [
          window.anime.random(-360, 360),
          function (el) {
            return el.getAttribute("data-rotation");
          },
        ],
        scale: [0.7, 1],
        opacity: [0, 1],
        easing: "easeInOutExpo",
      });
    }

    const heroFigure = document.querySelector(".hero-figure");

    const handleMouseMove = (e) => {
      const s = document.querySelectorAll(".is-moving-object");
      const n = e.pageX;
      const i = e.pageY;
      const a = window.scrollY;
      const o = window.innerWidth / 2 - n;
      const r = window.innerHeight / 2 - (i - a);

      s.forEach((element) => {
        const translatingFactor =
          element.getAttribute("data-translating-factor") || 20;
        const rotatingFactor =
          element.getAttribute("data-rotating-factor") || 20;
        const perspective = element.getAttribute("data-perspective") || 500;

        let transformValues = [];

        // Handle translation
        if (element.classList.contains("is-translating")) {
          transformValues.push(
            `translate(${o / translatingFactor}px, ${r / translatingFactor}px)`
          );
        }

        // Handle rotation with clamped rotateX and rotateY (in the XY plane)
        if (element.classList.contains("is-rotating")) {
          let rotateY = -o / rotatingFactor;
          let rotateX = r / rotatingFactor;

          // Clamp rotateY to be between -30 and 30 degrees
          rotateY = Math.max(-40, Math.min(40, rotateY));

          // Clamp rotateX to be between -30 and 30 degrees
          rotateX = Math.max(-40, Math.min(40, rotateX));

          // Apply only rotation in XY plane (rotateX and rotateY)
          transformValues.push(
            `perspective(${perspective}px) rotateY(${rotateY}deg) rotateX(${rotateX}deg)`
          );
        }

        if (transformValues.length) {
          element.style.transform = transformValues.join(" ");
          element.style.transition = "transform 0.7s ease-out";
        }
      });
    };

    // Reset transform properties on mouse leave
    const resetRotation = () => {
      const s = document.querySelectorAll(".is-moving-object");

      s.forEach((element) => {
        const isSpecialElement =
          element.classList.contains("hero-figure-box-05") ||
          element.classList.contains("hero-figure-box-06") ||
          element.classList.contains("hero-figure-box-07");

        if (isSpecialElement) {
          // Reset to original transformation values
          if (element.classList.contains("hero-figure-box-05")) {
            element.style.transform =
              "perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg)";
          } else if (element.classList.contains("hero-figure-box-06")) {
            element.style.transform = "rotateZ(20deg)";
          } else if (element.classList.contains("hero-figure-box-07")) {
            element.style.transform = "rotateZ(20deg)";
          }
        } else {
          const perspective = element.getAttribute("data-perspective") || 500;
          const rotation = element.getAttribute("data-rotation");

          if (rotation) {
            // Reapply the original rotation from the data-rotation attribute
            element.style.transform = `perspective(${perspective}px) rotate(${rotation})`;
          } else {
            // Fallback to no rotation if data-rotation is not present
            element.style.transform = `perspective(${perspective}px) rotate(0deg)`;
          }
        }
        element.style.transition = "transform 1s ease-out";
      });
    };

    // Add event listener only when hovering over .hero-figure
    const handleMouseEnter = () => {
      window.addEventListener("mousemove", handleMouseMove);
    };

    // Remove event listener when mouse leaves .hero-figure and reset rotation
    const handleMouseLeave = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      resetRotation(); // Reset the rotation for specific elements
    };

    setTimeout(() => {
      heroFigure.addEventListener("mouseenter", handleMouseEnter);
      heroFigure.addEventListener("mouseleave", handleMouseLeave);
    }, 500);

    // Clean up event listeners on component unmount
    return () => {
      heroFigure.removeEventListener("mouseenter", handleMouseEnter);
      heroFigure.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState({});
  const validateEmail = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|rediffmail\.com|outlook\.com|hotmail\.com|icloud\.com|aol\.com|protonmail\.com|zoho\.com|apple\.com)$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let formErrors = {};

    if (formData.name.trim().length < 4) {
      formErrors.name = "Name must be at least 4 characters long.";
    }

    if (!validateEmail(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (formData.message.trim().length < 20) {
      formErrors.message = "Message must be at least 20 characters long.";
    }

    return formErrors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const res = await fetch(
        "http://localhost:5000/api/sendMessageMail/sendMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await res.json();
      setResponse(data.message);
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      setErrors({});
    } catch (error) {
      console.error("Error sending message", error);
      setResponse("Error sending message");
    }
  };

  const landConst = useContext(GreatContext);
  const { currentUser } = landConst;

  useEffect(() => {
    // Check if the script is already added
    if (
      !document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/payment-button.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.setAttribute("data-payment_button_id", "pl_P1r2jcL2Aku1CI");
      script.async = true;

      // Append script inside the form after it mounts
      const formElement = document.getElementById("razorpay-payment-form2");
      formElement.appendChild(script);
    }
  }, [currentUser]);

  return (
    <div className="landerWrapper">
      <div className="is-boxed has-animations">
        <div className="body-wrap">
          <header className="site-header">
            <div className="container labellander">
              <div className="site-header-inner">
                <div className="brand header-brand">
                  <h1 className="m-0">
                    <a href="/">
                      <img
                        className="header-logo-image"
                        src="../landerimages/logo.svg"
                        alt="Logo"
                      />
                    </a>
                  </h1>
                </div>
              </div>
            </div>
          </header>

          <main>
            <section className="hero">
              <div className="container labellander">
                <div className="hero-inner">
                  <div className="hero-copy">
                    <div className="glowing-btn">
                      <span className="glowing-txt">
                        S<span className="faulty-letter">Y</span>NCHR
                        <span className="faulty-letter2">O</span>NEXA
                      </span>
                    </div>

                    <p className="hero-paragraph line-1 anim-typewriter">
                      Organize, Connect, Secure - All in Sync
                    </p>
                    <div className="hero-cta">
                      <Link className="aqua-button" to="/signup">
                        Explore Synchronexa
                      </Link>
                      <Link className="aqua-button" to="/login">
                        Get in touch
                      </Link>
                    </div>
                  </div>

                  <div
                    className="hero-figure anime-element"
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      className="placeholder"
                      width="528"
                      height="396"
                      viewBox="0 0 528 396"
                    >
                      <rect
                        width="528"
                        height="396"
                        style={{ fill: "transparent" }}
                      />
                    </svg>
                    <div
                      className="hero-figure-box hero-figure-box-01 is-moving-object is-rotating"
                      data-rotation="45deg"
                    ></div>
                    <div
                      className="hero-figure-box hero-figure-box-02 is-moving-object is-rotating"
                      data-rotation="-45deg"
                    ></div>
                    <div
                      className="hero-figure-box hero-figure-box-03 is-moving-object is-rotating"
                      data-rotation="0deg"
                    ></div>
                    <div
                      className="hero-figure-box hero-figure-box-04 is-moving-object is-rotating"
                      data-rotation="-135deg"
                    ></div>
                    <div className="hero-figure-box hero-figure-box-05 is-moving-object is-rotating"></div>
                    <div className="hero-figure-box hero-figure-box-06 is-moving-object is-rotating"></div>
                    <div className="hero-figure-box hero-figure-box-07 is-moving-object is-rotating"></div>
                    <div
                      className="hero-figure-box hero-figure-box-08 is-moving-object is-rotating"
                      data-rotation="-22deg"
                    ></div>
                    <div
                      className="hero-figure-box hero-figure-box-09 is-moving-object is-rotating"
                      data-rotation="-52deg"
                    ></div>
                    <div
                      className="hero-figure-box hero-figure-box-10 is-moving-object is-rotating"
                      data-rotation="-50deg"
                    ></div>
                  </div>
                </div>
              </div>
            </section>

            <section className="features section">
              <div className="container">
                <div className="features-inner section-inner has-bottom-divider">
                  <div className="features-wrap">
                    <div className="feature text-center is-revealing">
                      <div className="feature-inner">
                        <div>
                          <div className="feature-icon">
                            <img
                              src="../landerimages/feature-icon-01.png"
                              alt="Feature 01"
                              style={{ width: "5rem" }}
                            />
                          </div>
                          <h4 className="feature-title mt-24">
                            Lightning-Fast File Management
                          </h4>
                        </div>

                        <p
                          className="text-sm mb-0"
                          style={{ textAlign: "justify", marginTop: "1rem" }}
                        >
                          Experience unparalleled efficiency with our highly
                          fast and seamless file upload, download, and deletion
                          capabilities. Whether you're sharing a single file or
                          collaborating with others, you can easily create
                          shared vaults between two users. This feature ensures
                          that your mutual files are secure, accessible, and
                          organized, transforming the way you handle your
                          important documents.
                        </p>
                      </div>
                    </div>
                    <div className="feature text-center is-revealing">
                      <div className="feature-inner">
                        <div>
                          <div className="feature-icon">
                            <img
                              src="../landerimages/feature-icon-02.png"
                              alt="Feature 02"
                              style={{ width: "5rem" }}
                            />
                          </div>
                          <h4 className="feature-title mt-24">
                            Powerful Folder Structure
                          </h4>
                        </div>

                        <p
                          className="text-sm mb-0"
                          style={{ textAlign: "justify", marginTop: "1rem" }}
                        >
                          Take control of your files with our intuitive folder
                          management system. Maintain a fully nested folder
                          tree, move files effortlessly between subfolders, and
                          navigate through your documents with ease. Our
                          platform allows you to download entire folders as ZIP
                          files, ensuring that managing your digital workspace
                          is not only fast but also efficient. Plus, shared
                          vaults can include shared folders for enhanced
                          collaboration.
                        </p>
                      </div>
                    </div>
                    <div className="feature text-center is-revealing">
                      <div className="feature-inner">
                        <div>
                          <div className="feature-icon">
                            <img
                              src="../landerimages/feature-icon-03.png"
                              alt="Feature 03"
                              style={{ width: "5rem" }}
                            />
                          </div>
                          <h4 className="feature-title mt-24">
                            Versatile File Viewing
                          </h4>
                        </div>

                        <p
                          className="text-sm mb-0"
                          style={{ textAlign: "justify", marginTop: "1rem" }}
                        >
                          Our app supports a variety of file types, allowing
                          users to open PDFs, images, MP3s, and MP4s directly
                          within the platform. Need to access Office files like
                          DOCX or Excel? No problem! With Google Drive
                          integration, you can authenticate seamlessly and open
                          your documents without leaving the app. Enjoy a
                          hassle-free experience with all your essential files
                          in one place.
                        </p>
                      </div>
                    </div>
                    <div className="feature text-center is-revealing">
                      <div className="feature-inner">
                        <div>
                          <div className="feature-icon">
                            <img
                              src="../landerimages/feature-icon-04.png"
                              alt="Feature 04"
                              style={{ width: "5rem" }}
                            />
                          </div>
                          <h4 className="feature-title mt-24">
                            Secure Personal Chat
                          </h4>
                        </div>

                        <p
                          className="text-sm mb-0"
                          style={{ textAlign: "justify", marginTop: "1rem" }}
                        >
                          Connect with friends and colleagues through our highly
                          capable and secure end-to-end encrypted personal chat
                          feature. Enjoy a smooth chatting experience enhanced
                          with emojis and GIFs. Whether you're discussing
                          projects or just catching up, our chat functionality
                          keeps your conversations private and engaging,
                          ensuring that your communication is as secure as it is
                          enjoyable.
                        </p>
                      </div>
                    </div>
                    <div className="feature text-center is-revealing">
                      <div className="feature-inner">
                        <div>
                          <div className="feature-icon">
                            <img
                              src="../landerimages/feature-icon-05.png"
                              alt="Feature 05"
                              style={{ width: "5rem" }}
                            />
                          </div>
                          <h4 className="feature-title mt-24">
                            Enhanced Shared Vault Access
                          </h4>
                        </div>

                        <p
                          className="text-sm mb-0"
                          style={{ textAlign: "justify", marginTop: "1rem" }}
                        >
                          In our shared vault section, users can view and edit
                          files and folders shared with them, based on the
                          permissions granted by the sender. This dynamic
                          feature allows for collaborative editing and sharing,
                          making teamwork effortless. Keep track of your shared
                          files while maintaining control over your content,
                          ensuring that your digital workspace is both flexible
                          and secure.
                        </p>
                      </div>
                    </div>
                    <div className="feature text-center is-revealing">
                      <div className="feature-inner">
                        <div>
                          <div className="feature-icon">
                            <img
                              src="../landerimages/feature-icon-06.png"
                              alt="Feature 06"
                              style={{ width: "5rem" }}
                            />
                          </div>
                          <h4 className="feature-title mt-24">
                            Build Your Community with Teams
                          </h4>
                        </div>

                        <p
                          className="text-sm mb-0"
                          style={{ textAlign: "justify", marginTop: "1rem" }}
                        >
                          Join the community-building revolution with our Teams
                          section! Create and manage groups where you can give
                          different permission levels to members, ensuring full
                          access control. Engage in highly interactive group
                          chats with multimedia support. Plus, enjoy our
                          StackOverflow-style Q&A platform, where users can post
                          questions, upvote, downvote, and reply with full
                          code-writing support. Collaborate, learn, and grow
                          together!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pricing section" style={{ marginTop: "1rem" }}>
              <div className="container">
                <div className="pricing-inner section-inner">
                  <div className="pricing-header text-center">
                    <h2 className="section-title mt-0">Unlimited for all</h2>
                    <p className="section-paragraph mb-0">
                      For the first two years, Synchronexa is completely free
                      for all users! I believe in giving everyone the
                      opportunity to experience the power and efficiency of my
                      platform without any barriers. After this period, i'll
                      introduce affordable pricing plans to help sustain and
                      enhance the platform’s features. If you love using
                      Synchronexa and would like to support its development, you
                      can donate me any time. Your contributions will go towards
                      maintaining a high-quality service and adding even more
                      exciting features!
                    </p>
                  </div>
                  <div className="pricing-tables-wrap">
                    <div className="pricing-table">
                      <div className="pricing-table-inner is-revealing">
                        <div className="pricing-table-main">
                          <div className="pricing-table-header pb-24">
                            <div className="pricing-table-price">
                              <span className="pricing-table-price-currency h2">
                                ₹
                              </span>
                              <span className="pricing-table-price-amount h1">
                                0
                              </span>
                              <span className="text-xs">/month</span>
                            </div>
                          </div>
                          <div className="pricing-table-features-title text-xs pt-24 pb-24">
                            What you will get
                          </div>
                          <ul className="pricing-table-features list-reset text-xs">
                            <li>
                              <span>Storage upto 2GB</span>
                            </li>
                            <li>
                              <span>Unlimited file/folder sharing</span>
                            </li>
                            <li>
                              <span>end-2-end encrypted Chat support</span>
                            </li>
                            <li>
                              <span>
                                Community-building, group chats, and much more!
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="pricing-table-cta mb-8">
                          <a
                            className="button button-primary button-shadow button-block"
                            href="/"
                          >
                            Join now
                          </a>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="cta section">
              <div className="container">
                <div className="cta-inner section-inner">
                  <div
                    className="contacttitle"
                    style={{
                      width: "40%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <h3
                      className="section-title mt-0"
                      style={{ fontWeight: "400" }}
                    >
                      To report any bugs or give your feedback, reach me out
                    </h3>
                    <div
                      className="contacttitleimg"
                      style={{
                        width: "100%",
                        padding: "5rem 0rem 10rem 0rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={logosym} alt="" style={{ width: "100%" }} />
                    </div>
                    
                  </div>

                  <div className="contacttitleform" style={{ width: "60%" }}>
                    <form onSubmit={handleSubmit}>
                      <div className="rower65" style={{ margin: "0", display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                        <h6
                          className="section-title mt-0"
                          style={{ fontWeight: "300", fontSize:'1.2rem' }}
                        >
                          I would love to hear from you, if you like my work a small token of appreciation would mean the world.
                        </h6>
                        <div style={{ padding: "1rem 1rem 1rem 0rem" }}>
                          <form
                            id="razorpay-payment-form2"
                            style={{ color: "black" }}
                          ></form>
                        </div>
                      </div>
                      <div
                        className="row input-container"
                        style={{ margin: "0", width: "100%" }}
                      >
                        <div className="col-xs-12">
                          <div className="styled-input wide">
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                            <label>Name</label>
                            {errors.name && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                *{errors.name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div className="styled-input">
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                            <label>Email</label>
                            {errors.email && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                *{errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div className="styled-input wide">
                            <textarea
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              required
                            ></textarea>
                            <label>Message</label>
                            {errors.message && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                *{errors.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div
                            className="cta-cta"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              type="submit"
                              className="btn btn-outline-info"
                            >
                              Give Feedback
                            </button>
                          </div>
                        </div>
                      </div>
                      {response && (
                        <p>Thank you for your valuable feedback ❤️</p>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <footer className="site-footer">
            <div className="container">
              <div className="site-footer-inner">
                <div className="brand footer-brand">
                  <a href="/">
                    <img
                      className="header-logo-image"
                      src="../landerimages/logo.svg"
                      alt="Logo"
                    />
                  </a>
                  <div
                    className="footer-copyright"
                    style={{ marginTop: "1rem" }}
                  >
                    &copy; 2024 Synchronexa, all rights reserved
                  </div>
                </div>
                <ul
                  className="footer-links list-reset"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <li>
                    <a
                      href="https://www.linkedin.com/in/abhishek-raj-chauhan-747448206/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={inicon}
                        alt="LinkedIn"
                        style={{ width: "1.5rem" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/Abhishek-Raj-Chauhan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={giticon}
                        alt="GitHub"
                        style={{ width: "1.5rem" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discordapp.com/users/572768700856270849"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={discordicon}
                        alt="Discord"
                        style={{ width: "1.5rem" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/rajyaabhishekC"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={xicon} alt="X" style={{ width: "1.5rem" }} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
